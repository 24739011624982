import React from "react";
import { Modal, Button } from "react-bootstrap";

import { globalStrings } from "../util/translation/languages";
const ChatBadWord = (props) => {
  const { showBadWord, handleClose, selectedLanguage } = props;

  return (
    <>
      <Modal show={showBadWord}>
        <Modal.Header>
          <Modal.Title>
            {globalStrings[selectedLanguage]?.$badWordAlert}
          </Modal.Title>
          <button onClick={handleClose} className="icon_container">
            <svg width="13" viewBox="6.29 6.34 11.31 11.31">
              <path
                fill="#425b76"
                d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
              ></path>
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p className="text-thin">{globalStrings[selectedLanguage]?.$pTagBadWordMessage}</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            className="btn btn-orange btn btn-secondary"
            type="cancel"
            id="cancel"
            onClick={handleClose}
          >
            {globalStrings[selectedLanguage]?.$cancel}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChatBadWord;
