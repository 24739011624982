import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";

import {
  hangupInboundCall,
  recordingConsentCloseHandler,
} from "../Helpers/CallCommon";
import { globalStrings } from "../util/translation/languages";

const RecordingConsent = (props) => {
  const { selectedLanguage } = props;
  const recordingConsentPopUpEnable = useSelector(
    (state) => state.call.recordingConsentPopUpEnable,
  );

  return (
    <Modal className="attachment_info" show={recordingConsentPopUpEnable}>
      <Modal.Body className="d-flex modal-body flex-column align-items-start">
        <h2 className="text-center mb-2 text-lg">
          {globalStrings[selectedLanguage]?.$meetingRecorded}
        </h2>
        <p className="m-0 text-center">
          {globalStrings[selectedLanguage]?.$pTagMeetingRecorded}
        </p>
      </Modal.Body>

      <Modal.Footer className="d-flex justify-content-end">
        <Button
          className="btn btn-light mr-2"
          variant="secondary"
          onClick={() => {
            hangupInboundCall("ended");
            recordingConsentCloseHandler();
          }}
        >
          {globalStrings[selectedLanguage]?.$leaveMeeting}
        </Button>

        <Button
          className="btn btn-orange"
          variant="secondary"
          onClick={() => recordingConsentCloseHandler()}
        >
          {globalStrings[selectedLanguage]?.$continue}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RecordingConsent;
