import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
// import { BrowserTracing } from "@sentry/tracing";
// import * as Sentry from "@sentry/browser";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
// import { ENVIRONMENT } from "./constants";

// if (ENVIRONMENT !== "dev") {
//   Sentry.init({
//     dsn: "https://f914850df9dd421bbf65e84287044b6e@o1347523.ingest.sentry.io/6626262",
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 1.0,
//   });
// }

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
