/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, memo } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { getAuthSession } from "../Helpers/common";
import { setSearchAttendeeData } from "../redux/chatSlice";
import { alertMsg } from "../util/alertMsg";

const SidebarSearch = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showReq, setShowReq] = useState(false);
  const [launchUrl, setLaunchUrl] = useState(false);
  const [alertDownload, setAlertDownload] = useState(false);
  const { searchAttendeeData } = useSelector((state) => state.chat);
  const [search, setSearch] = useState("");

  const SearchAttendee = (e) => {
    if (e.keyCode === 13 && !e.shifyKey) {
      e.preventDefault();
      dispatch(setSearchAttendeeData(search));
    }

    if (e.key === "Backspace") {
      if (e.target.value.length === 1) {
        ClearSearch();
      }
    }
  };

  const ClearSearch = () => {
    dispatch(setSearchAttendeeData(""));
    setSearch("");
  };

  useEffect(() => {
    let auth = getAuthSession();
    let url = "c2perform-chat://" + auth.login_token;
    setLaunchUrl(url);
  }, []);

  return (
    <>
      {show && (
        <WindowsType
          show={show}
          setAlertDownload={setAlertDownload}
          alertDownload={alertDownload}
          launchUrl={launchUrl}
          handleClose={() => setShow(false)}
        />
      )}
      {showReq && (
        <MacType
          show={showReq}
          setAlertDownload={setAlertDownload}
          alertDownload={alertDownload}
          launchUrl={launchUrl}
          handleClose={() => setShowReq(false)}
        />
      )}
      <div className="chat_search mt-2">
        <input
          spellCheck="false"
          type="text"
          className="form-control"
          aria-label="Name"
          value={search}
          aria-describedby="basic-addon1"
          onChange={(e) => setSearch(e.currentTarget.value)}
          onKeyDown={(e) => SearchAttendee(e)}
        />
        {search || searchAttendeeData ? (
          <a
            className="btn_search icon_container"
            onClick={(e) => ClearSearch(e)}
          >
            <svg
              width="11.13"
              height="11.13"
              viewBox="0 0 9.13 9.13"
              className="rotate-45 cross"
            >
              <path
                fill="#b8c0c9"
                id="Icon_awesome-plus"
                data-name="Icon awesome-plus"
                d="M7.621,5.051H4.329V1.759A.509.509,0,0,0,3.82,1.25H3.311a.509.509,0,0,0-.509.509V5.051H-.491A.509.509,0,0,0-1,5.561V6.07a.509.509,0,0,0,.509.509H2.8V9.871a.509.509,0,0,0,.509.509H3.82a.509.509,0,0,0,.509-.509V6.579H7.621A.509.509,0,0,0,8.13,6.07V5.561A.509.509,0,0,0,7.621,5.051Z"
                transform="translate(1 -1.25)"
              ></path>
            </svg>
          </a>
        ) : (
          <a className="btn_search icon_container">
            <svg width="13.807" height="13.807" viewBox="0 0 13.807 13.807">
              <g
                id="Icon_feather-search"
                data-name="Icon feather-search"
                transform="translate(-3.75 -3.75)"
              >
                <path
                  id="Path_1034"
                  data-name="Path 1034"
                  fill="none"
                  stroke="#b8c0c9"
                  d="M15.163,9.832A5.332,5.332,0,1,1,9.832,4.5a5.332,5.332,0,0,1,5.332,5.332Z"
                />
                <path
                  id="Path_1035"
                  data-name="Path 1035"
                  fill="none"
                  stroke="#b8c0c9"
                  d="M27.874,27.874l-2.9-2.9"
                  transform="translate(-11.378 -11.378)"
                />
              </g>
            </svg>
          </a>
        )}
      </div>
    </>
  );
};

export default memo(SidebarSearch);

const MacType = ({ show, handleClose, launchUrl, alertDownload }) => {
  let MacURL = "https://desktop.c2perform.com/C2-Connect.dmg";
  const downloadAppForMac = () => {
    window.open(MacURL, "_blank");
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title></Modal.Title>
        <a onClick={handleClose} className="icon_container">
          <svg width="13" viewBox="6.29 6.34 11.31 11.31">
            <path
              fill="#425b76"
              d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
            ></path>
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <div>
          {" "}
          <a
            href={launchUrl}
            target="_blank"
            className="btn btn-orange btn--download"
            rel="noreferrer"
          >
            {alertMsg.launchMac}
          </a>
        </div>
        <div>
          {" "}
          <a
            onClick={downloadAppForMac}
            className="btn btn-orange btn--download"
          >
            {alertMsg.downloadMac}
          </a>
        </div>
        {alertDownload ? <div>{alertMsg.prepDownloading}</div> : ""}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

const WindowsType = ({
  show,
  handleClose,
  launchUrl,
  setAlertDownload,
  alertDownload,
}) => {
  let WindowsURL = "https://desktop.c2perform.com/C2-Connect.exe";
  // eslint-disable-next-line no-unused-vars
  let test = "https://i.ibb.co/H7k5gYM/scott-webb-a-Hhhd-KUP77-M-unsplash.jpg";
  const downloadAppForWindow = () => {
    setAlertDownload(true);
    window.open(WindowsURL, "_blank");
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title></Modal.Title>
        <a onClick={handleClose} className="icon_container">
          <svg width="13" viewBox="6.29 6.34 11.31 11.31">
            <path
              fill="#425b76"
              d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
            ></path>
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center flex-column">
        {" "}
        <a
          href={launchUrl}
          target="_blank"
          className="btn btn-orange btn--download"
          // without
          rel="noreferrer"
        >
          {alertMsg.launchWindow}
        </a>{" "}
        <button
          onClick={downloadAppForWindow}
          className="btn btn-orange btn--download"
        >
          {alertMsg.downloadWindow}
        </button>
        {alertDownload ? <div>{alertMsg.prepDownloading}</div> : ""}
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};
