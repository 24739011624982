/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Howl } from "howler";
import ReactTooltip from "react-tooltip";

import { getAuthSession, isElectron, privateChatID } from "../Helpers/common";
import {
  _userUpdate,
  _sendEvent,
  _updateCall,
  // _sendRoom,
  // _getRoomDetail,
  _sendScreenInfoNotify,
} from "../../SocketIo";
import OutBoundCall from "../models/OutBound";
import InBoundCall from "../models/InBoundCall";
import UserOnCall from "../models/UserOnCall";
// import Screenspy from "../Helpers/Lib/screenspy";
import {
  CallMonitorStatusChangeAction,
  DirectOutBound,
  callDataChangeActions,
} from "../Helpers/CallCommon";
import { callSoundDetail } from "../util/sounds";
import {
  setCallRoom,
  setCallStartUserDetail,
  setIncomingTone,
  setIsOpenWhiteBoard,
  setMonitorScreeens,
  setMonitorUser,
  setRecordingConsentPopUpEnable,
  setUseronCall,
} from "../redux/callSlice";
import {
  getUserOrGroupListWithoutSearch,
  openUserChat,
} from "../Helpers/chatCommon";
import { globalStrings } from "../util/translation/languages";
// import { getScreensInfo } from "../Helpers/Lib/screenShareInfo";
import DesktopScreenMonitorModal from "../models/DesktopScreenMonitorModal";
import Screenspy from "../Helpers/Lib/screenspy";

// eslint-disable-next-line no-unused-vars
const SidebarDirectMessage = (props) => {
  const { privateTypingData, loaderActiveData } = props;
  const dispatch = useDispatch();
  const sidebarActiveData = useSelector(
    (state) => state.chat.sidebarActiveData,
  );
  const attendeeList = useSelector((state) => state.chat.attendeeList);

  const searchAttendeeData = useSelector(
    (state) => state.chat.searchAttendeeData,
  );
  const unreadChatMessage = useSelector(
    (state) => state.chat.unreadChatMessage,
  );
  const selectedLanguage = useSelector((state) => state.chat.selectedLanguage);
  const loderAttendeeDirect = useSelector(
    (state) => state.chat.loderAttendeeDirect,
  );
  const loader = loderAttendeeDirect;
  const authSessionUser = useSelector(
    (state) => state.authUserDetail.authSessionUser,
  );
  const authSession = useSelector(
    (state) => state.authUserDetail.authSession,
  )?.data;

  const allAttendeeList = useSelector((state) => state.call.allAttendeeList);
  const callStartUserDetail = useSelector(
    (state) => state.call.callStartUserDetail,
  );
  const callRoom = useSelector((state) => state.call.callRoom);
  const room = callRoom;
  const outBoundCallPopUpShow = useSelector(
    (state) => state.call.outBoundCallPopUpShow,
  );
  const inBoundCallPopUpShow = useSelector(
    (state) => state.call.inBoundCallPopUpShow,
  );
  const callDetail = useSelector((state) => state.call.callDetail);
  const screenSpyStatus = useSelector((state) => state.call.screenSpyStatus);
  const recordingConsentUserIds = useSelector(
    (state) => state.call.recordingConsentUserIds,
  );

  const monitorScreens = useSelector((state) => state.call.monitorScreens);

  const sessionThemeData = useSelector(
    (state) => state.authUserDetail.sessionThemeData,
  );
  const companyDetail = useSelector(
    (state) => state?.authUserDetail?.companyDetail,
  );
  // eslint-disable-next-line no-unused-vars
  const { user_unique_key: senderIDD, company_unique_key } = getAuthSession();
  // const [directMessageAttendeeList, setDirectMessageAttendeeList] = useState(
  //   [],
  // );
  const [isExpand, setIsExpand] = useState(true);
  const [showDesktopMonitor, setShowDesktopMonitor] = useState(false);

  useEffect(() => {
    let callSoundData = "";
    if (authSessionUser?.call_sound !== undefined) {
      callSoundData = callSoundDetail.find(
        (findItem) => findItem.name === authSessionUser?.call_sound,
      )?.value;
    } else {
      callSoundData = callSoundDetail[0].value;
    }

    const incoming_tone = new Howl({
      // eslint-disable-next-line quotes
      src: [callSoundData],
      loop: true,
      volume: 0.5,
    });

    dispatch(setIncomingTone(incoming_tone));
  }, [authSessionUser?.call_sound]);

  // useEffect(() => {
  //   let list = getUserOrGroupListWithSearch("user");
  //   setDirectMessageAttendeeList(list);
  // }, [attendeeList, searchAttendeeData, unreadChatMessage]);

  const directMessageAttendee = useMemo(() => {
    return getUserOrGroupListWithoutSearch("user");
  }, [attendeeList, unreadChatMessage]);

  const searchedAttendeeList = useMemo(() => {
    const list = searchAttendeeData
      ? directMessageAttendee.filter((val) =>
          val.name?.toLowerCase()?.match(searchAttendeeData?.toLowerCase()),
        )
      : directMessageAttendee;

    return list;
  }, [searchAttendeeData]);

  useEffect(() => {
    callDataChangeActions();
  }, [callDetail]);

  useEffect(() => {
    CallMonitorStatusChangeAction();
  }, [callDetail]);

  const callSetupHandler = (data) => {
    dispatch(setCallStartUserDetail({}));
    const { company_id } = data;
    let room = privateChatID([senderIDD, data?.user_id]);
    dispatch(setCallRoom(room));
    StartCall(company_id, room, data);
    const ObjPayload = {
      senderID: senderIDD,
      event_type: "call-notification", // typing-start / typing-end / call-notification / msg-notification
      receiverID: data?.user_id, // receiverID or groupID
      type: "private", // private or group
      message: "call",
      company_id: company_id,
      timestamp: Date.now(),
      documentID: room,
    };
    _sendEvent(ObjPayload);
  };

  const StartCall = async (company_id, room, data) => {
    let host = senderIDD;
    let hostUserData = allAttendeeList?.find((obj) => obj.user_id === host);
    const updatedHostUserData = { ...hostUserData };
    updatedHostUserData["roomId"] = room;
    updatedHostUserData["onCall"] = true;

    const participantID = [
      {
        id: host,
        status: "accepted",
        hand_raise: false,
        audio: false,
        screenShare: false,
      },
      {
        id: data?.user_id,
        status: "initiated",
        hand_raise: false,
        audio: false,
        screenShare: false,
      },
    ];

    _userUpdate(updatedHostUserData);
    const callPayload = {
      calling_status: "initiated",
      senderID: host,
      timestamp: Date.now(),
      receiverID: data?.user_id,
      company_id: company_id,
      monitor_status: false,
      documentID: room, // room
      type: "private", // "private" : "group
      participantID: participantID,
      user_added: false,
      recordingTimestamp: null,
      hand_raise: false,
      sharedFile: {},
      entireScreenSharedIds: [],
    };
    await _updateCall(callPayload);
  };

  useEffect(() => {
    if (Object.keys(callStartUserDetail).length > 0) {
      if (callDetail?.monitor_status === false) {
        setTimeout(() => {
          callSetupHandler(callStartUserDetail);
        }, [2000]);
      }
    }
  }, [callDetail]);

  useEffect(() => {
    if (Object.keys(callDetail).length > 0) {
      const { whiteBoard, type, calling_status, participantID } = callDetail;
      if (whiteBoard) {
        if (type === "private" && !callDetail?.user_added) {
          if (calling_status === "accepted") {
            dispatch(setIsOpenWhiteBoard(true));
          }
        } else {
          const tmpUserData = participantID.find((v) => v.id === senderIDD);
          if (tmpUserData?.status === "accepted") {
            dispatch(setIsOpenWhiteBoard(true));
          }
        }
      } else {
        dispatch(setIsOpenWhiteBoard(false));
      }
    }
  }, [callDetail]);

  useEffect(() => {
    if (
      recordingConsentUserIds.length > 0 &&
      recordingConsentUserIds.includes(senderIDD)
    ) {
      dispatch(setRecordingConsentPopUpEnable(true));
    } else {
      dispatch(setRecordingConsentPopUpEnable(false));
    }
  }, [recordingConsentUserIds]);

  const directMessageAttendeeList = searchAttendeeData
    ? searchedAttendeeList
    : directMessageAttendee?.slice(0, 100);

  useEffect(() => {
    if (Object.keys(monitorScreens).length > 0) {
      setShowDesktopMonitor(true);
    } else {
      setShowDesktopMonitor(false);
    }
  }, [monitorScreens]);

  return (
    <>
      <div className="message-list-head d-flex align-items-center pb-0">
        <a
          onClick={() => setIsExpand(!isExpand)}
          className={`icon_container expend-chevron ${
            !isExpand ? "active" : ""
          }`}
        >
          {sessionThemeData ? (
            isExpand ? (
              <svg width="9.13" height="9.13" viewBox="5 9 14 8">
                <path fill="#b8c0c9" d="m11.998 17 7-8h-14z"></path>
              </svg>
            ) : (
              <svg width="9.13" height="9.13" viewBox="5 9 14 8">
                <path fill="#b8c0c9" d="m11.998 17 7-8h-14z"></path>
              </svg>
            )
          ) : isExpand ? (
            <svg width="9.13" height="9.13" viewBox="5 9 14 8">
              <path fill="#b8c0c9" d="m11.998 17 7-8h-14z"></path>
            </svg>
          ) : (
            <svg width="9.13" height="9.13" viewBox="5 9 14 8">
              <path fill="#b8c0c9" d="m11.998 17 7-8h-14z"></path>
            </svg>
          )}
        </a>
        <span className="text-green">
          {globalStrings[selectedLanguage]?.$directMessage}
        </span>
      </div>

      {loader == true ? (
        <div className="w-100 d-flex align-items-center justify-content-center h-60">
          <div className="loader_spinner__box__sm">
            <div className="spin"></div>
          </div>
        </div>
      ) : (
        <>
          {isExpand && (
            <div className="direct-message">
              {directMessageAttendeeList.map((data, index) => {
                let role_name = data.role;
                let availabilityStatus = data?.online ?? "false";
                let onCall = data.user_id === senderIDD ? data.onCall : false;
                return (
                  <Attendee
                    key={index}
                    data={data}
                    sidebarActiveData={sidebarActiveData}
                    availabilityStatus={availabilityStatus}
                    role_name={role_name}
                    senderIDD={senderIDD}
                    callDetail={callDetail}
                    attendeeIndex={index}
                    privateTypingData={privateTypingData}
                    loaderActiveData={loaderActiveData}
                    unreadChatMessage={unreadChatMessage}
                    setCallStartUserDetail={setCallStartUserDetail}
                    callSetupHandler={callSetupHandler}
                    company_unique_key={company_unique_key}
                    allAttendeeList={allAttendeeList}
                    selectedLanguage={selectedLanguage}
                    authSession={authSession}
                    onCall={onCall}
                    companyDetail={companyDetail}
                  />
                );
              })}
            </div>
          )}
        </>
      )}
      {outBoundCallPopUpShow && callDetail?.type === "private" ? (
        <OutBoundCall selectedLanguage={selectedLanguage} />
      ) : null}

      {inBoundCallPopUpShow && callDetail?.type === "private" ? (
        <InBoundCall selectedLanguage={selectedLanguage} />
      ) : null}

      {screenSpyStatus && (
        <Screenspy
          room={room}
          user_unique_key={senderIDD}
          allAttendeeList={allAttendeeList}
          selectedMonitorScreen={callDetail?.selectedMonitorScreen}
        />
      )}
      {showDesktopMonitor && (
        <DesktopScreenMonitorModal
          handleClose={() => dispatch(setMonitorScreeens({}))}
          show={showDesktopMonitor}
        />
      )}
    </>
  );
};

const Attendee = ({
  data,
  availabilityStatus,
  role_name,
  sidebarActiveData,
  senderIDD,
  attendeeIndex,
  privateTypingData,
  loaderActiveData,
  unreadChatMessage,
  selectedLanguage,
  authSession,
  onCall,
  companyDetail,
}) => {
  let isTypingStatus = useMemo(() => {
    return (
      privateTypingData?.find((obj) => obj.senderID === data?.user_id)
        ?.event_type ?? "typing-end"
    );
  }, [privateTypingData]);

  let unreadMessageCount = useMemo(() => {
    return (
      unreadChatMessage.find(
        (filterItem) => filterItem.user_id === data?.user_id,
      )?.count ?? 0
    );
  }, [unreadChatMessage]);

  const authSessionUser = useSelector(
    (state) => state.authUserDetail.authSessionUser,
  );

  const dispatch = useDispatch();
  const { userOnCall } = useSelector((state) => state.call);

  const busyPopUpHandler = (user_id) => {
    dispatch(setUseronCall(user_id));
  };

  const monitorHandle = async () => {
    if (data?.onCall === false) {
      const { user_id, company_id } = data;
      dispatch(setMonitorUser(data));
      _sendScreenInfoNotify({
        company_id: company_id,
        user_id: user_id,
        sender_id: senderIDD,
      });
    }
  };

  const userOnCallHandleClose = () => {
    dispatch(setUseronCall(false));
  };

  return (
    <>
      <div
        className={`head align-items-center ${
          sidebarActiveData === data?.user_id ? "selected" : ""
        }`}
        onClick={() => {
          !loaderActiveData && authSession?.chat === true
            ? openUserChat(data, false)
            : "";
        }}
      >
        <div className="user align-items-start">
          <div
            className={`avatar ${
              isTypingStatus === "typing-start" ? "person-typing" : ""
            }`}
          >
            {isTypingStatus === "typing-start" ? (
              <div className="typing">
                <svg
                  id="typing_bubble"
                  data-name="typing bubble"
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="66"
                  viewBox="0 0 120 66"
                >
                  <g>
                    <circle className="typing-dot" cx="46" cy="30" r="8" />
                    <circle className="typing-dot" cx="68" cy="30" r="8" />
                    <circle className="typing-dot" cx="90" cy="30" r="8" />
                  </g>
                </svg>
              </div>
            ) : (
              <img
                className="h-100"
                src={`${
                  data.user_profile_image ? (
                    data.user_profile_image
                  ) : (
                    <svg viewBox="3 2 18 19">
                      <path
                        fill="#F85800"
                        d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"
                      ></path>
                    </svg>
                  )
                }`}
                alt=""
              ></img>
            )}

            <span
              className={`status ${
                availabilityStatus === true ? "online" : ""
              }`}
            ></span>
          </div>
          <div className="d-flex flex-column contact_info">
            <div className="d-flex align-items-center">
              <div
                className={`name unread-msg-name ${
                  unreadMessageCount > 0 ? "text-bold" : ""
                }`}
              >
                {data.name}
              </div>
            </div>
            <small
              className={`text-small ${
                unreadMessageCount > 0 ? "text-bold" : ""
              }`}
            >
              {role_name}
            </small>
          </div>
        </div>
        <span className="d-flex align-items-center">
          {unreadMessageCount > 0 && (
            <span className="chat_count d-flex align-items-center justify-content-center align-self-center mr-2">
              {unreadMessageCount > 9 ? "9+" : unreadMessageCount}
            </span>
          )}

          {companyDetail?.peek === true &&
          authSessionUser?.peek === true &&
          isElectron ? (
            <a
              className="icon d-flex moni-icon
          align-items-center content--h-end icon_container"
              onClick={() => monitorHandle()}
            >
              <svg width="15.826" height="12.867" viewBox="2 3 20 19">
                <path
                  fill="#b8c0c9"
                  d="M20 3H4c-1.103 0-2 .897-2 2v11c0 1.103.897 2 2 2h7v2H8v2h8v-2h-3v-2h7c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 14V5h16l.002 9H4z"
                ></path>
              </svg>
            </a>
          ) : (
            ""
          )}
          {authSession?.video === true ? (
            <a
              className={`icon d-flex align-items-center icon_container ${
                data?.onCall === true ? "text-green" : ""
              } `}
              href={void 0}
              onClick={(e) => {
                {
                  onCall
                    ? busyPopUpHandler(data?.user_id)
                    : DirectOutBound(data);
                  e.stopPropagation();
                }
              }}
            >
              {data?.onCall === true ? (
                <svg width="15.826" height="12.867" viewBox="0 0 15.826 11.867">
                  <g
                    id="Group_2303"
                    data-name="Group 2303"
                    transform="translate(-7469.257 -3666.066)"
                  >
                    <path
                      fill="#b8c0c9"
                      id="video-camera"
                      d="M15.1,129.938a1.31,1.31,0,0,0-1.379.121l-1.208.9a3.3,3.3,0,0,0-3.28-2.967H3.3a3.3,3.3,0,0,0-3.3,3.3v5.274a3.3,3.3,0,0,0,3.3,3.3H9.23a3.3,3.3,0,0,0,3.28-2.967l1.206.9a1.318,1.318,0,0,0,2.11-1.055v-5.618A1.311,1.311,0,0,0,15.1,129.938Zm-3.89,6.622a1.978,1.978,0,0,1-1.978,1.978H3.3a1.978,1.978,0,0,1-1.978-1.978v-5.274A1.978,1.978,0,0,1,3.3,129.308H9.23a1.978,1.978,0,0,1,1.978,1.978Zm3.3.169-1.978-1.474v-2.665l1.978-1.474Z"
                      transform="translate(7469.257 3538.077)"
                    />
                    <rect
                      fill="#2bc18d"
                      id="Rectangle_698"
                      data-name="Rectangle 698"
                      width="7"
                      height="6"
                      rx="1.5"
                      transform="translate(7472 3669)"
                    />
                  </g>
                </svg>
              ) : (
                <>
                  <div className="tooltip">
                    <div
                      className="d-flex"
                      data-tip
                      data-for={`registerTip${attendeeIndex}`}
                    >
                      <svg
                        width="15.826"
                        height="12.867"
                        viewBox="0 0 15.826 11.867"
                      >
                        <path
                          fill="#b8c0c9"
                          id="video-camera"
                          d="M15.1,129.938a1.31,1.31,0,0,0-1.379.121l-1.208.9a3.3,3.3,0,0,0-3.28-2.967H3.3a3.3,3.3,0,0,0-3.3,3.3v5.274a3.3,3.3,0,0,0,3.3,3.3H9.23a3.3,3.3,0,0,0,3.28-2.967l1.206.9a1.318,1.318,0,0,0,2.11-1.055v-5.618A1.311,1.311,0,0,0,15.1,129.938Zm-3.89,6.622a1.978,1.978,0,0,1-1.978,1.978H3.3a1.978,1.978,0,0,1-1.978-1.978v-5.274A1.978,1.978,0,0,1,3.3,129.308H9.23a1.978,1.978,0,0,1,1.978,1.978Zm3.3.169-1.978-1.474v-2.665l1.978-1.474Z"
                          transform="translate(0 -127.99)"
                        />
                      </svg>
                    </div>
                    <ReactTooltip
                      id={`registerTip${attendeeIndex}`}
                      place="top"
                      effect="solid"
                    >
                      {globalStrings[selectedLanguage]?.$videoCall} {data.name}
                    </ReactTooltip>
                  </div>
                </>
              )}
            </a>
          ) : null}
        </span>
      </div>
      {userOnCall === data?.user_id && (
        <UserOnCall
          userOnCall={userOnCall}
          handleClose={userOnCallHandleClose}
          name={data?.name ?? "user"}
          onCall={onCall}
          selectedLanguage={selectedLanguage}
        />
      )}
    </>
  );
};

export default SidebarDirectMessage;
