import React from "react";
import { Modal, Button } from "react-bootstrap";

import { globalStrings } from "../util/translation/languages";

const FileSize = ({
  fileSizeShow,
  handleClose,
  fileName,
  selectedLanguage,
}) => {
  return (
    <Modal
      className="attachment_info"
      show={fileSizeShow}
      onHide={() => handleClose()}
    >
      <Modal.Body>
        <h6 className="mb-0">
          {fileName} {globalStrings[selectedLanguage]?.$fileSizeMsg}
        </h6>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-end">
        <Button
          className="btn btn-orange ml-auto"
          variant="secondary"
          onClick={() => handleClose()}
        >
          {globalStrings[selectedLanguage]?.$close}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileSize;
