import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { _deleteChatMessage } from "../../SocketIo";
import { timestampToDateTimeDelete } from "../Helpers/common";
import { setDeleteMessage } from "../redux/chatSlice";
import { globalStrings } from "../util/translation/languages";

const ChatDelete = (props) => {
  const { showDelete, handleClose, deleteData, userInfo, selectedLanguage } =
    props;
  const dispatch = useDispatch();
  let deletedMessageIds = useSelector((state) => state.chat.deletedMessageIds);
  const deleteChatMessage = () => {
    deleteData.messageID = deleteData?.id;
    _deleteChatMessage(deleteData);
    let deletedIds = [...deletedMessageIds, deleteData?.messageID];
    dispatch(setDeleteMessage(deletedIds));
    handleClose();
  };

  const retrieveFileName = (data) => {
    const fileNames = data.map((item) => item.originalFileName).join(", ");
    return fileNames;
  };

  return (
    <>
      <Modal show={showDelete}>
        <Modal.Header>
          <Modal.Title>
            {globalStrings[selectedLanguage]?.$deleteMsg}
          </Modal.Title>
          <button onClick={handleClose} className="icon-close p-0">
            <svg
              width="15.556"
              height="15.556"
              viewBox="2240.448 1762.444 15.556 15.556"
            >
              <path
                d="m2250.727 1766.055-2.5 2.5-2.5-2.5a.786.786 0 0 0-1.112 0l-.555.556a.786.786 0 0 0 0 1.11l2.5 2.5-2.5 2.501a.786.786 0 0 0 0 1.111l.555.556a.786.786 0 0 0 1.111 0l2.5-2.5 2.5 2.5a.786.786 0 0 0 1.112 0l.555-.556a.786.786 0 0 0 0-1.11l-2.5-2.501 2.5-2.5a.786.786 0 0 0 0-1.111l-.555-.556a.786.786 0 0 0-1.111 0Z"
                fill="#2d343f"
              ></path>
            </svg>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p className="text-thin">
            {globalStrings[selectedLanguage]?.$pTagDeleteMessage}
          </p>
          <p className="text-thin">
            <b>{userInfo.name} : </b>
            {timestampToDateTimeDelete(deleteData.timestamp)}
            <br />
            {deleteData.message === "c2-attach-files"
              ? retrieveFileName(deleteData?.attachments)
              : deleteData.message}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-light btn btn-secondary"
            type="cancel"
            id="cancel"
            onClick={handleClose}
          >
            {globalStrings[selectedLanguage]?.$cancel}
          </Button>
          <Button
            className="btn btn-orange btn btn-secondary"
            type="submit"
            id="submit"
            onClick={() => deleteChatMessage()}
          >
            {globalStrings[selectedLanguage]?.$delete}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChatDelete;
