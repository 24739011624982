import React from "react";
import { useStopwatch } from "react-timer-hook";

function Start() {
  const { seconds, minutes, hours } = useStopwatch({ autoStart: true });

  return (
    <>
      <span>{hours.toString().length === 1 ? "0" + hours : hours}</span>:
      <span>{minutes.toString().length === 1 ? "0" + minutes : minutes}</span>:
      <span>{seconds.toString().length === 1 ? "0" + seconds : seconds}</span>
    </>
  );
}

export default function Timer() {
  return (
    <div>
      <Start />
    </div>
  );
}
