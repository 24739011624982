// chat sounds
import IncomingMessageAudio from "../../assets/sound/chatSounds/incoming_message.wav";
import ChatAudioOne from "../../assets/sound/chatSounds/chat-1.wav";
import ChatAudioTwo from "../../assets/sound/chatSounds/chat-2.wav";
import ChatAudioThree from "../../assets/sound/chatSounds/chat-3.wav";
import ChatAudioFour from "../../assets/sound/chatSounds/chat-4.wav";
import ChatAudioFive from "../../assets/sound/chatSounds/chat-5.wav";
import ChatAudioSix from "../../assets/sound/chatSounds/chat-6.wav";
import ChatAudioSeven from "../../assets/sound/chatSounds/chat-7.wav";

//call sounds
import IncomingCallAudio from "../../assets/sound/callSounds/348584__aldenb4610__phone-outgoing-call.wav";
import CallAudioOne from "../../assets/sound/chatSounds/chat-1.wav";
import CallAudioTwo from "../../assets/sound/chatSounds/chat-2.wav";
import CallAudioThree from "../../assets/sound/chatSounds/chat-3.wav";
import CallAudioFour from "../../assets/sound/chatSounds/chat-4.wav";
import CallAudioFive from "../../assets/sound/chatSounds/chat-5.wav";
import CallAudioSix from "../../assets/sound/chatSounds/chat-6.wav";
import CallAudioSeven from "../../assets/sound/chatSounds/chat-7.wav";

export const callSoundDetail = [
    {name:"348584__aldenb4610__phone-outgoing-call.wav",value:IncomingCallAudio, label:"Default"},
    {name:"call-1.wav",value:ChatAudioOne, label:"Bell"},
    {name:"call-2.wav",value:ChatAudioTwo, label:"Alert"},
    {name:"call-3.wav",value:ChatAudioThree, label:"Ring"},
    {name:"call-4.wav",value:ChatAudioFour, label:"Echo"},
    {name:"call-5.wav",value:ChatAudioFive, label:"Knock Knock"},
    {name:"call-6.wav",value:ChatAudioSix, label:"Marimba"},
    {name:"call-7.wav",value:ChatAudioSeven, label:"Bright"},
]

export const chatSoundDetail = [
    {name:"incoming_message.wav",value:IncomingMessageAudio, label:"Default"},
     {name:"chat-1.wav",value:CallAudioOne, label:"Hello"},
    {name:"chat-2.wav",value:CallAudioTwo, label:"Chord"},
    {name:"chat-3.wav",value:CallAudioThree, label:"Vibe"},
    {name:"chat-4.wav",value:CallAudioFour, label:"Bling"},
    {name:"chat-5.wav",value:CallAudioFive, label:"Pop up"},
    {name:"chat-6.wav",value:CallAudioSix, label:"Glitter"},
    {name:"chat-7.wav",value:CallAudioSeven, label:"Bip"},
]