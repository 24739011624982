/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useState } from "react";

import { attachmentsDownload } from "../Helpers/common";
import FileType from "./fileType";
import ImagePreviewModal from "../models/ImagePreviewModal";

const AttachmentsFiles = (props) => {
  const { attachmentItem, messageDetail } = props;
  const [downloadFile, setDownloadFile] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const downloadAttachment = (e, id, filename, originalName, type) => {
    setDownloadFile([...downloadFile, id]);
    // eslint-disable-next-line indent
    attachmentsDownload(
      id,
      filename,
      originalName,
      setDownloadFile,
      downloadFile,
      type,
      messageDetail,
    );
  };
  const openModal = () => {
    if (
      attachmentItem?.attachment_type == "png" ||
      attachmentItem?.attachment_type == "jpg" ||
      attachmentItem?.attachment_type == "jpeg" ||
      attachmentItem?.attachment_type == "jfif"
    ) {
      setIsOpenModal(true);
    }
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  return (
    <>
      <div className="attached_file">
        <div
          className={`avatar ${attachmentItem.imagePreview ? "chat-img" : ""}`}
          onClick={openModal}
        >
          <FileType attachmentItem={attachmentItem} />
        </div>
        <div
          className={`${
            downloadFile.includes(attachmentItem.attachmentId)
              ? "downloading-loader actions"
              : ""
          }`}
        >
          <div
            className={`${
              downloadFile.includes(attachmentItem.attachmentId) ? "spin" : ""
            }`}
          ></div>
        </div>

        {!downloadFile.includes(attachmentItem.attachmentId) && (
          // eslint-disable-next-line react/jsx-no-comment-textnodes
          <div className="actions">
            <button
              className="attached_file_btn"
              onClick={(e) =>
                downloadAttachment(
                  e,
                  attachmentItem.attachmentId,
                  attachmentItem.attachmentName,
                  attachmentItem.documentID,
                )
              }
            >
              <svg width="15" viewBox="2 4 20 16">
                <path fill="#ffffff" d="m12 16 4-5h-3V4h-2v7H8z"></path>
                <path
                  fill="#ffffff"
                  d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"
                ></path>
              </svg>
            </button>
          </div>
        )}
      </div>
      {isOpenModal && (
        <ImagePreviewModal
          show={true}
          closeModal={closeModal}
          attachmentItem={attachmentItem}
        />
      )}
    </>
  );
};

export default memo(AttachmentsFiles);
