import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getReactionUserName } from "../Helpers/chatCommon";
import { alertMsg } from "../util/alertMsg";

const ReactionUserPopOver = () => {
  const selectedReactionUser = useSelector(
    (state) => state.chat.selectedReactionUser,
  );
  const [userNameData, setUserNameData] = useState([]);

  useEffect(async () => {
    const res = await getReactionUserName(selectedReactionUser?.reactionUserId);
    setUserNameData(res);
  }, [selectedReactionUser]);

  return (
    <>
      <div className="reactions-popup-container">
        <div className="reactions-popup">
          <div className="d-flex flex-column text-center align-items-center">
            {userNameData?.length > 0 ? (
              <>
                <div className="reactions-icon">
                  {selectedReactionUser?.emoji}
                </div>
                <div className="reactions-username">
                  {userNameData?.join(", ")}{" "}
                  {selectedReactionUser?.names?.length > 0 ? (
                    <b className="reactions-name">
                      &nbsp;
                      {`reacted with :${
                        selectedReactionUser.names.length > 1
                          ? selectedReactionUser.names[1]
                          : selectedReactionUser.names[0]
                      }:`}{" "}
                    </b>
                  ) : null}
                </div>
              </>
            ) : (
              alertMsg.loader
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReactionUserPopOver;
