/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";

function MainIframe() {
  const [iframeOpen, setIframeOpen] = useState(false);
  let token = useParams();
  let loginToken = token.id;

  const handleClose = () => {
    setIframeOpen(false);
  };

  return (
    <>
      <Button
        className="btn btn-orange"
        type="submit"
        id="submit"
        variant="primary"
        onClick={() => {
          setIframeOpen(true);
        }}
      >
        Chat
      </Button>
      <Modal
        show={iframeOpen}
        size="xl"
        onHide={handleClose}
        className="mainIframeModal"
      >
        <Modal.Header>
          <a onClick={handleClose} className="icon_container">
            <svg width="13" viewBox="6.29 6.34 11.31 11.31">
              <path
                fill="#425b76"
                d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"
              ></path>
            </svg>
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="mainIframe">
            <iframe
              title="test"
              width="100%"
              height="100%"
              src={`/dashboard/${loginToken}`}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation allow-downloads"
            ></iframe>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MainIframe;
