/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import SimpleReactValidator from "simple-react-validator";
import { useDispatch, useSelector } from "react-redux";

import { _groupUpdate } from "../../SocketIo";
import { setAttendeeData } from "../redux/chatSlice";
import { globalStrings } from "../util/translation/languages";

const GroupEditTitleAndDescription = (props) => {
  const { show, handleClose, editType } = props;
  const dispatch = useDispatch();

  const attendeeData = useSelector((state) => state.chat.attendeeData);
  const selectedLanguage = useSelector((state) => state.chat.selectedLanguage);
  const [inputText, setInputText] = useState("");
  const [, forceUpdate] = useState();
  const validator = useRef(
    new SimpleReactValidator({
      autoForceUpdate: { forceUpdate: forceUpdate },
    }),
  );

  useEffect(() => {
    setInputText(
      editType == "name" ? attendeeData?.groupName : attendeeData?.description,
    );
  }, [attendeeData]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      const updatedGroupData = { ...attendeeData };
      if (editType === "name") {
        updatedGroupData["groupName"] = inputText;
      } else {
        updatedGroupData["description"] = inputText;
      }
      _groupUpdate(updatedGroupData);
      dispatch(setAttendeeData(updatedGroupData));
      setInputText("");
      handleClose();
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>
            <span className="title-span">
              {editType === "name"
                ? globalStrings[selectedLanguage]?.$editGroupName
                : globalStrings[selectedLanguage]?.$editGroupDescription}
            </span>
          </Modal.Title>
          <a onClick={handleClose} className="icon-close">
            <svg
              width="15.556"
              height="15.556"
              viewBox="2240.448 1762.444 15.556 15.556"
            >
              <path
                d="m2250.727 1766.055-2.5 2.5-2.5-2.5a.786.786 0 0 0-1.112 0l-.555.556a.786.786 0 0 0 0 1.11l2.5 2.5-2.5 2.501a.786.786 0 0 0 0 1.111l.555.556a.786.786 0 0 0 1.111 0l2.5-2.5 2.5 2.5a.786.786 0 0 0 1.112 0l.555-.556a.786.786 0 0 0 0-1.11l-2.5-2.501 2.5-2.5a.786.786 0 0 0 0-1.111l-.555-.556a.786.786 0 0 0-1.111 0Z"
                fill="#2d343f"
              />
            </svg>
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="text-label">
              {editType === "name"
                ? globalStrings[selectedLanguage]?.$groupName
                : globalStrings[selectedLanguage]?.$groupDescription}
            </label>
            <input
              spellCheck="false"
              type="text"
              onChange={(e) => setInputText(e.target.value)}
              name="group"
              placeholder={globalStrings[selectedLanguage]?.$groupName}
              className="form-control"
              list="channels"
              id="channel"
              value={inputText}
            />
            {validator.current.message(
              editType === "name"
                ? globalStrings[selectedLanguage]?.$groupName
                : globalStrings[selectedLanguage]?.$description,
              inputText,
              "required",
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
          <Button
            className="btn btn-orange"
            type="submit"
            id="submit"
            variant="primary"
            onClick={(e) => {
              submitHandler(e);
            }}
          >
            {globalStrings[selectedLanguage]?.$save}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GroupEditTitleAndDescription;
