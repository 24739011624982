import React from "react";
import { Modal } from "react-bootstrap";

import { globalStrings } from "../util/translation/languages";

const UserOnCall = ({
  userOnCall,
  handleClose,
  name,
  onCall,
  selectedLanguage,
}) => {
  return (
    <Modal show={userOnCall} onHide={() => handleClose(false)}>
      <Modal.Body className="d-flex align-items-center flex-column user-another-call-popup">
        <h6 className={`${onCall ? "text-center" : ""}`}>
          {onCall
            ? globalStrings[selectedLanguage]?.$cannotPlaceCall
            : `${name} ${globalStrings[selectedLanguage]?.$anotherCall}`}
        </h6>
        <button
          className={`btn btn-light ml-2 ${onCall ? "mt-3" : ""}`}
          onClick={() => {
            handleClose(false);
          }}
        >
          {globalStrings[selectedLanguage]?.$cancel}
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default UserOnCall;
