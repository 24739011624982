/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Modal } from "react-bootstrap";

import UserProfilePopOver from "./userProfilePopOver";

const UserProfileModal = ({
  show,
  closeModal,
  userInfo,
  isOpenModal,
  selectedLanguage,
}) => {
  return (
    <Modal show={show} onHide={() => closeModal()}>
      <Modal.Header>
        <a className="icon-close top-10" onClick={() => closeModal()}>
          <svg
            width="15.556"
            height="15.556"
            viewBox="2240.448 1762.444 15.556 15.556"
          >
            <path
              d="m2250.727 1766.055-2.5 2.5-2.5-2.5a.786.786 0 0 0-1.112 0l-.555.556a.786.786 0 0 0 0 1.11l2.5 2.5-2.5 2.501a.786.786 0 0 0 0 1.111l.555.556a.786.786 0 0 0 1.111 0l2.5-2.5 2.5 2.5a.786.786 0 0 0 1.112 0l.555-.556a.786.786 0 0 0 0-1.11l-2.5-2.501 2.5-2.5a.786.786 0 0 0 0-1.111l-.555-.556a.786.786 0 0 0-1.111 0Z"
              fill="#2d343f"
            ></path>
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center justify-content-center">
        <UserProfilePopOver
          userInfo={userInfo}
          isOpenModal={isOpenModal}
          closeModal={closeModal}
          selectedLanguage={selectedLanguage}
        />
      </Modal.Body>
    </Modal>
  );
};

export default UserProfileModal;
