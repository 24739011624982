import React from "react";

import {
  _userUpdate,
  _updateCall,
  _groupUpdate,
  _sendEvent,
  _sendRoom,
  _getRoomDetail,
  whiteBoardElemets,
  clearWhiteBoardData,
  _updateCallParticipants,
  _callDocUpdate,
  _callActiveGroup,
  _addCallParticipants,
  // _callActiveGroup,
} from "../../SocketIo";
import store from "../../store";
import {
  removeScheduledCallForHost,
  setCallDetail,
  setCallRoom,
  setCallStartUserDetail,
  setCanvasSize,
  setCurrentPage,
  setInBoundCallPopUpShow,
  setInBoundCallPopUpVisibility,
  setMinimizePopUpShow,
  setMonitorUser,
  setMuteAllLoader,
  setNumPages,
  setOutBoundCallPopUpShow,
  setOutBoundCallPopUpVisibility,
  setRecordingConsentUserIds,
  setScreenSpyStatus,
  setUseronCall,
} from "../redux/callSlice";

import {
  SCHEDULE_CALL_TITLE,
  getAuthSession,
  notifyDesktopApp,
  privateChatID,
} from "./common";
import { alertMsg } from "../util/alertMsg";
import { toast } from "react-toastify";
import PushNotification from "../util/PushNotification";
import swal from "sweetalert";
import { globalStrings } from "../util/translation/languages";
import { HangupScreenSpyCall } from "./Lib/screenspy";
import { RECORDING_ENABLE } from "../../constants";
import {
  setCurrentActiveVideoTracks,
  setGalleryViewParticipants,
  setisActiveMic,
  setLocalJid,
} from "./Lib/libSlice";
import {
  changeTrackResolution,
  getParticipiantsDesktopTracks,
  getParticipiantsVideoTracks,
  localAudioTrackMute,
} from "./Lib";
export const hangupOutBoundCall = async (
  callDetailsData,
  type,
  allAttendeeList,
  user_unique_key,
  groupList,
) => {
  if (callDetailsData) {
    const { senderID, participantID } = callDetailsData;
    const callDetails = { ...callDetailsData };
    if (callDetails?.type !== "group") {
      if (callDetails?.user_added === true) {
        AnotherUserAddedPrivateCallHangup(
          callDetails,
          user_unique_key,
          allAttendeeList,
          type,
        );
      } else {
        callDetails["calling_status"] = type;
        callDetails["recordingTimestamp"] = null;
        callDetails["hand_raise"] = false;
        _updateCall(callDetails);
        let senderUser = allAttendeeList.find(
          (obj) => obj?.user_id === senderID,
        );
        const senderUserData = { ...senderUser };
        senderUserData["onCall"] = false;
        senderUserData["roomId"] = "";

        _userUpdate(senderUserData);
        if (type === "ended") {
          clearWhiteBoardData();
        }
      }
    }

    if (callDetails?.type === "group") {
      let participant = [...participantID];
      let index = participantID.findIndex((v) => v.id === user_unique_key);
      const particularObject = { ...participant[index] };
      particularObject.status = type;
      particularObject.hand_raise = false;
      particularObject.audio = false;
      participant[index] = particularObject;

      const JoinedParticipant = participant.filter(
        (item) => item?.status === "accepted",
      );
      if (JoinedParticipant.length === 0) {
        callDetails["calling_status"] = "ended";
        callDetails["recordingTimestamp"] = null;
        callDetails["entireScreenSharedIds"] = [];
        const GroupData = groupList.find(
          (obj) => obj.groupId === callDetails?.receiverID,
        );
        const UpdatedGroupData = { ...GroupData };
        UpdatedGroupData["groupCall"] = false;
        _groupUpdate(UpdatedGroupData);
        clearWhiteBoardData();
        callDetails["participantID"] = participant;
        _updateCall(callDetails);
      } else {
        const callParticipantsPayload = {
          company_id: callDetails?.company_id,
          id: callDetails?.id,
          participantID: particularObject,
          receiverID: callDetails?.receiverID,
        };
        _updateCallParticipants(callParticipantsPayload);
      }

      let User = allAttendeeList.find(
        (obj) => obj?.user_id === user_unique_key,
      );
      const UpdatedUser = { ...User };
      UpdatedUser["onCall"] = false;
      UpdatedUser["roomId"] = "";
      _userUpdate(UpdatedUser);
    }
  }
};

export const hangupInboundCall = async (type) => {
  const state = store.getState();

  const { callDetail, allAttendeeList, incoming_tone } = state.call;
  const { groupList } = state.chat;
  const { user_unique_key } = getAuthSession();
  incoming_tone.pause();
  if (callDetail) {
    const { receiverID, senderID, participantID } = callDetail;
    const callDetails = { ...callDetail };
    if (callDetails?.type === "private") {
      if (callDetails?.user_added === true) {
        AnotherUserAddedPrivateCallHangup(
          callDetails,
          user_unique_key,
          allAttendeeList,
          type,
        );
      } else {
        callDetails["calling_status"] = type;
        callDetails["hand_raise"] = false;
        _updateCall(callDetails);
        let senderUser = allAttendeeList.find(
          (obj) => obj?.user_id === user_unique_key,
        );
        const UpdatedSenderUser = { ...senderUser };
        UpdatedSenderUser["onCall"] = false;
        UpdatedSenderUser["roomId"] = "";

        _userUpdate(UpdatedSenderUser);
        if (type === "ended") {
          clearWhiteBoardData();
        }
      }
    } else if (callDetails?.type === "group") {
      let index = participantID.findIndex((v) => v.id === user_unique_key);
      let participant = [...participantID];
      const particularObject = { ...participant[index] };
      particularObject.status = type;
      particularObject.hand_raise = false;
      particularObject.audio = false;
      particularObject.screenShare = false;

      participant[index] = particularObject;
      const JoinedParticipant = participant.filter(
        (participantItem) => participantItem.status === "accepted",
      );
      if (JoinedParticipant.length == 0) {
        callDetails["calling_status"] = "ended";
        callDetails["recordingTimestamp"] = null;
        callDetails["entireScreenSharedIds"] = [];
        const GroupData = groupList.find((obj) => obj.groupId === receiverID);
        const UpdatedGroupData = { ...GroupData };
        UpdatedGroupData["groupCall"] = false;
        _groupUpdate(UpdatedGroupData);
        clearWhiteBoardData();
        _updateCall(callDetails);
      } else {
        const callParticipantsPayload = {
          company_id: callDetail?.company_id,
          id: callDetail?.id,
          participantID: particularObject,
          receiverID: callDetail?.receiverID,
        };
        _updateCallParticipants(callParticipantsPayload);
      }

      let User = allAttendeeList.find(
        (obj) => obj?.user_id === user_unique_key,
      );
      const UpdatedUserData = { ...User };
      UpdatedUserData["onCall"] = false;
      UpdatedUserData["roomId"] = "";
      _userUpdate(UpdatedUserData);
    } else if (callDetails?.type === "schedule") {
      let participant = [...participantID];
      if (user_unique_key === senderID) {
        participantID.forEach((item, index) => {
          if (item?.status === "initiated" || item?.status === "accepted") {
            participant[index] = {
              status: "ended",
              id: item.id,
              hand_raise: false,
              audio: false,
            };
            let User = allAttendeeList.find((obj) => obj?.user_id === item.id);
            const UpdatedUser = { ...User };
            UpdatedUser["onCall"] = false;
            UpdatedUser["roomId"] = "";
            _userUpdate(UpdatedUser);
          }
        });
        callDetails["calling_status"] = "ended";
        callDetails["entireScreenSharedIds"] = [];
        const GroupData = groupList.find((obj) => obj.groupId === receiverID);
        const UpdatedGroupData = { ...GroupData };
        UpdatedGroupData["groupCall"] = false;
        _groupUpdate(UpdatedGroupData);
        clearWhiteBoardData();
        callDetails["participantID"] = participant;
        _updateCall(callDetails);
      } else {
        let index = participantID.findIndex((v) => v.id === user_unique_key);
        const particularObject = { ...participant[index] };
        particularObject.status = type;
        particularObject.hand_raise = false;
        particularObject.audio = false;
        particularObject.screenShare = false;
        const callParticipantsPayload = {
          company_id: callDetail?.company_id,
          id: callDetail?.id,
          participantID: particularObject,
          receiverID: callDetail?.receiverID,
        };

        _updateCallParticipants(callParticipantsPayload);
      }

      let User = allAttendeeList.find(
        (obj) => obj?.user_id === user_unique_key,
      );
      const UpdatedUser = { ...User };
      UpdatedUser["onCall"] = false;
      UpdatedUser["roomId"] = "";
      _userUpdate(UpdatedUser);
    }
  }
};

const AnotherUserAddedPrivateCallHangup = (
  callDetailsData,
  user_unique_key,
  allAttendeeList,
  type,
) => {
  const { participantID } = callDetailsData;
  const callDetails = { ...callDetailsData };

  let participant = [...participantID];
  if (user_unique_key === callDetailsData.senderID) {
    participantID.forEach((item, index) => {
      if (item?.status === "initiated" || item?.status === "accepted") {
        participant[index] = {
          status: "ended",
          id: item.id,
          hand_raise: false,
          audio: false,
          jid: null,
          screenShare: false,
        };
      }
    });
    let User = allAttendeeList.find((obj) => obj?.user_id === user_unique_key);
    const UpdatedUser = { ...User };
    UpdatedUser["onCall"] = false;
    UpdatedUser["roomId"] = "";
    _userUpdate(UpdatedUser);
    callDetails["calling_status"] = "ended";
    callDetails["recordingTimestamp"] = null;
    callDetails["entireScreenSharedIds"] = [];
    clearWhiteBoardData();
    callDetails["participantID"] = participant;
    _updateCall(callDetails);
  } else {
    let index = participantID.findIndex((v) => v.id === user_unique_key);
    const particularObject = { ...participant[index] };
    particularObject.status = type;
    particularObject.hand_raise = false;
    particularObject.audio = false;
    particularObject.jid = null;
    particularObject.screenShare = false;
    // participant[index] = particularObject;
    const callParticipantsPayload = {
      company_id: callDetails?.company_id,
      id: callDetails?.id,
      participantID: particularObject,
      receiverID: callDetails?.receiverID,
    };

    _updateCallParticipants(callParticipantsPayload);

    const JoinedParticipant = participant.filter(
      (participantItem) => participantItem.status === "accepted",
    );
    if (JoinedParticipant.length == 0) {
      callDetails["calling_status"] = "ended";
      callDetails["recordingTimestamp"] = null;
      callDetails["entireScreenSharedIds"] = [];
      _callDocUpdate(callDetails);
    }
    let User = allAttendeeList.find((obj) => obj?.user_id === user_unique_key);
    const UpdatedUserData = { ...User };
    UpdatedUserData["onCall"] = false;
    UpdatedUserData["roomId"] = "";
    _userUpdate(UpdatedUserData);
    clearWhiteBoardData();
  }
};

export const hangupOutBoundMonitorCall = (callDetailsData, allAttendeeList) => {
  const { participantID, senderID } = callDetailsData;
  const callDetails = { ...callDetailsData };
  let participant = [...participantID];
  participantID.forEach((element, index) => {
    const particularObject = { ...participant[index] };
    particularObject.status = "ended";
    participant[index] = particularObject;
    // let participantUser = allAttendeeList.find(
    //   (obj) => obj.user_id === element.id,
    // );
    // const participantUserData = { ...participantUser };
    // participantUserData["monitor_status"] = false;
    // participantUserData["roomId"] = "";
    // _userUpdate(participantUserData);
  });
  callDetails["participantID"] = participant;
  callDetails["calling_status"] = "ended";
  callDetails["monitor_status"] = false;
  _updateCall(callDetails);
  let senderUser = allAttendeeList.find((obj) => obj.user_id === senderID);
  const senderUserData = { ...senderUser };
  senderUserData["monitor_status"] = false;
  senderUserData["roomId"] = "";

  _userUpdate(senderUserData);
};

export const RemoveUserFromCall = (user_id, callDetailsData) => {
  const { participantID } = callDetailsData;
  const callDetails = { ...callDetailsData };
  let index = participantID.findIndex((v) => v.id === user_id);
  let participant = [...participantID];
  const particularObject = { ...participant[index] };
  particularObject.status = "ended";
  particularObject.hand_raise = false;
  particularObject.audio = false;
  particularObject.jid = null;
  participant[index] = particularObject;
  callDetails["participantID"] = participant;
  _updateCall(callDetails);
};

export const DirectOutBound = async (data) => {
  const { dispatch } = store;
  const state = store.getState();
  const { allAttendeeList, callDetail } = state.call;
  const { user_unique_key: senderIDD } = getAuthSession();
  try {
    const { onCall, monitor_status } = data;
    let userLoggedInMonitorStatus =
      allAttendeeList.find((v) => v.user_id === senderIDD)?.monitor_status ??
      false;
    if (
      monitor_status === undefined ||
      (monitor_status === false && userLoggedInMonitorStatus === undefined) ||
      userLoggedInMonitorStatus === false
    ) {
      if (onCall === undefined || onCall === false) {
        callSetupHandler(data);
      } else {
        dispatch(setUseronCall(data?.user_id ?? ""));
      }
    } else {
      dispatch(setCallStartUserDetail(data));
      await hangupOutBoundMonitorCall(callDetail, allAttendeeList);
    }
  } catch (err) {
    console.log(err);
  }
};

export const callSetupHandler = (data) => {
  const { user_unique_key: senderIDD } = getAuthSession();
  setCallStartUserDetail({});
  const { dispatch } = store;
  const { company_id } = data;
  let room = privateChatID([senderIDD, data?.user_id]);
  dispatch(setCallRoom(room));
  StartCall(company_id, room, data);
  const ObjPayload = {
    senderID: senderIDD,
    event_type: "call-notification", // typing-start / typing-end / call-notification / msg-notification
    receiverID: data?.user_id, // receiverID or groupID
    type: "private", // private or group
    message: "call",
    company_id: company_id,
    timestamp: Date.now(),
    documentID: room,
  };
  _sendEvent(ObjPayload);
};

export const StartCall = async (company_id, room, data) => {
  const { user_unique_key: senderIDD } = getAuthSession();
  const state = store.getState();
  const { allAttendeeList } = state.call;
  let host = senderIDD;

  let hostUserData = allAttendeeList?.find((obj) => obj.user_id === host);
  const UpdatedHostUserData = { ...hostUserData };

  UpdatedHostUserData["roomId"] = room;
  UpdatedHostUserData["onCall"] = true;

  const participantID = [
    {
      id: host,
      status: "accepted",
      hand_raise: false,
      audio: false,
      screenShare: false,
    },
    {
      id: data?.user_id,
      status: "initiated",
      hand_raise: false,
      audio: false,
      screenShare: false,
    },
  ];

  _userUpdate(UpdatedHostUserData);
  const callPayload = {
    calling_status: "initiated",
    senderID: host,
    timestamp: Date.now(),
    receiverID: data?.user_id,
    company_id: company_id,
    monitor_status: false,
    documentID: room, // room
    type: "private", // "private" : "group
    participantID: participantID,
    user_added: false,
    recordingTimestamp: null,
    hand_raise: false,
    scheduleId: data?.scheduleId ?? undefined,
    sharedFile: {},
    entireScreenSharedIds: [],
  };
  await _updateCall(callPayload);
  whiteBoardElemets({ documentID: room, company_id: company_id });
};

export const callDataChangeActions = () => {
  const { user_unique_key: senderID } = getAuthSession();
  const state = store.getState();
  const { callDetail, incoming_tone, allAttendeeList } = state.call;
  const { dispatch } = store;
  if (callDetail && Object.keys(callDetail).length > 0) {
    const {
      senderID: callSenderID,
      participantID,
      type,
      calling_status,
      receiverID,
      documentID,
      user_added,
    } = callDetail;

    // for group
    if (
      type === "group" &&
      calling_status &&
      calling_status !== "decline" &&
      calling_status !== "ended"
    ) {
      const particularID = participantID?.find((v) => v.id === senderID);
      if (particularID?.status === "accepted") {
        if (callSenderID === senderID) {
          dispatch(setInBoundCallPopUpShow(false));
          dispatch(setOutBoundCallPopUpShow(true));
        } else {
          dispatch(setOutBoundCallPopUpShow(false));
          dispatch(setInBoundCallPopUpShow(true));
        }
      }

      if (callSenderID !== senderID && particularID?.status === "initiated") {
        if (
          incoming_tone &&
          !incoming_tone?.playing() &&
          particularID?.jid === undefined
        ) {
          incoming_tone?.play();
        }
        dispatch(setCallRoom(receiverID));
        dispatch(setOutBoundCallPopUpShow(false));
        dispatch(setInBoundCallPopUpShow(true));
      }
    }

    // for one-to-one
    if (type === "private") {
      if (
        receiverID === senderID &&
        calling_status === "initiated" &&
        (callDetail?.monitor_status === undefined ||
          callDetail?.monitor_status === false)
      ) {
        const particularID = participantID?.find((v) => v.id === callSenderID);

        if (
          incoming_tone &&
          particularID?.jid === undefined &&
          !incoming_tone?.playing()
        ) {
          incoming_tone?.play();
        }
        dispatch(setCallRoom(documentID));
        dispatch(setOutBoundCallPopUpShow(false));
        dispatch(setInBoundCallPopUpShow(true));
      }

      if (
        callDetail?.monitor_status === true &&
        calling_status === "initiated"
      ) {
        const particularID = participantID?.find((v) => v.id === senderID);
        if (
          particularID?.id !== receiverID &&
          particularID?.id !== callSenderID &&
          particularID?.status === "accepted"
        ) {
          dispatch(setCallRoom(documentID));
          dispatch(setOutBoundCallPopUpShow(false));
          dispatch(setInBoundCallPopUpShow(true));
        }
      }
      if (senderID === callSenderID && calling_status === "initiated") {
        dispatch(setInBoundCallPopUpShow(false));
        dispatch(setOutBoundCallPopUpShow(true));
      }
      if (
        calling_status === "ended" &&
        (callDetail?.monitor_status === false ||
          callDetail?.monitor_status === undefined)
      ) {
        if (receiverID === senderID || callSenderID === senderID) {
          let loggedInUser = allAttendeeList.find(
            (obj) => obj.user_id === senderID,
          );
          if (loggedInUser?.onCall) {
            const loggedInUserData = { ...loggedInUser };
            loggedInUserData["onCall"] = false;
            loggedInUserData["roomId"] = "";
            _userUpdate(loggedInUserData);
          }
        }

        dispatch(setInBoundCallPopUpShow(false));
        dispatch(setLocalJid(null));
        dispatch(setOutBoundCallPopUpShow(false));
      }
      if (user_added === true) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        const callUserDetail = callDetail?.participantID?.find(
          (v) => v?.id === senderID,
        );
        if (callUserDetail?.status === "initiated") {
          if (
            incoming_tone &&
            !incoming_tone?.playing() &&
            callUserDetail?.jid === undefined
          ) {
            incoming_tone?.play();
          }
          dispatch(setCallRoom(documentID));
          dispatch(setOutBoundCallPopUpShow(false));
          dispatch(setInBoundCallPopUpShow(true));
        }
      }
    }
  }
};

export const CallMonitorStatusChangeAction = () => {
  const state = store.getState();
  const { callDetail, allAttendeeList } = state.call;
  const { dispatch } = store;
  const { user_unique_key: senderID } = getAuthSession();
  if (callDetail.monitor_status === true) {
    if (senderID == callDetail.senderID) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const callUserDetail = callDetail?.participantID?.find(
        (v) => v?.id === senderID,
      );
      if (callUserDetail?.status === "accepted") {
        dispatch(setCallRoom(callDetail?.documentID));
        dispatch(setInBoundCallPopUpShow(false));
        dispatch(setOutBoundCallPopUpShow(true));
      }
    } else {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const tmpCallUserDetail = callDetail?.participantID?.find(
        (v) => v?.id === senderID,
      );
      if (
        tmpCallUserDetail?.status === "accepted" &&
        tmpCallUserDetail?.id === callDetail?.receiverID
      ) {
        dispatch(setCallRoom(callDetail?.documentID));
        dispatch(setScreenSpyStatus(true));
        let loggedInUser = allAttendeeList.find(
          (obj) => obj.user_id === senderID,
        );

        const loggedInUserData = { ...loggedInUser };
        loggedInUserData["monitor_status"] = true;
        loggedInUserData["roomId"] = callDetail?.documentID;
        _userUpdate(loggedInUserData);
      }

      if (tmpCallUserDetail?.status === "ended") {
        HangupScreenSpyCall();
        let loggedInUser = allAttendeeList.find(
          (obj) => obj.user_id === senderID,
        );
        if (loggedInUser?.monitor_status) {
          const loggedInUserData = { ...loggedInUser };
          loggedInUserData["monitor_status"] = false;
          loggedInUserData["roomId"] = "";
          _userUpdate(loggedInUserData);
        }
      }
    }
  }
};

export const GroupOutBoundCall = async (
  groupData,
  isInstantCall = false,
  setCallIconLoader,
) => {
  const { dispatch } = store;
  const { user_unique_key: senderID, company_unique_key: company_id } =
    getAuthSession();
  if (setCallIconLoader) {
    setCallIconLoader(groupData?.groupId);
  }

  const groupCallDoc = !isInstantCall
    ? await _callActiveGroup({
        user_id: senderID,
        company_id: company_id,
        groupID: groupData?.groupId,
      })
    : {};
  let isGroupcallAlreadyActive = true;
  if (isInstantCall) {
    isGroupcallAlreadyActive = false;
  } else {
    if (
      groupData.groupCall === undefined ||
      groupData.groupCall === false ||
      Object.keys(groupCallDoc).length === 0
    ) {
      isGroupcallAlreadyActive = false;
    }
  }

  const selectedGroup = groupData;
  if (!isGroupcallAlreadyActive) {
    const isUserCanInitiateCall = isInstantCall
      ? true
      : selectedGroup?.hostId.includes(senderID)
      ? true
      : false;
    if (isUserCanInitiateCall) {
      dispatch(
        setCallRoom(isInstantCall ? groupData?.scheduleId : groupData?.groupId),
      );
      let receiverId = {};
      groupData?.attendeeId
        ?.filter((filterItem) => filterItem != senderID)
        .map((attendeeItem) => {
          receiverId[`${attendeeItem}`] = { notify: true };
        });
      let partparticipantID = [];
      groupData?.attendeeId?.map((v) => {
        if (v !== senderID) {
          partparticipantID.push({
            id: v,
          });
        }
      });
      const ObjPayload = {
        senderID: senderID,
        event_type: "call-notification", // typing-start / typing-end / call-notification / msg-notification
        receiverID: isInstantCall ? groupData?.scheduleId : groupData?.groupId, // receiverID or groupID
        type: isInstantCall ? "schedule" : "group", // private or group
        message: "call",
        company_id: company_id,
        timestamp: Date.now(),
        documentID: isInstantCall ? groupData?.scheduleId : groupData?.groupId,
        participantID: isInstantCall ? partparticipantID : undefined,
        scheduleName: isInstantCall ? groupData?.scheduleName : undefined,
      };

      _sendEvent(ObjPayload);
      StartGroupCall(
        company_id,
        isInstantCall ? groupData?.scheduleId : groupData?.groupId,
        senderID,
        groupData,
        "group",
        isInstantCall,
      );
      selectedGroup["groupCall"] = true;
      _groupUpdate(selectedGroup);
    } else {
      swal({
        text: "There is currently no active call in this group.",
        dangerMode: true,
        buttons: {
          cancel: false,
          Ok: true,
        },
        closeOnClickOutside: false,
      });
    }
  } else {
    const existingGroupCallPayload = {
      company_id: company_id,
      documentID: groupData?.groupId,
      user_id: senderID,
    };
    const ExistingGroupCall = await _sendRoom(existingGroupCallPayload);
    if (Object.keys(ExistingGroupCall).length > 0) {
      JoinGroupCall(ExistingGroupCall);
    }
  }
  if (setCallIconLoader) {
    setCallIconLoader("");
  }
};

export const StartGroupCall = async (
  companyID,
  room,
  senderID,
  group,
  callType,
  isInstantCall,
) => {
  let host = senderID;
  let participant = [];
  const state = store.getState();
  const { allAttendeeList } = state.call;
  group?.attendeeId?.map((v) => {
    let particularAttendeeData = allAttendeeList?.find(
      (filterItem) => filterItem.user_id === v,
    );
    const onCallCheck = particularAttendeeData?.onCall ?? false;
    const UpdatedParticularAttendeeData = { ...particularAttendeeData };
    if (!onCallCheck) {
      if (v === host) {
        participant.push({
          id: v,
          status: callType === "group" ? "accepted" : "initiated",
          hand_raise: false,
          audio: false,
          screenShare: false,
        });
        UpdatedParticularAttendeeData["onCall"] = true;
        UpdatedParticularAttendeeData["roomId"] = room;
        _userUpdate(UpdatedParticularAttendeeData);
      } else {
        participant.push({
          id: v,
          status: "initiated",
          hand_raise: false,
          audio: false,
          screenShare: false,
        });
      }
    } else {
      participant.push({
        id: v,
        status: "ended",
        hand_raise: false,
        audio: false,
        screenShare: false,
      });
    }
  });

  let participantID = [...participant];
  let callPayload = {
    calling_status: "initiated",
    senderID: isInstantCall ? group?.adminId : host,
    timestamp: Date.now(),
    receiverID: room,
    company_id: companyID,
    documentID: room, // room
    type: callType, // "private" , "group", schedule
    participantID,
    groupName: isInstantCall ? group?.scheduleName : group?.groupName,
    monitor_status: false,
    user_added: false,
    recordingTimestamp: null,
    scheduleId: group?.scheduleId ?? undefined,
    sharedFile: {},
    entireScreenSharedIds: [],
  };

  console.log("callPayload", callPayload);
  _updateCall(callPayload);
  whiteBoardElemets({ documentID: room, company_id: companyID });
};

export const JoinGroupCall = (ExistingGroupCall) => {
  const ExistingCallDetail = ExistingGroupCall;
  const { user_unique_key: senderID } = getAuthSession();
  const { dispatch } = store;
  const state = store.getState();
  const { allAttendeeList } = state.call;
  if (ExistingGroupCall) {
    const { participantID, documentID } = ExistingGroupCall;
    dispatch(setCallRoom(documentID));
    const participant = [...participantID];
    let index = participantID.findIndex((v) => v.id === senderID);
    if (index !== -1) {
      let particularParticipant = { ...participant[index] };
      particularParticipant.status = "accepted";
      particularParticipant.audio = false;
      particularParticipant.screenShare = false;
      participant[index] = particularParticipant;
      ExistingCallDetail["participantID"] = participant;
      _updateCall(ExistingCallDetail);
    } else {
      const tmpParticipant = [];
      tmpParticipant.push({
        id: senderID,
        status: "accepted",
        hand_raise: false,
        audio: false,
        screenShare: false,
      });
      const callAddedPayload = {
        company_id: ExistingGroupCall?.company_id,
        id: ExistingGroupCall?.id,
        participantID: tmpParticipant,
        receiverID: ExistingGroupCall?.receiverID,
      };
      _addCallParticipants(callAddedPayload);
    }
    let particularAttendeeData = allAttendeeList?.find(
      (filterItem) => filterItem.user_id === senderID,
    );
    const updatedParticularAttendeeData = { ...particularAttendeeData };
    updatedParticularAttendeeData["onCall"] = true;
    updatedParticularAttendeeData["roomId"] = documentID;
    _userUpdate(updatedParticularAttendeeData);
  }
  _getRoomDetail(true);
};

export const recordingConsentCloseHandler = () => {
  const state = store.getState();
  const { recordingConsentUserIds } = state.call;
  const { user_unique_key } = getAuthSession();
  const { dispatch } = store;
  const updatedRecordingConsentUserIds = [...recordingConsentUserIds];
  const index = recordingConsentUserIds.findIndex(
    (item) => item === user_unique_key,
  );
  updatedRecordingConsentUserIds.splice(index, 1);
  dispatch(setRecordingConsentUserIds(updatedRecordingConsentUserIds));
};

export const RecordingContent = () => {
  const state = store.getState();
  const { recordingTime, isActiveRecording } = state.lib;
  const { callDetail } = state.call;
  const { user_unique_key } = getAuthSession();
  return (
    <>
      {isActiveRecording && (
        <h5 className="record-duration  position-absolute text-white">
          {user_unique_key === callDetail?.senderID
            ? new Date(recordingTime * 1000).toISOString()?.slice(11, 19)
            : alertMsg.recordingMsg}
        </h5>
      )}
    </>
  );
};

export const _canvas_credentials = () => {
  const { dispatch } = store;
  const _element = document.getElementById("outerStyleId");
  const height = _element?.clientHeight ?? 0;
  const width = _element?.clientWidth ?? 0;
  dispatch(setCanvasSize({ height, width }));
};

export const raiseHandNotify = (data) => {
  const state = store.getState();
  const { authSessionUser } = state.authUserDetail;
  const { roomId } = authSessionUser;
  const { documentID, name } = data;
  if (roomId === documentID) {
    toast(`${name} has raised a hand`, {
      position: toast.POSITION.BOTTOM_RIGHT,
      theme: "dark",
      autoClose: 3000,
    });
  }
};
const AVATAR_COLORS = [
  "232, 105, 156",
  "255, 198, 115",
  "69, 69, 119",
  "92, 167, 145",
  "234, 255, 128",
];
const AVATAR_OPACITY = 0.4;

export function getAvatarColor(initials) {
  let colorIndex = 0;

  if (initials) {
    let nameHash = 0;

    for (const s of initials) {
      nameHash += s.codePointAt(0);
    }

    colorIndex = nameHash % AVATAR_COLORS.length;
  }

  return `rgba(${AVATAR_COLORS[colorIndex]}, ${AVATAR_OPACITY})`;
}

export function _getAvatarStyle(color) {
  return {
    backgroundColor: color || undefined,
  };
}

export const joinScheduleCall = async (data) => {
  const state = store.getState();
  const { authSessionUser } = state.authUserDetail;
  const { selectedLanguage } = state.chat;
  if (!authSessionUser?.onCall) {
    const { scheduleId } = data;
    const { user_unique_key: senderID, company_unique_key: company_id } =
      getAuthSession();
    const existingScheduleCallPayload = {
      company_id: company_id,
      documentID: scheduleId,
      user_id: senderID,
    };
    await _sendRoom(existingScheduleCallPayload);
    const ExistingScheduleCall = await _getRoomDetail(false);
    JoinGroupCall(ExistingScheduleCall);
  } else {
    swal({
      text: globalStrings[selectedLanguage]?.$cannotPlaceCall,
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: false,
      },
      closeOnClickOutside: false,
    });
  }
};

export const scheduledCallReminder = (data) => {
  const { scheduleName } = data;
  const ScheduleBody = `You have scheduled a call with the name "${scheduleName}" in ${
    data?.reminder_minutes ?? 5
  } minutes.`;
  PushNotification.showScheduledCallReminderNotification(
    ScheduleBody,
    "assets/images/call-schedule.svg",
  );
  notifyDesktopApp(
    SCHEDULE_CALL_TITLE,
    ScheduleBody,
    "",
    "schedule-call-reminder",
    "assets/images/call-schedule.svg",
  );
};

export const startScheduledCall = (data) => {
  const { dispatch } = store;
  dispatch(removeScheduledCallForHost(data));
  const groupPayload = {
    groupId: data?.groupId,
    attendeeId: data?.attendeeId,
    adminId: data?.adminId,
    scheduleName: data?.scheduleName,
    scheduleId: data?.scheduleId,
    company_id: data?.company_id,
  };
  GroupOutBoundCall(groupPayload, true);
};

export const updateScreenshareTypeGlobally = (type, mode) => {
  const state = store.getState();
  const { callDetail } = state.call;
  const { authSessionUser } = state.authUserDetail;
  const tmpCallDetail = { ...callDetail };
  const tmpEntireScreenSharedIds =
    callDetail.entireScreenSharedIds !== undefined
      ? // eslint-disable-next-line no-unsafe-optional-chaining
        [...callDetail?.entireScreenSharedIds]
      : [];
  const index = tmpEntireScreenSharedIds.findIndex(
    (v) => v === authSessionUser?.user_id,
  );
  if (index > -1 && mode === "stop") {
    tmpEntireScreenSharedIds.splice(index, 1);
  } else {
    if (mode === "start" && type === "screen") {
      tmpEntireScreenSharedIds.push(authSessionUser?.user_id);
    }
  }
  tmpCallDetail["entireScreenSharedIds"] = tmpEntireScreenSharedIds;
  _callDocUpdate(tmpCallDetail);
};

export const showRecordingButton = (props) => {
  let flag = false;
  if (RECORDING_ENABLE && props?.callDetails?.participantID) {
    if (props?.callDetails?.type === "private") {
      if (props.user_unique_key === props?.callDetails?.senderID) {
        flag = true;
      }
    } else if (props?.callDetails?.type === "group") {
      const state = store.getState();
      const { groupList } = state.chat;
      const particularGroupData = groupList.find(
        (item) => item.groupId === props?.callDetails?.documentID,
      );
      if (particularGroupData && Object.keys(particularGroupData).length > 0) {
        const hostID = particularGroupData?.hostId ?? [];
        if (hostID.includes(props.user_unique_key)) {
          flag = true;
        }
      } else {
        if (props.user_unique_key === props?.callDetails?.senderID) {
          flag = true;
        }
      }
    }
  }

  return flag;
};

export const monitorStart = async (data) => {
  const state = store.getState();
  const { dispatch } = store;
  const { monitorUser, allAttendeeList } = state.call;
  if (monitorUser?.onCall === false) {
    const { user_id, company_id, monitor_status, roomId } = monitorUser;
    const { user_unique_key: senderIDD } = getAuthSession();
    let host = senderIDD;
    let hostUserData = allAttendeeList?.find((obj) => obj.user_id === host);
    if (monitor_status === false || monitor_status === undefined) {
      let room = privateChatID([senderIDD, user_id]);
      // let hostUserData = allAttendeeList?.find((obj) => obj.user_id === host);
      const updatedHostUserData = { ...hostUserData };
      updatedHostUserData["roomId"] = room;
      updatedHostUserData["monitor_status"] = true;
      _userUpdate(updatedHostUserData);
      const callPayload = {
        calling_status: "initiated",
        senderID: host,
        timestamp: Date.now(),
        receiverID: user_id,
        company_id: company_id,
        documentID: room, // room
        type: "private", // "private" : "group
        monitor_status: true,
        participantID: [
          { id: host, status: "accepted" },
          { id: user_id, status: "accepted" },
        ],
        sharedFile: {},
        entireScreenSharedIds: [],
        selectedMonitorScreen: data,
      };
      await _updateCall(callPayload);
      dispatch(setMonitorUser({}));
    } else {
      const existingGroupCallPayload = {
        company_id: company_id,
        documentID: roomId,
        user_id: host,
      };
      await _sendRoom(existingGroupCallPayload);
      const ExistingMonitorCall = await _getRoomDetail(false);
      JoinMonitor(ExistingMonitorCall);
    }
  }
};

const JoinMonitor = (ExistingMonitorCall) => {
  const state = store.getState();
  const { monitorUser, allAttendeeList } = state.call;
  const { dispatch } = store;
  const { user_unique_key: senderIDD } = getAuthSession();
  const ExistingCallDetail = { ...ExistingMonitorCall };
  if (
    ExistingCallDetail.participantID == undefined ||
    ExistingCallDetail.participantID.length === 0
  ) {
    let participant = [];
    participant.push(
      { id: ExistingCallDetail.senderID, status: "accepted" },
      { id: ExistingCallDetail.receiverID, status: "accepted" },
      { id: monitorUser?.user_id, status: "accepted" },
    );
    ExistingCallDetail["participantID"] = participant;
  } else {
    const { participantID } = ExistingCallDetail;
    const participant = [...participantID];
    const isUserAlreadyExistIndex = participant.findIndex(
      (filterItem) => filterItem.id === senderIDD,
    );
    if (isUserAlreadyExistIndex === -1) {
      participant.push({ id: senderIDD, status: "accepted" });
    } else {
      const prevParticipantItem = participant[isUserAlreadyExistIndex];
      participant[isUserAlreadyExistIndex] = {
        id: prevParticipantItem.id,
        status: "accepted",
      };
    }
    ExistingCallDetail["participantID"] = participant;
  }
  let particularAttendeeData = allAttendeeList?.find(
    (filterItem) => filterItem?.user_id === senderIDD,
  );
  dispatch(setCallRoom(ExistingMonitorCall?.documentID));
  const updatedParticularAttendeeData = { ...particularAttendeeData };
  updatedParticularAttendeeData["monitor_status"] = true;
  updatedParticularAttendeeData["roomId"] = ExistingMonitorCall.documentID;
  _userUpdate(updatedParticularAttendeeData);
  _updateCall(ExistingCallDetail);
  _getRoomDetail(true);
  dispatch(setMonitorUser({}));
};

export const updateCallParticipantID = (key, value) => {
  const { user_unique_key } = getAuthSession();
  const state = store.getState();
  const { callDetail } = state.call;

  const { participantID } = callDetail;

  let index = participantID.findIndex((v) => v.id === user_unique_key);
  const particularPartcipiant = { ...participantID[index] };
  particularPartcipiant[`${key}`] = value;

  const callParticipantsPayload = {
    company_id: callDetail?.company_id,
    id: callDetail?.id,
    participantID: particularPartcipiant,
    receiverID: callDetail?.receiverID,
  };

  _updateCallParticipants(callParticipantsPayload);
};

export const calculatePagination = () => {
  const { dispatch } = store;
  const state = store.getState();
  let participantsCount = 0;
  const { localDesktopVideoTrack, desktopVideoTrack } = state.lib;
  const { callDetail, maxVideos, currrentPage } = state.call;
  const { participantID } = callDetail;
  const localdesktopVideoTrack = getTrack(localDesktopVideoTrack);

  if (localdesktopVideoTrack && localdesktopVideoTrack !== null) {
    participantsCount += 1;
  }

  participantID.forEach((item) => {
    if (item.status === "accepted") {
      participantsCount += 1;

      const remoteDesktopTrack = desktopVideoTrack[item.jid];
      if (remoteDesktopTrack?.muted === false) {
        participantsCount += 1;
      }
    }
  });

  let numPages = Math.ceil(participantsCount / maxVideos);
  numPages = numPages < 1 ? 1 : numPages;

  // const totalNumpages = numPages + (participantsCount - 1);

  // const finalPage = Math.ceil(totalNumpages / maxVideos);
  dispatch(setNumPages(numPages));

  if (currrentPage > numPages) {
    dispatch(setCurrentPage(numPages));
  }
};

// export const calculatePagination = () => {
//   const { dispatch } = store;
//   const state = store.getState();
//   let participantsCount = 0;
//   const { localDesktopVideoTrack, desktopVideoTrack } = state.lib;
//   const { callDetail, maxVideos, currrentPage } = state.call;
//   // const { user_unique_key } = getAuthSession();
//   const { participantID } = callDetail;
//   const localdesktopVideoTrack = getTrack(localDesktopVideoTrack);

//   if (localdesktopVideoTrack && localdesktopVideoTrack !== null) {
//     participantsCount += 1;
//   }

//   participantID.forEach((item) => {
//     if (item.status === "accepted") {
//       participantsCount += 1;

//       const remoteDesktopTrack = desktopVideoTrack[item.jid];
//       if (remoteDesktopTrack?.muted === false) {
//         participantsCount += 1;
//       }
//     }
//   });
//   let numPages = Math.ceil(participantsCount / maxVideos);
//   numPages = numPages < 1 ? 1 : numPages;

//   if (numPages > 1) {
//     if (participantsCount % maxVideos === 0) {
//       participantsCount = participantsCount + (numPages - 1);
//       numPages = Math.ceil(participantsCount / maxVideos);
//     }
//   }

//   // const totalNumpages = numPages + (participantsCount - 1);

//   // const finalPage = Math.ceil(totalNumpages / maxVideos);

//   dispatch(setNumPages(numPages));

//   if (currrentPage > numPages) {
//     dispatch(setCurrentPage(numPages));
//   }
// };

export const getTrack = (track) => {
  return track !== null && track?.muted === false ? track : null;
};

export const requestCallParticipiantsVideos = () => {
  const state = store.getState();
  const { dispatch } = store;

  const { user_unique_key } = getAuthSession();

  const { numPages, maxVideos, currrentPage, callDetail, allAttendeeList } =
    state.call;
  const { authSessionUser } = state.authUserDetail;
  const { localDesktopVideoTrack, localJid, localVideoTrack } = state.lib;

  const { participantID } = callDetail;

  const desktopVideoTrack = getTrack(localDesktopVideoTrack);
  const videoTrack = getTrack(localVideoTrack);

  let localSelectedTracks = [];
  let galleryParticipantData = [];

  if (videoTrack && videoTrack?.muted === false) {
    const participant = {
      track: videoTrack.jitsiTrack,
      type: "camera",
      name: authSessionUser?.name,
      jid: localJid,
      local: true,
      user_id: authSessionUser?.user_id,
    };
    localSelectedTracks.push(participant);
  } else {
    const participant = {
      track: null,
      type: "camera",
      name: authSessionUser?.name,
      jid: localJid,
      local: true,
      user_id: authSessionUser?.user_id,
    };
    localSelectedTracks.push(participant);
  }

  if (desktopVideoTrack && desktopVideoTrack !== null) {
    const participant = {
      track: desktopVideoTrack.jitsiTrack,
      type: "desktop",
      name: authSessionUser.name + "'s Presentation",
      local: true,
      user_id: authSessionUser?.user_id,
    };
    localSelectedTracks.push(participant);
  }

  const pageCount = [];
  for (let i = 0; i < numPages; i++) {
    if (i === 0) {
      let start = 0;
      let end = maxVideos - localSelectedTracks.length;
      pageCount.push({ start: start, end: end });
    } else {
      let start = pageCount[i - 1].end;
      let end = pageCount[i - 1].end + maxVideos - 1;
      pageCount.push({ start: start, end: end });
    }
  }

  let startCount = pageCount[currrentPage - 1]?.start;
  let endCount = pageCount[currrentPage - 1]?.end;

  let currentActiveTrack = [];

  participantID
    .filter((filterItem) => filterItem.id !== user_unique_key)
    .forEach((item) => {
      if (item.status === "accepted") {
        const userVideoTrack = getParticipiantsVideoTracks(item.jid);
        const desktopTrack = getParticipiantsDesktopTracks(
          item.jid,
          "requestCallParticipiantsVideos",
        );
        const userData = allAttendeeList.find(
          (filterItem) => filterItem.user_id == item.id,
        );

        if (userVideoTrack?.muted === false) {
          const participant = {
            track: userVideoTrack.jitsiTrack,
            type: "camera",
            name: userData?.name,
            jid: item.jid,
            local: false,
            user_id: userData?.user_id,
          };
          galleryParticipantData.push(participant);
          currentActiveTrack.push(userVideoTrack);
        } else {
          const participant = {
            track: null,
            type: "camera",
            name: userData?.name,
            jid: item.jid,
            local: false,
            user_id: userData?.user_id,
          };
          galleryParticipantData.push(participant);
        }

        if (desktopTrack?.muted === false) {
          const participant = {
            track: desktopTrack.jitsiTrack,
            type: "desktop",
            name: userData?.name + "'s Presentation",
            jid: item.jid,
            local: false,
            user_id: userData?.user_id,
          };
          galleryParticipantData.push(participant);
          currentActiveTrack.push(desktopTrack);
        }
      }
    });

  const specificNumberGalleryParticipantData = galleryParticipantData.slice(
    startCount,
    endCount,
  );

  const finalLocalSelectedTracks =
    currrentPage === 1
      ? localSelectedTracks
      : localSelectedTracks && localSelectedTracks.length > 0
      ? [localSelectedTracks[0]]
      : [];

  const finalGalleryParticipantData = [
    ...finalLocalSelectedTracks,
    ...specificNumberGalleryParticipantData,
  ];

  let finalCurrentActiveTrack = [];
  let finalRequestIds = [];
  for (let user of specificNumberGalleryParticipantData) {
    const isUserIncurrentActiveTrack = currentActiveTrack.find(
      (item) => item.participantId === user.jid,
    );
    if (
      isUserIncurrentActiveTrack &&
      Object.keys(isUserIncurrentActiveTrack).length > 0
    ) {
      finalCurrentActiveTrack.push(isUserIncurrentActiveTrack);
      finalRequestIds.push(
        isUserIncurrentActiveTrack?.jitsiTrack?.getSourceName(),
      );
    }
  }

  changeTrackResolution(finalRequestIds);
  dispatch(setCurrentActiveVideoTracks(finalCurrentActiveTrack));
  dispatch(setGalleryViewParticipants(finalGalleryParticipantData));
};

export const requestPinParticipiantVideos = (pinUserId) => {
  const state = store.getState();
  const { dispatch } = store;

  const { callDetail, allAttendeeList } = state.call;
  const { authSessionUser } = state.authUserDetail;
  const { localDesktopVideoTrack, localJid, localVideoTrack } = state.lib;

  const { participantID } = callDetail;

  let pinnedUserDetail = {};

  if (pinUserId === "localVideo" || pinUserId === "localscreen") {
    pinnedUserDetail = participantID.find(
      (item) => item.id === authSessionUser?.user_id,
    );
  } else {
    pinnedUserDetail = participantID.find(
      (item) =>
        `${item?.jid}screen` === pinUserId || `${item?.jid}Video` === pinUserId,
    );
  }
  let galleryParticipantData = [];
  let requestIds = [];
  let currentActiveTrack = [];

  if (pinnedUserDetail?.id === authSessionUser?.user_id) {
    if (pinUserId.includes("Video")) {
      const videoTrack = getTrack(localVideoTrack);
      if (videoTrack && videoTrack?.muted === false) {
        const participant = {
          track: videoTrack.jitsiTrack,
          type: "camera",
          name: authSessionUser?.name,
          jid: localJid,
          local: true,
          user_id: authSessionUser?.user_id,
        };
        galleryParticipantData.push(participant);
        currentActiveTrack.push(videoTrack);
      } else {
        const participant = {
          track: null,
          type: "camera",
          name: authSessionUser?.name,
          jid: localJid,
          local: true,
          user_id: authSessionUser?.user_id,
        };
        galleryParticipantData.push(participant);
      }
    } else {
      const desktopVideoTrack = getTrack(localDesktopVideoTrack);

      if (desktopVideoTrack && desktopVideoTrack !== null) {
        const participant = {
          track: desktopVideoTrack.jitsiTrack,
          type: "desktop",
          name: authSessionUser.name + "'s Presentation",
          local: true,
          user_id: authSessionUser?.user_id,
        };
        galleryParticipantData.push(participant);
        currentActiveTrack.push(desktopVideoTrack);
      }
    }
  } else {
    const userData = allAttendeeList.find(
      (filterItem) => filterItem.user_id == pinnedUserDetail.id,
    );
    if (pinUserId.includes("Video")) {
      const userVideoTrack = getParticipiantsVideoTracks(pinnedUserDetail.jid);

      if (userVideoTrack?.muted === false) {
        const participant = {
          track: userVideoTrack.jitsiTrack,
          type: "camera",
          name: userData?.name,
          jid: pinnedUserDetail.jid,
          local: false,
          user_id: userData?.user_id,
        };
        galleryParticipantData.push(participant);
        currentActiveTrack.push(userVideoTrack);
        requestIds.push(userVideoTrack?.jitsiTrack?.getSourceName());
      } else {
        const participant = {
          track: null,
          type: "camera",
          name: userData?.name,
          jid: pinnedUserDetail.jid,
          local: false,
          user_id: userData?.user_id,
        };
        galleryParticipantData.push(participant);
      }
    } else {
      const desktopTrack = getParticipiantsDesktopTracks(pinnedUserDetail.jid);
      if (desktopTrack?.muted === false) {
        const participant = {
          track: desktopTrack.jitsiTrack,
          type: "desktop",
          name: userData?.name + "'s Presentation",
          jid: pinnedUserDetail.jid,
          local: false,
          user_id: userData?.user_id,
        };
        galleryParticipantData.push(participant);
        currentActiveTrack.push(desktopTrack);
        requestIds.push(desktopTrack?.jitsiTrack?.getSourceName());
      }
    }
  }

  changeTrackResolution(requestIds);
  dispatch(setCurrentActiveVideoTracks(currentActiveTrack));
  dispatch(setGalleryViewParticipants(galleryParticipantData));
};

export const setGalleryTracks = () => {
  const { dispatch } = store;
  const state = store.getState();
  const { galleryViewParticipants } = state.lib;
  let participantId = [];

  const { localDesktopVideoTrack, localVideoTrack } = state.lib;

  let newGalleryParticipants = [];

  let _participants = galleryViewParticipants.map((p) => {
    let participant = { ...p };
    if (!participant.local) {
      if (participant.type === "camera") {
        const remoteVideoTrack = getParticipiantsVideoTracks(
          participant.jid,
          "setGalleryTracks",
        );

        if (remoteVideoTrack?.muted === false) {
          participantId.push(remoteVideoTrack.jitsiTrack.getSourceName());
          participant.track = remoteVideoTrack.jitsiTrack;
        } else {
          participant.track = null;
        }
      }
      if (participant.type === "desktop") {
        const remoteDesktopTrack = getParticipiantsDesktopTracks(
          participant.jid,
          "requestCallParticipiantsVideos",
        );

        if (remoteDesktopTrack?.muted === false) {
          participantId.push(remoteDesktopTrack.jitsiTrack.getSourceName());
          participant.track = remoteDesktopTrack.jitsiTrack;
        } else {
          participant.track = null;
        }
      }
    } else {
      if (participant.type === "camera") {
        const videoTrack = getTrack(localVideoTrack);
        if (videoTrack && videoTrack?.muted === false) {
          participant.track = videoTrack.jitsiTrack;
        } else {
          participant.track = null;
        }
      }

      if (participant.type === "desktop") {
        const desktopVideoTrack = getTrack(localDesktopVideoTrack);

        if (desktopVideoTrack && desktopVideoTrack?.muted === false) {
          participant.track = desktopVideoTrack.jitsiTrack;
        } else {
          participant.track = null;
        }
      }
    }

    return participant;
  });

  changeTrackResolution(participantId);
  console.log(
    "GalleryViewParticipants setGalleryTracks neha==>",
    _participants,
    newGalleryParticipants,
  );
  dispatch(
    setGalleryViewParticipants([..._participants, ...newGalleryParticipants]),
  );
};

export const pinUserClass = (pinUserId, id, type) => {
  let classData = "displayBlock";
  const pinValue =
    type === "Video" ? "localvideo" : "localvideocontainer_screen";
  if (pinUserId !== null) {
    if (pinUserId !== pinValue && pinUserId !== `${id}${type}`) {
      classData = "displayNone";
    }
  }
  return classData;
};

export const getKeyId = (type, local, id) => {
  let keyId = id;
  if (type === "camera") {
    if (local === true) {
      keyId = "localvideo";
    }
  } else {
    if (local === true) {
      keyId = "localvideocontainer_screen";
    } else {
      keyId = id + "desktop";
    }
  }
  return keyId;
};

export const ShowActiveSpeakerOnFirstPage = () => {
  const state = store.getState();
  const { dispatch } = store;

  const { maxVideos, callDetail } = state.call;
  const { activeSpeaker } = state.lib;
  const { authSessionUser } = state.authUserDetail;

  const { participantID } = callDetail;
  const localUser =
    participantID?.find((item) => item?.id === authSessionUser?.user_id) ?? {};
  if (activeSpeaker !== null && activeSpeaker != localUser?.jid) {
    const galleryViewJid = participantID.slice(0, maxVideos - 1);
    const isDominatExist = galleryViewJid.findIndex(
      (item) => item?.jid === activeSpeaker,
    );
    if (isDominatExist == -1) {
      const tmpParticipantsID = [...participantID];
      const DominatIndex = tmpParticipantsID.findIndex(
        (item) => item?.jid === activeSpeaker,
      );
      const sortedList = getParticipiantsJidSortedData(
        participantID.slice(0, maxVideos - 1),
      );
      const leastUserSpeakIndex = tmpParticipantsID.findIndex(
        (item) => item?.jid === sortedList[sortedList.length - 1].jid,
      );
      if (DominatIndex !== -1) {
        const tmpData = tmpParticipantsID[leastUserSpeakIndex];
        tmpParticipantsID[leastUserSpeakIndex] =
          tmpParticipantsID[DominatIndex];
        tmpParticipantsID[DominatIndex] = tmpData;
        const updatedCallDetail = { ...callDetail };
        updatedCallDetail["participantID"] = tmpParticipantsID;
        dispatch(setCallDetail(updatedCallDetail));
      }
    }
  }
};

export const getParticipiantsJidSortedData = (data) => {
  let list = [...data];
  let speakUsers = list.filter((z) => z.last_speak_time !== undefined);
  const unSpeakUser = list.filter((b) => b.last_speak_time === undefined);
  if (speakUsers.length > 0) {
    const sortedSpeakUsers = speakUsers.sort(function (x, y) {
      return y.last_speak_time - x.last_speak_time;
    });
    list = [...sortedSpeakUsers, ...unSpeakUser];
  }
  return list;
};

export const updateLastSpeakerTime = (dominantSpeakerId) => {
  const state = store.getState();
  const { callDetail } = state.call;
  const { authSessionUser } = state.authUserDetail;
  const { participantID } = callDetail;
  const localUser =
    participantID?.find((item) => item?.id === authSessionUser?.user_id) ?? {};
  if (localUser?.jid === dominantSpeakerId) {
    let currenttimeUTC = new Date().toISOString();
    let timeStampUTC = Date.parse(currenttimeUTC);
    updateCallParticipantID("last_speak_time", timeStampUTC);
  }
};

export const muteMic = () => {
  const state = store.getState();
  const { callDetail } = state.call;
  const { authSessionUser } = state.authUserDetail;
  const { participantID } = callDetail;
  const localUser =
    participantID?.find((item) => item?.id === authSessionUser?.user_id) ?? {};
  localAudioTrackMute()
    .then(async (res) => {
      if (res.audio) {
        store.dispatch(setisActiveMic(false));
        updateCallParticipantID("audio", false);
      } else {
        if (localUser?.audio === true) {
          store.dispatch(setisActiveMic(false));
          updateCallParticipantID("audio", false);
        }
      }
    })
    .catch((error) => {
      console.log("localAudioTrackMute error", error);
    });
};

export const IndividualUserActionPerform = (type) => {
  const state = store.getState();
  const { callDetail } = state.call;
  const { authSessionUser } = state.authUserDetail;
  const { participantID } = callDetail;
  const localUser =
    participantID?.find((item) => item?.id === authSessionUser?.user_id) ?? {};

  switch (type) {
    case "mute":
      if (localUser?.audio) {
        muteMic();
      }
      break;
    default:
      break;
  }
};

export const handleMuteAll = () => {
  const { dispatch } = store;
  const state = store.getState();
  const { callDetail } = state.call;
  const { authSessionUser } = state.authUserDetail;
  dispatch(setMuteAllLoader(true));
  const ObjPayload = {
    senderID: authSessionUser?.user_id,
    event_type: "mute-all", // typing-start / typing-end / call-notification / msg-notification
    receiverID: callDetail?.receiverID, // receiverID or groupID
    type: callDetail?.type, // private or group
    company_id: callDetail?.company_id,
    timestamp: Date.now(),
    documentID: callDetail?.documentID,
  };
  _sendEvent(ObjPayload);
  dispatch(setMuteAllLoader(false));
};

export const CallButtons = (type) => {
  const state = store.getState();
  const { muteAllLoader } = state.call;
  const { selectedLanguage } = state.chat;

  return (
    <>
      <div className="d-flex w-50">
        <button
          type="submit"
          id="submit"
          className="btn btn-light btn btn-primary mr-2 btn-xs"
          onClick={() => handleMuteAll()}
        >
          <span className="flex-1">
            {muteAllLoader ? (
              <div className="spinner" />
            ) : (
              globalStrings[selectedLanguage]?.$muteAll
            )}
          </span>
        </button>
        <button
          type="submit"
          id="submit"
          className="btn btn-light btn btn-primary btn-xs"
          onClick={() => endMeetingForAll(type)}
        >
          <span className="flex-1">
            {globalStrings[selectedLanguage]?.$endMeetingForAll}
          </span>
        </button>
      </div>
    </>
  );
};

export const endMeetingForAll = (modalType) => {
  const state = store.getState();
  const { callDetail, allAttendeeList } = state.call;
  const { groupList } = state.chat;
  const { authSessionUser } = state.authUserDetail;
  const updatedCallDetail = { ...callDetail };
  const { participantID, receiverID } = callDetail;
  const participant = [...participantID];
  participantID.forEach((item, index) => {
    if (item?.status === "initiated" || item?.status === "accepted") {
      participant[index] = {
        status: "ended",
        id: item.id,
        hand_raise: false,
        audio: false,
      };
    }
  });
  updatedCallDetail["calling_status"] = "ended";
  updatedCallDetail["entireScreenSharedIds"] = [];
  updatedCallDetail["end_meeting_all"] = true;
  const GroupData = groupList.find((obj) => obj.groupId === receiverID);
  const UpdatedGroupData = { ...GroupData };
  UpdatedGroupData["groupCall"] = false;
  _groupUpdate(UpdatedGroupData);
  clearWhiteBoardData();
  updatedCallDetail["participantID"] = participant;
  _updateCall(updatedCallDetail);
  let User = allAttendeeList.find(
    (obj) => obj?.user_id === authSessionUser?.user_id,
  );
  const UpdatedUser = { ...User };
  UpdatedUser["onCall"] = false;
  UpdatedUser["roomId"] = "";
  _userUpdate(UpdatedUser);
  if (modalType === "inbound") {
    store.dispatch(setInBoundCallPopUpShow(false));
    store.dispatch(setLocalJid(null));
    store.dispatch(setInBoundCallPopUpVisibility(false));
    store.dispatch(setMinimizePopUpShow(false));
  } else {
    store.dispatch(setOutBoundCallPopUpShow(false));
    store.dispatch(setLocalJid(null));
    store.dispatch(setMinimizePopUpShow(false));
    store.dispatch(setOutBoundCallPopUpVisibility(false));
  }
};

export const showCallButton = (callDetail) => {
  let flag = false;
  const state = store.getState();
  const { groupList } = state.chat;
  const { user_unique_key } = getAuthSession();
  if (callDetail?.type === "group") {
    const particularGroupData = groupList.find(
      (item) => item.groupId === callDetail?.documentID,
    );
    if (particularGroupData && Object.keys(particularGroupData).length > 0) {
      const hostID = particularGroupData?.hostId ?? [];
      if (hostID.includes(user_unique_key)) {
        flag = true;
      }
    }
  }
  return flag;
};

export const isHostExistinMeeting = () => {
  let flag = true;
  const state = store.getState();
  const { groupList } = state.chat;
  const { callDetail } = state.call;

  if (callDetail?.type === "group") {
    const particularGroupData = groupList.find(
      (item) => item.groupId === callDetail?.documentID,
    );
    if (particularGroupData && Object.keys(particularGroupData).length > 0) {
      const hostID = particularGroupData?.hostId ?? [];
      const isHostExist = callDetail?.participantID.filter(
        (item) => hostID.includes(item.id) && item.status === "accepted",
      );
      if (isHostExist.length === 0) {
        flag = false;
      }
    }
  }
  return flag;
};

export const sendHangup = (event, props, allAttendeeList) => {
  event.preventDefault();
  if (props.callDetails.calling_status !== "ended") {
    if (props.callModalType == "outBound") {
      hangupOutBoundCall(
        props.callDetails,
        "ended",
        allAttendeeList,
        props.user_unique_key,
        props.groupList,
      );
    } else {
      hangupInboundCall("ended");
    }
  }
};

export const showIframe = (callDetail) => {
  const { user_unique_key } = getAuthSession();
  let flag = false;
  const localUser = callDetail?.participantID?.find(
    (item) => item.id === user_unique_key,
  );
  if (localUser?.status === "accepted") {
    flag = true;
  }
  return flag;
};

export const videoToolbar = () => {
  return (
    <div className="video__toolbar">
      <div
        className="tooltip position-relative toolbar__tag"
        data-tip
        data-for="handrise"
      >
        <span className="d-flex align-items-center">
          <svg
            fill="none"
            width="23px"
            height="23px"
            viewBox="5.65 2.5 46.85 55"
          >
            <path
              d="M35 8.75V27.5V11.25C35 9.17893 36.679 7.5 38.75 7.5C40.821 7.5 42.5 9.17893 42.5 11.25V27.5V18.75C42.5 16.6789 44.179 15 46.25 15C48.321 15 50 16.6789 50 18.75V40C50 48.2842 43.2842 55 35 55H32.1817C28.3707 55 24.7028 53.5495 21.9226 50.943L9.52612 39.3213C7.73412 37.6413 7.6885 34.8115 9.4254 33.0745C11.1235 31.3765 13.8766 31.3765 15.5746 33.0745L20 37.5V16.25C20 14.1789 21.6789 12.5 23.75 12.5C25.821 12.5 27.5 14.1789 27.5 16.25V27.5V8.75C27.5 6.67893 29.179 5 31.25 5C33.321 5 35 6.67893 35 8.75Z"
              stroke="#FFFFFF"
              strokeWidth="5"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          {/* <ReactTooltip place="top" effect="solid">
                    {globalStrings[selectedLanguage]?.$raiseHand}
                  </ReactTooltip> */}
        </span>
      </div>
      <div
        className="tooltip position-relative toolbar__tag"
        data-tip
        data-for="registerTipUnMute"
      >
        <span className="d-flex align-items-center icon-audio">
          <svg width="23px" height="23px" viewBox="4 1.98 16 20.02">
            <path
              fill="#ffffff"
              d="M16 12V6c0-2.217-1.785-4.021-3.979-4.021a.933.933 0 0 0-.209.025A4.006 4.006 0 0 0 8 6v6c0 2.206 1.794 4 4 4s4-1.794 4-4zm-6 0V6c0-1.103.897-2 2-2a.89.89 0 0 0 .163-.015C13.188 4.06 14 4.935 14 6v6c0 1.103-.897 2-2 2s-2-.897-2-2z"
            ></path>
            <path
              fill="#ffffff"
              d="M6 12H4c0 4.072 3.061 7.436 7 7.931V22h2v-2.069c3.939-.495 7-3.858 7-7.931h-2c0 3.309-2.691 6-6 6s-6-2.691-6-6z"
            ></path>
          </svg>
          {/* <ReactTooltip place="top" effect="solid">
                    {globalStrings[selectedLanguage]?.$mic}
                  </ReactTooltip> */}
        </span>
      </div>
      <div
        className="tooltip position-relative toolbar__tag"
        data-tip
        data-for="registerTipCamraOff"
      >
        <span className="d-flex align-items-center icon-video">
          <svg width="23px" height="23px" viewBox="2 2.29 20 19.41">
            <path
              fill="#ffffff"
              d="M18 7c0-1.103-.897-2-2-2H6.414L3.707 2.293 2.293 3.707l18 18 1.414-1.414L18 16.586v-2.919L22 17V7l-4 3.333V7zm-2 7.586L8.414 7H16v7.586zM4 19h10.879l-2-2H4V8.121L2.145 6.265A1.977 1.977 0 0 0 2 7v10c0 1.103.897 2 2 2z"
            ></path>
          </svg>
          {/* <ReactTooltip place="top" effect="solid">
                    {globalStrings[selectedLanguage]?.$mic}
                  </ReactTooltip> */}
        </span>
      </div>
      <div
        className="tooltip position-relative toolbar__tag"
        data-tip
        data-for="registerTip"
      >
        <span className="d-flex align-items-center icon-audio">
          <svg width="23px" height="23px" viewBox="0 0 24 24">
            <path
              fill="#ffffff"
              d="M16 21c3.527-1.547 5.999-4.909 5.999-9S19.527 4.547 16 3v2c2.387 1.386 3.999 4.047 3.999 7S18.387 17.614 16 19v2z"
            ></path>
            <path
              fill="#ffffff"
              d="M16 7v10c1.225-1.1 2-3.229 2-5s-.775-3.9-2-5zM4 17h2.697l5.748 3.832a1.004 1.004 0 0 0 1.027.05A1 1 0 0 0 14 20V4a1 1 0 0 0-1.554-.832L6.697 7H4c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2zm0-8h3c.033 0 .061-.016.093-.019a1.027 1.027 0 0 0 .38-.116c.026-.015.057-.017.082-.033L12 5.868v12.264l-4.445-2.964c-.025-.017-.056-.02-.082-.033a.986.986 0 0 0-.382-.116C7.059 15.016 7.032 15 7 15H4V9z"
            ></path>
          </svg>
          {/* <ReactTooltip place="top" effect="solid">
                    {globalStrings[selectedLanguage]?.$mic}
                  </ReactTooltip> */}
        </span>
      </div>
      <div
        className="tooltip position-relative toolbar__tag"
        data-tip
        data-for="sharedFile"
      >
        <span className="d-flex align-items-center icon-video">
          <svg width="23px" viewBox="2 4 20 16">
            <path fill="#ffffff" d="M11 15h2V9h3l-4-5-4 5h3z"></path>
            <path
              fill="#ffffff"
              d="M20 18H4v-7H2v7c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2v-7h-2v7z"
            ></path>
          </svg>
          {/* <ReactTooltip place="top" effect="solid">
                    {globalStrings[selectedLanguage]?.$mic}
                  </ReactTooltip> */}
        </span>
      </div>
      <div data-tip data-for="EndCall">
        <span className="toolbar__tag bg-red icon-end">
          <svg
            className="rotate-135"
            width="20px"
            height="20px"
            viewBox="3 3.19 17.81 17.81"
          >
            <path
              fill="#ffffff"
              d="m20.487 17.14-4.065-3.696a1.001 1.001 0 0 0-1.391.043l-2.393 2.461c-.576-.11-1.734-.471-2.926-1.66-1.192-1.193-1.553-2.354-1.66-2.926l2.459-2.394a1 1 0 0 0 .043-1.391L6.859 3.513a1 1 0 0 0-1.391-.087l-2.17 1.861a1 1 0 0 0-.29.649c-.015.25-.301 6.172 4.291 10.766C11.305 20.707 16.323 21 17.705 21c.202 0 .326-.006.359-.008a.992.992 0 0 0 .648-.291l1.86-2.171a.997.997 0 0 0-.085-1.39z"
            ></path>
          </svg>
          {/* <ReactTooltip place="top" effect="solid">
                    {globalStrings[selectedLanguage]?.$mic}
                  </ReactTooltip> */}
        </span>
      </div>
    </div>
  );
};
