import axios from "axios";
import store from "../../store";
import { setProgress } from "../redux/chatSlice";
import { apiClient } from "./common";
import { setSharedFileProgressBar } from "../redux/callSlice";

export const fetchWasabiImageUploadURL = async (downloadUrl, payload) => {
  try {
    const res = await apiClient("download", {
      method: "POST",
      data: payload,
    });
    return res;
  } catch (e) {
    return { res: {}, status: false };
  }
};

export const uploadWasabiFile = async (uploadFileUrl, payload) => {
  const data = await axios.post(uploadFileUrl, payload);
  return data;
};

export const uploadAttachmentFileWasabi = async (
  { _uploadUrl, _file },
  _isSharedFile = false,
) => {
  let option = {
    headers: { "x-amz-acl": "public-read" },
    onUploadProgress: (event) => {
      //Set the progress value to show the progress bar
      if (_isSharedFile) {
        store.dispatch(
          setSharedFileProgressBar(
            Math.round((100 * event.loaded) / event.total),
          ),
        );
      } else {
        store.dispatch(
          setProgress(Math.round((100 * event.loaded) / event.total)),
        );
      }
    },
  };
  try {
    const response = await axios
      .put(_uploadUrl, _file, option)
      .then((response) => {
        return response;
      })
      .catch((thrown) => {
        console.log("thrown", thrown);
      });
    return response;
  } catch (error) {
    return error.response;
  }
};
