/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { joinScheduleCall, startScheduledCall } from "../Helpers/CallCommon";
import { globalStrings } from "../util/translation/languages";

const ScheduleCallList = ({ closeModal, scheduleCallType }) => {
  const scheduledCallList = useSelector(
    (state) => state.call.scheduledCallList,
  );
  const scheduledCallListForHost = useSelector(
    (state) => state.call.scheduledCallListForHost,
  );
  const selectedLanguage = useSelector((state) => state.chat.selectedLanguage);
  const callList = useMemo(() => {
    return scheduleCallType === "admin"
      ? scheduledCallListForHost
      : scheduledCallList;
  }, [scheduleCallType]);

  const callHanlder = (data) => {
    if (scheduleCallType === "admin") {
      startScheduledCall(data);
    } else {
      joinScheduleCall(data);
    }
    closeModal();
  };
  return (
    <Modal show={true} onHide={() => closeModal()}>
      <Modal.Header>
        <Modal.Title className="m-0 text-uppercase">
          {scheduleCallType === "admin"
            ? globalStrings[selectedLanguage].$scheduledCallTitleForAdmin
            : globalStrings[selectedLanguage].$scheduledCallTitle}
        </Modal.Title>
        <a className="icon-close top-10" onClick={() => closeModal()}>
          <svg
            width="15.556"
            height="15.556"
            viewBox="2240.448 1762.444 15.556 15.556"
          >
            <path
              d="m2250.727 1766.055-2.5 2.5-2.5-2.5a.786.786 0 0 0-1.112 0l-.555.556a.786.786 0 0 0 0 1.11l2.5 2.5-2.5 2.501a.786.786 0 0 0 0 1.111l.555.556a.786.786 0 0 0 1.111 0l2.5-2.5 2.5 2.5a.786.786 0 0 0 1.112 0l.555-.556a.786.786 0 0 0 0-1.11l-2.5-2.501 2.5-2.5a.786.786 0 0 0 0-1.111l-.555-.556a.786.786 0 0 0-1.111 0Z"
              fill="#2d343f"
            ></path>
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column">
          {callList &&
            callList.map((item, index) => {
              return (
                <div
                  className="d-flex align-items-center justify-content-between mb-2"
                  key={index}
                >
                  {item.scheduleName}
                  <button
                    className="btn btn-green"
                    onClick={() => callHanlder(item)}
                  >
                    {scheduleCallType === "admin"
                      ? globalStrings[selectedLanguage].$start
                      : globalStrings[selectedLanguage].$join}
                  </button>
                </div>
              );
            })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ScheduleCallList;
