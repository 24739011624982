import React from "react";
import { useSelector } from "react-redux";
import { globalStrings } from "../../util/translation/languages";
import { sendHangup } from "../CallCommon";

function HostRequired({ props, allAttendeeList }) {
  const selectedLanguage = useSelector((state) => state.chat.selectedLanguage);
  return (
    <div className="d-flex flex-column align-items-center">
      <h2>{globalStrings[selectedLanguage]?.$hostRequiredMsg}</h2>
      <button
        id="submit"
        className="btn btn-red btn btn-primary mt-2"
        onClick={(event) => sendHangup(event, props, allAttendeeList)}
      >
        <span className="flex-1">{globalStrings[selectedLanguage]?.$exit}</span>
      </button>
    </div>
  );
}

export default HostRequired;
