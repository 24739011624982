import React from "react";
import { useSelector } from "react-redux";

function ProgressBar() {
  const { sharedFileProgressBar } = useSelector((state) => state.call);
  return (
    <div className="progress">
      <div
        className="progress-bar progress-bar-striped progress-bar-animated"
        role="progressbar"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
        style={{ width: "100%" }}
      />
      <div className="progress-count">{sharedFileProgressBar}%</div>
    </div>
  );
}

export default ProgressBar;
