/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { memo, useState } from "react";
import virtualBackgroundImg1 from "../../assets/virtualBackgroundImages/virtualBackgroundImg1.png";
import virtualBackgroundImg2 from "../../assets/virtualBackgroundImages/virtualBackgroundImg2.png";
import virtualBackgroundImg4 from "../../assets/virtualBackgroundImages/virtualBackgroundImg4.png";
import virtualBackgroundImg5 from "../../assets/virtualBackgroundImages/virtualBackgroundImg5.png";
import virtualBackgroundImg6 from "../../assets/virtualBackgroundImages/virtualBackgroundImg6.png";
import virtualBackgroundImg3 from "../../assets/virtualBackgroundImages/virtualBackgroundImg3.png";

const VirtualBackground = (props) => {
  const { applyVirtualBackground, virtualBackgroundOptions } = props;

  const [backgroundList] = useState([
    {
      src: virtualBackgroundImg1,
      uuid: "b740118e-cb4f-4b8d-8fda-ec27764761e3",
    },
    {
      src: virtualBackgroundImg2,
      uuid: "f41d870d-0318-407a-ba9b-413ded02948a",
    },
    {
      src: virtualBackgroundImg3,
      uuid: "21b20a58-5678-435b-a1d2-fd27d9485457",
    },
    {
      src: virtualBackgroundImg4,
      uuid: "1e73511a-52ef-4515-bfeb-b24089386eb1",
    },
    {
      src: virtualBackgroundImg5,
      uuid: "f09afbfd-f3d4-4d2f-8b11-080344334f3b",
    },
    {
      src: virtualBackgroundImg6,
      uuid: "46b78d43-601c-44e2-94f3-dffacd255d29",
    },
  ]);

  return (
    <>
      <div className="d-flex">
        {backgroundList.length > 0 ? (
          backgroundList.map((image, index) => (
            <div
              className={"virtual-thumbnail-container"}
              key={index}
              onClick={(e) => applyVirtualBackground(image, e)}
            >
              <img
                className={
                  virtualBackgroundOptions?.selectedThumbnail === image.uuid
                    ? "virtual-thumbnail virtual-thumbnail-selected"
                    : "virtual-thumbnail"
                }
                key={index}
                src={image.src}
                loading="lazy"
                alt="image"
              />
            </div>
          ))
        ) : (
          <>
            {/* {!loading && selectedCategory === "upload" && (
                  <p>Images you upload will appear here</p>
                )} */}
          </>
        )}
      </div>
    </>
  );
};

export default memo(VirtualBackground);
