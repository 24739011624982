const french = {
  $groupMessage: "MESSAGES DE GROUPE",
  $directMessage: "MESSAGES DIRECTS",
  $about: "À propos",
  $members: "Membres",
  $settings: "Paramètres",
  $addHost: "Ajouter un hôte",
  $addAttendee: "Ajouter un participant",
  $addUser: "Ajouter un utilisateur",
  $groupName: "Nom de groupe",
  $groupDescription: "Description du groupe",
  $createdBy: "Créé par",
  $microphone: "Microphone",
  $speaker: "Conférencier",
  $camera: "Caméra",
  $callSound: "Son d'appel",
  $chatSound: "Son du chat",
  $videoCall: "Appel vidéo",
  $downloadForMac: "Télécharger l'application Mac",
  $preparingForDownload: "Préparation du téléchargement",
  $launchWindowsApp: "Lancer l'application Windows",
  $downloadWindowsApp: "Télécharger l'application Windows",
  $description: "Description",
  $selectAttendeesIsMandatory: "Sélectionner les participants est obligatoire",
  $back: "Dos",
  $createGroup: "Créer un groupe",
  $selectedAttendee: "Sélectionnez les participants",
  $selectAdditionalHosts: "Sélectionnez des hôtes supplémentaires",
  $pTagStandardGroup:
    "C'est le groupe standard. Nommez votre groupe autour d'un sujet et entrez une description si nécessaire. Ajoutez des participants individuels et des hôtes supplémentaires.",
  $createStandardGroup: "Créer un groupe standard",
  $createAGroup: "Créer un groupe",
  $standardGroup: "Groupe standard",
  $reactToolTipLine1: "Créer des groupes de discussion manuellement",
  $reactToolTipLine2: "ajouter et supprimer des utilisateurs.",
  $secondToolTipLine1: "Créez des groupes de discussion en autorisant",
  $secondToolTipLine2: "le système pour ajouter dynamiquement",
  $secondToolTipLine3: "et supprimer des utilisateurs en fonction de",
  $secondToolTipLine4: "règles que vous établissez.",
  $dynamicGroup: "Groupe dynamique",
  $learnMore: "Apprendre encore plus",
  $add: "Ajouter",
  $editGroupName: "Modifier le nom du groupe",
  $editGroupDescription: "Modifier la description du groupe",
  $save: "Sauvegarder",
  $toolTipForGroupSetting:
    "Vous ne pouvez modifier un groupe géré qu'à partir de c2perform.com",
  $addPeople: "Ajouter des personnes",
  $muteGroup: "Groupe muet",
  $leaveThisGroup: "Quitter ce groupe",
  $deleteThisGroup: "Supprimer ce groupe",
  $audioVideo: "Audio Video",
  $ringtone: "Sonnerie",
  $translation: "Traduction",
  $scheduledCalls: "Appels programmés",
  $previousMeetings: "Réunions précédentes",
  $saveSettings: "Enregistrer les paramètres",
  $language: "Langue",
  $spanScheduleCall:
    "Tous les appels programmés sont répertoriés sur le lien ci-dessous.",
  $clickToProceed: "Veuillez cliquer ici pour continuer.",
  $spanRecordingCall:
    "Tous les enregistrements d'appels sont répertoriés sur le lien ci-dessous",
  $playSound: "Jouer son",
  $playing: "Jouant",
  $invitePeopleToCall: "Inviter des personnes à cet appel",
  $logoutFromAllDevice: "Déconnectez-vous de tous les appareils",
  $cancel: "Annuler",
  $delete: "Supprimer",
  $leave: "Partir",
  $kickOut: "Expulser",
  $remove: "Retirer",
  $accept: "Accepter",
  $badWordAlert: "Alerte de mots grossiers",
  $pTagBadWordMessage:
    "Ce message n'a pas été envoyé car il contient des mots grossiers.",
  $deleteMsg: "Supprimer le message",
  $pTagDeleteMessage:
    "Etes-vous sur de vouloir supprimer ce message? Ça ne peut pas être annulé.",
  $fileSizeMsg:
    "Le fichier ne prend pas en charge. Veuillez télécharger un fichier dont la taille ne dépasse pas 5 Go",
  $close: "Fermer",
  $acceptCall: "Accepter l'appel",
  $endCall: "Fin d'appel",
  $waitingForHost: "En attente de l'hôte",
  $raiseHand: "Lever la main",
  $mic: "Micro",
  $whiteboard: "Tableau blanc",
  $declineCall: "a refusé l'appel",
  $group: "Groupe",
  $groupCall: "Appel de groupe",
  $meetingRecorded: "Cette réunion est enregistrée",
  $pTagMeetingRecorded:
    "En continuant à participer à la réunion, vous consentez à être enregistré",
  $leaveMeeting: "Quitter la réunion",
  $continue: "Continuer",
  $faqList: "FAQ",
  $accordionItemOne: "Accordéon Article Un",
  $loremIpsum: `C'est très important d'avoir un service client, mais c'est comme ça
                avec le temps, ils se produisent comme avec un grand travail et de la douleur. Pour au moins
                Allez, personne ne devrait pratiquer n'importe quel type de travail, sauf que certains d'entre eux
                bonne chance Doutes ou irure douleur à réprimander dans le plaisir
                Il veut être un cheveu, laissez-le fuir la douleur et personne n'accouchera. Sauf s'ils sont aveugles
                le désir ne va pas, ils sont fautifs ceux qui abandonnent leurs devoirs ;
                c'est un travail difficile.`,
  $accordionItemTwo: "Accordéon Point Deux",
  $accordionItemThree: "Accordéon Point Trois",
  $accordionItemFour: "Accordéon Point Quatre",
  $cannotPlaceCall:
    "Vous ne pouvez pas passer un appel si vous êtes déjà sur un autre appel",
  $anotherCall: "est sur un autre appel",
  $userProfile: "Profil de l'utilisateur",
  $pTagUserProfile: "Nous sommes fiers de vous Nous sommes fiers de vous",
  $message: "Message",
  $huddle: "Se blottir",
  $pTagGroupAdd:
    "Les groupes sont l'endroit où votre équipe communique. Ils sont meilleurs lorsqu'ils sont organisés autour d'un sujet - #marketing, par exemple.",
  $groupNameErrorMsg: "Le champ du nom du groupe est obligatoire.",
  $callingYou: "t'appelle",
  $pms: "Système de gestion des performances | Alimenté par",
  $dashboardError:
    "Vous avez été déconnecté de cette session, veuillez fermer cette application ou cette fenêtre et vous reconnecter avec vos identifiants",
  $dashboardError1:
    "Il semble y avoir un problème avec votre compte. Veuillez copier le message suivant et l'envoyer à votre support système afin qu'il puisse être corrigé. (Code d'erreur:",
  $belowHeaderError:
    "Il semble y avoir un problème avec ce compte. Veuillez contacter votre administrateur.",
  $desktopAlert: "Alertes de bureau",
  $desktopAlertMsg:
    "Le nombre de notifications non lues sur le bureau peut parfois rester bloqué ou ne pas correspondre au nombre réel, si cela se produit,",
  $clearCount: "cliquez ici",
  $desktopAlertMsg1:
    "pour effacer le nombre d'icônes de l'application de bureau.",
  $joinCall: "Rejoignez cet appel",
  $box: "Boîte",
  $polygon: "Polygone",
  $ellipse: "Ellipse",
  $arrow: "Flèche",
  $solidLine: "Ligne continue",
  $freeHand: "Main libre",
  $text: "Texte",
  $image: "Image",
  $eraser: "Gomme",
  $clearAll: "Tout effacer",
  $select: "Sélectionner",
  $triangle: "Triangle",
  $ra_triangle: "Triangle à angle droit",
  $colorPicker: "Choisissez la couleur",
  $clear: "Claire",
  $joinScheduleCall: "REJOIGNEZ L'APPEL EN COURS",
  $scheduledCallTitle: "LISTE D'APPELS EN COURS",
  $join: "Rejoindre",
  $startScheduleCall: "DÉMARRER L'APPEL HORAIRE",
  $scheduledCallTitleForAdmin: "Planifier la liste d'appels",
  $start: "Commencer",
  $virtualBackground: "Fond virtuel",
  $fullBlurBackground: "Arrière-plan entièrement flou",
  $partialBlurBackground: "Arrière-plan flou partiel",
  $GroupCallMaxLimit:
    "L'appel de groupe est désactivé au-delà de 75 participants",
  $muteAll: "Rendre tout silencieux",
  $endMeetingForAll: "Fin de la réunion pour tous",
  $endMeetingMsg: "L'hôte a mis fin à la réunion.",
  $hostRequiredMsg:
    "La réunion commencera à l'arrivée d'un hôte. Veuillez patienter.",
  $exit: "Sortie",
};

export default french;
