/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { memo, useState } from "react";

import UserSettingModal from "../models/UserSettingModal";

const UserSetting = ({ selectedLanguage }) => {
  const [show, setShow] = useState(false);
  const [settingType, setSettingType] = useState(false);

  const settingOpen = () => {
    setShow(true);
    setSettingType("type");
  };

  const handleClose = () => {
    setShow(false);
  };

  const userSettingModalProps = {
    show,
    settingType,
    setSettingType,
    handleClose,
    selectedLanguage,
  };

  return (
    <>
      <span
        className="alink three-dot icon_container m-0"
        onClick={() => settingOpen()}
      >
        <svg width="3.5px" viewBox="10 4 4 16">
          <path
            fill="#b8c0c9"
            d="M12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 12c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
          ></path>
        </svg>
      </span>
      {show && <UserSettingModal {...userSettingModalProps} />}
    </>
  );
};

export default memo(UserSetting);
