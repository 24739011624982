import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ChatBoardHeader from "./chatboard/chatboardHeader";
import ChatBoardBody from "./chatboard/chatboardBody";
import ChatBoardFooter from "./chatboard/chatboardFooter";
import ChatBoardWelcome from "./chatboard/ChatboardWelcome";
import { getAuthSession } from "./Helpers/common";
import { _getUpdatedChatMessage } from "../SocketIo";
import { setWelcomeBoard } from "./redux/authUserDetail";
import { setAttendeeData } from "./redux/chatSlice";

const ChatBoard = () => {
  const dispatch = useDispatch();
  const { user_unique_key: senderID } = getAuthSession();
  const welcomeBoard = useSelector(
    (state) => state?.authUserDetail.welcomeBoard,
  );
  const attendeeData = useSelector((state) => state?.chat.attendeeData);
  const groupList = useSelector((state) => state?.chat.groupList);
  const selectedLanguage = useSelector((state) => state?.chat.selectedLanguage);
  const allAttendeeList = useSelector((state) => state.call.allAttendeeList);

  let UserData = attendeeData ? attendeeData : {};

  useEffect(() => {
    if (Object.keys(attendeeData).length > 0) {
      if (attendeeData?.type == "group") {
        const selectedData = groupList.find(
          (item) => item?.groupId === attendeeData?.groupId,
        );
        if (selectedData === undefined) {
          dispatch(setAttendeeData({}));
        } else {
          const userNameData = [];
          selectedData?.attendeeId?.forEach((item) => {
            const filterUsername = allAttendeeList?.filter(
              (v) => v?.user_id === item,
            );
            if (filterUsername && filterUsername.length > 0) {
              userNameData.push(filterUsername[0].name);
            }
          });
          const username =
            userNameData.length > 0 ? userNameData.toString() : "";
          dispatch(
            setAttendeeData({ ...selectedData, type: "group", username }),
          );
        }
      } else {
        const selectedData = allAttendeeList.find(
          (item) => item.user_id === attendeeData?.user_id,
        );
        dispatch(
          setAttendeeData(
            selectedData === undefined
              ? {}
              : {
                  ...selectedData,
                  role_name: selectedData.role,
                  type: "attendee",
                },
          ),
        );
      }
    }
  }, [allAttendeeList, groupList]);

  useEffect(async () => {
    if (Object.keys(attendeeData).length > 0) {
      if (attendeeData?.type == "attendee") {
        dispatch(setWelcomeBoard(true));
      } else {
        if (
          attendeeData?.attendeeId?.includes(senderID) &&
          attendeeData?.type == "group"
        ) {
          dispatch(setWelcomeBoard(true));
        } else {
          dispatch(setWelcomeBoard(false));
        }
      }
    } else {
      dispatch(setWelcomeBoard(false));
    }
  }, [attendeeData]);

  useEffect(() => {
    _getUpdatedChatMessage(senderID);
  }, []);

  return (
    <>
      {welcomeBoard ? (
        <div className="right_s">
          <div className="chat_box">
            <ChatBoardHeader />
            <div className="body body-inner">
              {Object.keys(UserData).length > 0 ? <ChatBoardBody /> : null}
            </div>
            <ChatBoardFooter />
          </div>
        </div>
      ) : (
        <div className="right_s">
          <div className="chat_box">
            <div className="body">
              <ChatBoardWelcome selectedLanguage={selectedLanguage} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatBoard;
