import React from "react";
import { Modal, Button } from "react-bootstrap";

import { logoutHandler } from "../Helpers/chatCommon";
import { globalStrings } from "../util/translation/languages";

const AlertModal = ({
  show,
  closeModal,
  msg,
  Remove,
  alertType,
  logOutButtonEnable,
  selectedLanguage,
}) => {
  return (
    <Modal
      className="attachment_info alert-pop"
      show={show}
      onHide={
        alertType != "company" && alertType != "user"
          ? () => closeModal()
          : undefined
      }
    >
      <Modal.Body
        className={`d-flex align-items-center justify-content-center ${
          logOutButtonEnable && "flex-column p-3"
        }`}
      >
        <h6 className="m-0 text-center">{msg}</h6>
        {logOutButtonEnable && (
          <Button
            className="btn btn-orange mt-3"
            variant="secondary"
            onClick={() => logoutHandler()}
          >
            {globalStrings[selectedLanguage]?.$logoutFromAllDevice}
          </Button>
        )}
      </Modal.Body>
      {alertType != "accountInactive" ? (
        <Modal.Footer className="d-flex justify-content-around">
          <Button
            className="btn btn-light"
            variant="secondary"
            onClick={() => closeModal()}
          >
            {globalStrings[selectedLanguage]?.$cancel}
          </Button>

          <Button
            className="btn btn-orange"
            variant="secondary"
            onClick={() => Remove()}
          >{`${
            alertType === "group"
              ? globalStrings[selectedLanguage]?.$delete
              : alertType === "groupLeave"
              ? globalStrings[selectedLanguage]?.$leave
              : alertType === "kickOut"
              ? globalStrings[selectedLanguage]?.$kickOut
              : globalStrings[selectedLanguage]?.$remove
          }`}</Button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export default AlertModal;
