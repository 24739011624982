// eslint-disable-next-line import/no-unresolved
import store from "../../store";
import { CALL_TITLE, SCHEDULE_CALL_TITLE } from "../Helpers/common";

import { chatSoundDetail } from "./sounds";

const notify = (config, callback) => {
  // Check for notification compatibility.
  if (!("Notification" in window)) {
    // If the browser version is unsupported, remain silent.
    return;
  }

  // If the user has not been asked to grant or deny notifications for this domain
  if (Notification.permission === "default") {
    Notification.requestPermission(function () {
      // ...callback this function once a permission level has been set.
      notify();
    });
  }
  // If the user has granted permission for this domain to send notifications...
  else if (Notification.permission === "granted") {
    // const {
    //   title = "Notification from C2perform-Chat",
    //   body = "",
    //   icon = "/favicon.ico",
    // } = config;
    const title = config?.title || "Notification from C2perform-Chat";
    const body = config?.body || "";
    const icon = config?.icon || "/favicon.ico";

    var notification = new Notification(title, { body, icon });

    // Remove the notification from Notification Center when clicked.
    notification.onclick = function () {
      window.focus();
      this.close();
      if (callback) callback();
    };

    // Working on localhost on Firefox, but not on Chrome
    notification.addEventListener("close", () => {
      if (callback) {
        callback();
      }
    });

    // TODO: Check why this is not at all working but addEventListener is working for some browsers

    // Callback function when the notification is closed.
    // notification.onclose = function () {
    // };
  }
  // If the user does not want notifications to come from this domain...
  else if (Notification.permission === "denied") {
    // ...remain silent.
    return;
  }
};

const PushNotification = {
  showIncomingTextMessageNotification: (
    fromNumber,
    message,
    userImage,
    callback,
  ) => {
    const authSessionUser = store.getState().authUserDetail.authSessionUser;
    let audioSound = "";
    if (authSessionUser?.chat_sound !== undefined) {
      audioSound = chatSoundDetail.find(
        (findItem) => findItem.name === authSessionUser?.chat_sound,
      )?.value;
    } else {
      audioSound = chatSoundDetail[0].value;
    }
    const audio = new Audio(audioSound);
    if (audio) {
      audio?.play();
    }
    notify(
      {
        title: `New Message from ${fromNumber}`,
        body: `${message}`,
        icon: userImage,
      },
      callback,
    );
  },
  showIncomingGroupChatNotification: (
    from,
    message,
    userImage,
    groupName,
    groupId,
    callback,
  ) => {
    const authSessionUser = store.getState().authUserDetail.authSessionUser;
    let audioSound = "";
    if (authSessionUser?.chat_sound !== undefined) {
      audioSound = chatSoundDetail.find(
        (findItem) => findItem.name === authSessionUser?.chat_sound,
      )?.value;
    } else {
      audioSound = chatSoundDetail[0].value;
    }
    const audio = new Audio(audioSound);
    if (audio) {
      audio?.play();
    }
    notify(
      {
        title: `💬 ${groupName} Group - ${from} `,
        body: `${message}`,
        icon: userImage,
      },
      callback,
    );
  },

  showIncomingCallNotification: (fromNumber, userImage) => {
    notify({
      title: CALL_TITLE,
      body: `From: ${fromNumber}`,
      icon: userImage,
    });
  },

  showIncomingGroupCallNotification: (fromNumber, userImage, groupName) => {
    notify({
      title: CALL_TITLE,
      body: `From: ${fromNumber} in ${groupName} Group`,
      icon: userImage,
    });
  },

  showScheduledCallReminderNotification: (scheduleBody, icon) => {
    notify({
      title: SCHEDULE_CALL_TITLE,
      body: scheduleBody,
      icon: icon,
    });
  },
};

export default PushNotification;
