import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  authSessionUser: {},
  companyDetail: {},
  userLoginToken: null,
  IsLoggedInAccountActive: false,
  welcomeBoard: false,
  sessionThemeData: false,
  errorCode: "",
  logout: false,
  authSuccess: false,
  authSession: {},
};
const authUserDetailSlice = createSlice({
  name: "authUserDetail",
  initialState,
  reducers: {
    setAuthSessionUser: (state, action) => {
      state.authSessionUser = action.payload;
    },
    setCompanyDetail: (state, action) => {
      state.companyDetail = action.payload;
    },
    setUserLoginToken: (state, action) => {
      state.userLoginToken = action.payload;
    },
    setIsLoggedInAccountActive: (state, action) => {
      state.IsLoggedInAccountActive = action.payload;
    },
    setWelcomeBoard: (state, action) => {
      state.welcomeBoard = action.payload;
    },
    setSessionThemeData: (state, action) => {
      state.sessionThemeData = action.payload;
    },
    setErrorCode: (state, action) => {
      state.errorCode = action.payload;
    },
    setLogout: (state, action) => {
      state.logout = action.payload;
    },
    setAuthSuccess: (state, action) => {
      state.authSuccess = action.payload;
    },
    setAuthSession: (state, action) => {
      state.authSession = action.payload;
    },
  },
});

export default authUserDetailSlice.reducer;
export const {
  setAuthSessionUser,
  setCompanyDetail,
  setUserLoginToken,
  setIsLoggedInAccountActive,
  setWelcomeBoard,
  setSessionThemeData,
  setErrorCode,
  setLogout,
  setAuthSuccess,
  setAuthSession,
} = authUserDetailSlice.actions;
