/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import { _groupUpdate } from "../../SocketIo";
import { DropdownIndicator, ClearIndicator } from "../Helpers/common";
import { setAttendeeData } from "../redux/chatSlice";
import { globalStrings } from "../util/translation/languages";

const GroupAddUser = (props) => {
  const { show, handleClose, setUserAddedData, groupaddType } = props;
  const dispatch = useDispatch();
  const attendeeData = useSelector((state) => state.chat.attendeeData);
  const selectedLanguage = useSelector((state) => state.chat.selectedLanguage);
  const attendeeList = useSelector((state) => state.chat.attendeeList);
  const { allAttendeeList } = useSelector((state) => state.call);
  const [attendeeListForGroup, setAttendeeListForGroup] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);
  const [attendeeError, setAttendeeError] = useState(false);
  let groupId = "";

  if (attendeeData.type == "group") {
    groupId = attendeeData.groupId;
  }

  useEffect(async () => {
    if (groupId) {
      setSelectedOption([]);
    }
  }, [attendeeData?.hostId || attendeeData?.attendeeId]);

  const submitGroupUserForm = async (e) => {
    e.preventDefault();
    if (selectedOption.length > 0) {
      setUserAddedData(true);
      let attendeeUser = [];
      selectedOption.forEach((so) => {
        let data = so.value;
        attendeeUser.push(data);
      });
      const hostId = attendeeData?.hostId ?? [];
      let userId =
        groupaddType === "host" ? [...hostId] : [...attendeeData.attendeeId];
      let allUser = [...attendeeUser, ...userId];
      const removedDuplicateUsers = [...new Set(allUser)];
      const userNameData = [];
      removedDuplicateUsers?.forEach((item) => {
        const filterUsername = allAttendeeList?.filter(
          (v) => v?.user_id === item,
        );
        if (filterUsername && filterUsername.length > 0) {
          userNameData.push(filterUsername[0].name);
        }
      });
      const username = userNameData.length > 0 ? userNameData.toString() : "";
      const updatedAttendedData = {
        ...attendeeData,
        attendeeId:
          groupaddType === "attendeee"
            ? removedDuplicateUsers
            : attendeeData.attendeeId,
        username,
        hostId:
          groupaddType === "host" ? removedDuplicateUsers : attendeeData.hostId,
      };
      const updateGroupData = { ...attendeeData };
      if (groupaddType === "host") {
        let allAttendee = [
          ...removedDuplicateUsers,
          ...attendeeData.attendeeId,
        ];
        const removedDuplicateAttendees = [...new Set(allAttendee)];
        updateGroupData["attendeeId"] = removedDuplicateAttendees;
        updatedAttendedData["attendeeId"] = removedDuplicateAttendees;
      }
      updateGroupData[groupaddType === "host" ? "hostId" : "attendeeId"] =
        removedDuplicateUsers;
      dispatch(setAttendeeData(updatedAttendedData));
      _groupUpdate(updateGroupData);
      setSelectedOption([]);
      setAttendeeError(false);
      closePopUp();
    } else {
      setAttendeeError(true);
    }
  };

  const handleChange = (option) => {
    setSelectedOption(option);
    if (option.length === 0) {
      setAttendeeError(true);
    } else {
      setAttendeeError(false);
    }
  };

  useEffect(async () => {
    setArray(attendeeList);
  }, [attendeeData, attendeeList]);

  const setArray = (obj) => {
    let list = [];
    obj.forEach((v) => {
      if (!attendeeData?.attendeeId?.includes(v.user_id)) {
        let data = {
          value: v.user_id,
          label: v.name,
          online: v.online,
          custom: true,
        };
        list.push(data);
      }
    });
    setAttendeeListForGroup(list);
    return list;
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return data.custom ? (
      <div ref={innerRef} {...innerProps}>
        <span className="d-flex align-items-center Invite-People-drop">
          <span
            className={`status marginRight-5 ${data.online ? "online" : ""} `}
          ></span>{" "}
          {data.label}
        </span>
      </div>
    ) : (
      ""
    );
  };

  const closePopUp = () => {
    handleClose();
    setSelectedOption([]);
  };

  return (
    <>
      <Modal show={show} onHide={closePopUp}>
        <Modal.Header>
          <Modal.Title>
            <span className="title-span">
              {groupaddType === "host"
                ? globalStrings[selectedLanguage]?.$addHost
                : globalStrings[selectedLanguage]?.$addUser}
            </span>
          </Modal.Title>
          <a onClick={closePopUp} className="icon-close">
            <svg
              width="15.556"
              height="15.556"
              viewBox="2240.448 1762.444 15.556 15.556"
            >
              <path
                d="m2250.727 1766.055-2.5 2.5-2.5-2.5a.786.786 0 0 0-1.112 0l-.555.556a.786.786 0 0 0 0 1.11l2.5 2.5-2.5 2.501a.786.786 0 0 0 0 1.111l.555.556a.786.786 0 0 0 1.111 0l2.5-2.5 2.5 2.5a.786.786 0 0 0 1.112 0l.555-.556a.786.786 0 0 0 0-1.11l-2.5-2.501 2.5-2.5a.786.786 0 0 0 0-1.111l-.555-.556a.786.786 0 0 0-1.111 0Z"
                fill="#2d343f"
              />
            </svg>
          </a>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label className="text-label">
              {groupaddType === "host"
                ? globalStrings[selectedLanguage]?.$addHost
                : globalStrings[selectedLanguage]?.$addAttendee}
            </label>
            <Select
              className="form-control"
              isMulti={true}
              value={selectedOption}
              onChange={(e) => handleChange(e)}
              options={attendeeListForGroup}
              components={{
                Option: CustomOption,
                DropdownIndicator,
                ClearIndicator,
              }}
              classNamePrefix="select-menu-custom"
            />
            {attendeeError && (
              <div className="srv-validation-message">{`Select ${
                groupaddType === "host" ? "Host" : "Attendees"
              } is mandatory`}</div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a className="d-flex align-items-center text-link">
            <svg viewBox="2 2 20 20" width="14px">
              <path
                fill="#425b76"
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
              ></path>
              <path fill="#425b76" d="M11 11h2v6h-2zm0-4h2v2h-2z"></path>
            </svg>
            &nbsp; {globalStrings[selectedLanguage]?.$learnMore}
          </a>
          <Button
            className="btn btn-orange w-fit-content"
            type="submit"
            id="submit"
            variant="primary"
            onClick={(e) => {
              submitGroupUserForm(e);
            }}
          >
            {globalStrings[selectedLanguage]?.$add}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default GroupAddUser;
