import React, { Component } from "react";

class Video extends Component {
  constructor(props) {
    super(props);

    this._videoElement = null;
    this._onVideoPlaying = this._onVideoPlaying.bind(this);
    this._setVideoElement = this._setVideoElement.bind(this);
  }

  componentDidMount() {
    this._mounted = true;

    if (this._videoElement) {
      this._videoElement.volume = 0;
      this._videoElement.onplaying = this._onVideoPlaying;
    }
    this._attachTrack(this.props.videoTrack);

    if (this._videoElement && this.props.autoPlay) {
      this._videoElement.play().catch((error) => {
        if (this._mounted) {
          throw error;
        }
      });
    }
  }

  componentWillUnmount() {
    this._mounted = false;
    this._detachTrack(this.props.videoTrack);
  }

  shouldComponentUpdate(nextProps) {
    const currentJitsiTrack =
      this.props.videoTrack && this.props.videoTrack.jitsiTrack;
    const nextJitsiTrack =
      nextProps.videoTrack && nextProps.videoTrack.jitsiTrack;

    if (currentJitsiTrack !== nextJitsiTrack) {
      this._detachTrack(this.props.videoTrack);
      this._attachTrack(nextProps.videoTrack);
    }

    if (
      this.props.style !== nextProps.style ||
      this.props.className !== nextProps.className
    ) {
      return true;
    }

    return false;
  }

  render() {
    const {
      autoPlay,
      className,
      id,
      muted = false,
      playsinline,
      style,
      eventHandlers,
    } = this.props;

    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <video
        autoPlay={autoPlay}
        className={className}
        id={id}
        muted={muted}
        playsInline={playsinline}
        ref={this._setVideoElement}
        style={style}
        {...eventHandlers}
      />
    );
  }

  _attachTrack(videoTrack) {
    if (!videoTrack) {
      return;
    }
    videoTrack.jitsiTrack.attach(this._videoElement);
  }

  _detachTrack(videoTrack) {
    if (this._videoElement && videoTrack && videoTrack.jitsiTrack) {
      videoTrack.jitsiTrack.detach(this._videoElement);
    }
  }

  _onVideoPlaying() {
    if (this.props.onVideoPlaying) {
      this.props.onVideoPlaying();
    }
  }

  _setVideoElement(element) {
    this._videoElement = element;
  }
}

export default Video;
