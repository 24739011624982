/* eslint-disable react/display-name */
import React, { memo } from "react";

import UserInputMessageArea from "./userInputMessageArea";

const ChatBoardFooter = (props) => {
  const userInputMessageProps = { ...props, componentType: "footer" };
  return (
    <>
      <UserInputMessageArea {...userInputMessageProps} />
    </>
  );
};

export default memo(ChatBoardFooter);
