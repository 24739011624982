import { configureStore } from "@reduxjs/toolkit";

import libSlice from "./component/Helpers/Lib/libSlice";
import authUserDetailSlice from "./component/redux/authUserDetail";
import chatSlice from "./component/redux/chatSlice";
import callSlice from "./component/redux/callSlice";
import counterReducer from "./component/redux/fileSlice";
// import indexedDBMiddleware from "./component/util/indexedDBMiddleware";

const store = configureStore({
  reducer: {
    lib: libSlice,
    authUserDetail: authUserDetailSlice,
    chat: chatSlice,
    call: callSlice,
    counter: counterReducer,
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
