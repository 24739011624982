import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

let firebaseConfig = null;

// firebaseConfig = {
//   apiKey: "AIzaSyAqnCl88NsEhoR_Vr0Vk7rMV8ojx0Qd1ac",
//   authDomain: "app-804ab.firebaseapp.com",
//   databaseURL: "https://app-804ab-default-rtdb.firebaseio.com",
//   projectId: "app-804ab",
//   storageBucket: "app-804ab.appspot.com",
//   messagingSenderId: "434639645449",
//   appId: "1:434639645449:web:9736878d5d4e69a19241d8",
// };

firebaseConfig = {
  apiKey: "AIzaSyCaXlT0ao9K1Re_YvRpLrrHfVODOS9JtD8",
  authDomain: "whiteboard-b3f40.firebaseapp.com",
  databaseURL: "https://whiteboard-b3f40-default-rtdb.firebaseio.com",
  projectId: "whiteboard-b3f40",
  storageBucket: "whiteboard-b3f40.appspot.com",
  messagingSenderId: "860067710570",
  appId: "1:860067710570:web:f509df2fc6db335dfc6c13",
  measurementId: "G-P9EYH0W9ZG",
};

export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const auth = getAuth();
