/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { memo } from "react";

const CustomDropdown = ({
  handleChatFilterByDate = () => null,
  isOpenDropdown = "",
}) => {
  return (
    <div className={`chat-custom-dropdown ${isOpenDropdown}`}>
      <ul className="chat_actions_dropdown flex-column">
        <li className="chat_actions_li no_hover">
          <small>Jump to...</small>
        </li>
        <li
          className="chat_actions_li"
          onClick={() => handleChatFilterByDate(1)}
        >
          <span className="chat_actions_tag">1 day ago</span>
        </li>
        <li
          className="chat_actions_li"
          onClick={() => handleChatFilterByDate(3)}
        >
          <span className="chat_actions_tag">3 days ago</span>
        </li>
        <li
          className="chat_actions_li"
          onClick={() => handleChatFilterByDate(7)}
        >
          <span className="chat_actions_tag">7 days ago</span>
        </li>
        <li
          className="chat_actions_li"
          onClick={() => handleChatFilterByDate(15)}
        >
          <span className="chat_actions_tag">15 days ago</span>
        </li>
        <li
          className="chat_actions_li"
          onClick={() => handleChatFilterByDate(30)}
        >
          <span className="chat_actions_tag">30 days ago</span>
        </li>
      </ul>
    </div>
  );
};

export default memo(CustomDropdown);
