import React from "react";
import Video from "../Helpers/Lib/video";

const VideoInputPreview = ({ tracks }) => {
  let videoTrack = { jitsiTrack: tracks };
  return (
    <Video
      videoTrack={videoTrack}
      className={
        "video-input-preview-display h-100 w-100 border-radius object-fit-cover"
      }
      autoPlay={true}
    />
  );
};

export default VideoInputPreview;
