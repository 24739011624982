import React, { useEffect, useState } from "react";
import { Modal, ModalFooter, Tabs, Tab, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { monitorStart } from "../Helpers/CallCommon";

const TAB_LABELS = {
  screen: "Your Entire Screen",
};
// const VALID_TYPES = Object.keys(TAB_LABELS);

export default function DesktopScreenMonitorModal(props) {
  const [selectedSource, setSelectedSource] = useState({});
  const [sources, setSources] = useState({});
  const [types] = useState(["screen"]);

  const monitorScreens = useSelector((state) => state.call.monitorScreens);

  useEffect(() => {
    setSources(monitorScreens);
  }, [monitorScreens]);

  useEffect(() => {
    if (Object.keys(monitorScreens).length > 0) {
      const { id, type } = monitorScreens["screen"][0];
      setSelectedSource({ id, type });
    }
  }, []);

  const _renderTabs = () => {
    return (
      <Tabs defaultActiveKey="screen" fill className="monitor-tab">
        {types.map((type) => {
          return _renderTab(sources[type], type, selectedSource);
        })}
      </Tabs>
    );
  };

  const _onPreviewClick = (id, type, name) => {
    setSelectedSource({ id, type, name });
  };

  const _renderTab = (sources, type, selectedSource) => {
    const previews = sources ? (
      sources.map((source) => _renderSource(source, type, selectedSource))
    ) : (
      <div className="spinner">
        <div className="spin"></div>
      </div>
    );

    return (
      <Tab
        eventKey={type}
        title={TAB_LABELS[type]}
        className="tab-content-min tab-content-max"
      >
        <div className="d-flex flex-wrap share__container">{previews}</div>
      </Tab>
    );
  };

  const _renderSource = (source, type, selectedSource) => {
    const key = source.id;
    const selectedClass = key === selectedSource.id ? "selected" : "";
    const displayClasses = `share__box ${selectedClass} d-flex align-items-center justify-content-center`;
    return (
      <div
        className={displayClasses}
        onClick={() => {
          _onPreviewClick(source.id, type, source.name);
        }}
        onDoubleClick={() => {
          _onSubmit(source.id, type);
        }}
        key={key}
        aria-hidden="true"
      >
        <img src={source.imgUrl} alt="Share" width="100%" height="100%" />
        {/* <div className="desktop-source-preview-label">{source.name}</div> */}
      </div>
    );
  };

  const _onSubmit = () => {
    const { id, type } = selectedSource;
    monitorStart({ id, type });
    _onCloseModal(id, type);
  };

  const _onCloseModal = () => {
    props.handleClose();
  };

  return (
    <Modal show={props.show} size="lg">
      <Modal.Header className="flex-column align-items-start">
        <Modal.Title>Select screen</Modal.Title>
      </Modal.Header>
      <Modal.Body>{_renderTabs()}</Modal.Body>
      <ModalFooter className="justify-content-end">
        <div className="d-flex">
          <Button
            className="mr-2"
            variant="light"
            onClick={() => _onCloseModal()}
          >
            Cancel
          </Button>
          <Button variant="orange" onClick={() => _onSubmit()}>
            Monitor
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}
