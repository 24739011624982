import { Shapes } from "./Constant";
import "./WhiteBoard";

class DrawTools {
  constructor() {
    this.shapes = Shapes;
    this.canvasConfig = {
      touchEnabled: true,
      defaultDraw: true,
    };
    this.wb = null;
  }

  initialize = (canvasId, cb, overRides) => {
    const config = { ...this.canvasConfig, ...overRides };
    this.wb = new window.WhiteBoard(canvasId, (e, v) => cb(e, v), config);
  };

  toggleTextEnabled = (bool) => this.wb.toggleTextEnabled(bool);

  drawShapes = (shape, canvasWidth, canvasHeight, DRAW_SELF) => {
    var totWidth = DRAW_SELF ? 1 : canvasWidth;
    var totHeight = DRAW_SELF ? 1 : canvasHeight;
    if (shape && shape.points) {
      shape.points.map(function (p) {
        p.x = p.x * totWidth;
        p.y = p.y * totHeight;
        return p;
      });

      for (var i = 0; i < shape.points.length; i++) {
        shape.points[i] = this.wb.CreatePoints(shape.points[i]);
      }

      shape.points && this.wb.drawShape(shape);
    }
  };

  changeActiveShape = (shape) => {
    this.wb && this.wb.setShapeType(shape);
    return this.wb ? true : false;
  };

  PasteImage = (image) => {
    this.wb && this.wb.setImage(image);
    return this.wb ? true : false;
  };

  changeActiveMode = (_mode) => {
    this.wb && this.wb.changeMode(Boolean(_mode));
  };

  updateFiguresData = (data) => {
    this.wb && this.wb.updateFigures(data);
  };

  changeLineWidth = (width) => {
    this.wb && this.wb.updateLineWidth(width);
    return this.wb ? true : false;
  };

  changeUUID = (uuid) => {
    this.wb && this.wb.setUUID(uuid);
    return this.wb ? true : false;
  };

  changeTextStyle = (value) => {
    this.wb && this.wb.setTextStyle(value);
    return this.wb ? true : false;
  };

  setImage = (value) => {
    this.wb && this.wb.setImage(value);
    return this.wb ? true : false;
  };

  setScale = (value) => {
    this.wb && this.wb.setScale(value);
    return this.wb ? true : false;
  };

  setPanning = (value, _cb = null) => {
    if (this.wb) {
      this.wb.setPanning(value);
      if (_cb) {
        _cb(value);
      }
    }
    return this.wb ? true : false;
  };

  undo = () => this.wb && this.wb.undo();

  changeActiveColor = (color) => this.wb && this.wb.setColor(color);

  clear = () => this.wb && this.wb.clear();

  getBBoxCords = (pts, cw, ch) => {
    const newPts = pts.map((pt) =>
      this.wb.CreatePoints({ x: pt.x * cw, y: pt.y * ch }),
    );
    return this.wb.getBBoxCords(newPts);
  };

  setEraserColor = (color) => this.wb && this.wb.setEraserColor(color);

  setLock = (action) => {
    this.wb && this.wb.lockScreen(action);
  };
}

const drawTools = new DrawTools();
window.drawTools = drawTools;
export default drawTools;
export const newCanvasTools = () => {
  return new DrawTools();
};
