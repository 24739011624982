import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import VideoContainerHtml from "../Helpers/Lib/videocontainer";
import { _updateCall } from "../../SocketIo";
import { HangupCall, init } from "../Helpers/Lib";
import HostRequired from "../Helpers/Lib/hostRequired";

const Iframe = (props) => {
  const iframeLoading = useSelector((state) => state.lib.iframeLoading);
  const sessionThemeData = useSelector(
    (state) => state.authUserDetail.sessionThemeData,
  );
  const allAttendeeList = useSelector((state) => state.call.allAttendeeList);

  useEffect(async () => {
    const roomName = props.room.toLowerCase();
    const { user_unique_key } = props;
    const userName =
      allAttendeeList.find(
        (filterItem) => filterItem.user_id === user_unique_key,
      )?.name ?? "";

    HangupCall().then(() => {
      init(roomName, userName, user_unique_key, _updateCall);
    });
  }, []);

  return (
    <>
      {iframeLoading && (
        <div className="loader_spinner video-container__loader">
          <div className="loader_spinner__box">
            {sessionThemeData ? (
              <img src="/assets/images/logo.svg" alt="logo" />
            ) : (
              <img src="/assets/images/logo-dark.svg" alt="Logo" />
            )}
            <div className="spin"></div>
          </div>
        </div>
      )}
      {!iframeLoading && !props?.isHostExist ? (
        <HostRequired props={props} allAttendeeList={allAttendeeList} />
      ) : (
        <VideoContainerHtml {...props} />
      )}
    </>
  );
};
export default Iframe;
