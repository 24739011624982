export let alertMsg = {
  launchMac: "Launch Mac App",
  downloadMac: "Download Mac App",
  prepDownloading: "Preparing for Download",
  launchWindow: "Launch Windows App",
  downloadWindow: "Download Windows App",
  recordingMsg: "This meeting is being recorded…",
  countSuccess: "Unread message count clear successfully",
  fileSizeMsg: "File size should be less than 5GB",
  adminDelete: "Only user admin can delete this group",
  clearCount: "Are you sure to clear the unread message count?",
  maxLimit: "Max limit for message is 1000.",
  connectionLost:
    "No Connection. You'll be able to to send message as soon as you're back online.",
  nothingSearch: "Nothing turned up for our search",
  msgNotFound:
    "You may want to try using different keywords, checking for typos, or adjusting your filters.",
  loader: "Loader ....",
  emptyField: "user field cannot be empty",
  whiteBoardFileSizeMsg: "File size should be less than 1Mb",
};
