/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentPage, setNumPages } from "../redux/callSlice";
import { calculatePagination } from "../Helpers/CallCommon";
// import { calculateGalleryPagination } from "./utils/functions";

function Pagination_custom({ pinUserId }) {
  const dispatch = useDispatch();
  const [pagingContainer, setPagingContainer] = useState(null);
  const currrentPage = useSelector((state) => state.call.currrentPage);
  const numPages = useSelector((state) => state.call.numPages);
  const callDetail = useSelector((state) => state.call.callDetail);
  const desktopVideoTrack = useSelector((state) => state.lib.desktopVideoTrack);
  const localDesktopVideoTrack = useSelector(
    (state) => state.lib.localDesktopVideoTrack,
  );
  useEffect(() => {
    setPagingContainer(getPagingContainer(currrentPage));
  }, [currrentPage, numPages]);

  useEffect(() => {
    if (pinUserId !== null) {
      dispatch(setNumPages(1));
      dispatch(setCurrentPage(1));
    } else {
      calculatePagination();
    }
  }, [
    callDetail?.participantID,
    desktopVideoTrack,
    localDesktopVideoTrack,
    pinUserId,
  ]);

  const previousPage = (e) => {
    e.preventDefault();
    if (currrentPage !== 1) {
      const newCurrentPage = currrentPage - 1;

      dispatch(setCurrentPage(newCurrentPage));
    }
  };
  const nextPage = (e) => {
    e.preventDefault();
    if (currrentPage < numPages) {
      const newCurrentPage = currrentPage + 1;
      dispatch(setCurrentPage(newCurrentPage));
    }
  };
  const getPagingContainer = (_currrentPage) => {
    let left_active = null;
    let right_active = null;
    if (_currrentPage > 1) {
      left_active = "text-green";
    }
    if (_currrentPage < numPages) {
      right_active = "text-green";
    }
    if (numPages <= 1) {
      return null;
    } else {
      return (
        <>
          <div className="pagination_custom">
            <div className={`pagi-left ${left_active}`} onClick={previousPage}>
              <svg width="10" viewBox="7.59 6.29 7.12 11.41">
                <path
                  fill="#ffffff"
                  d="M13.293 6.293 7.586 12l5.707 5.707 1.414-1.414L10.414 12l4.293-4.293z"
                ></path>
              </svg>
            </div>
            <div className="pagi-count">
              {_currrentPage} of {numPages}
            </div>
            <div className={`pagi-right ${right_active}`} onClick={nextPage}>
              <svg width="10" viewBox="9.29 6.29 7.12 11.41">
                <path
                  fill="#ffffff"
                  d="M10.707 17.707 16.414 12l-5.707-5.707-1.414 1.414L13.586 12l-4.293 4.293z"
                ></path>
              </svg>
            </div>
          </div>
        </>
      );
    }
  };

  return <>{pagingContainer}</>;
}

export default Pagination_custom;
