/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import SidebarSearch from "./sidebar/sidebarSearch";
// eslint-disable-next-line import/namespace, import/default
import SidebarGroupMessage from "./sidebar/sidebarGroupMessage";
import SidebarDirectMessage from "./sidebar/sidebarDirectMessage";
import { getAuthSession } from "./Helpers/common";
import { _getEventList } from "../SocketIo";
import { setWelcomeBoard } from "./redux/authUserDetail";
import UserSetting from "./chatboard/UserSetting";
import {
  setInBoundCallPopUpVisibility,
  setOutBoundCallPopUpVisibility,
} from "./redux/callSlice";
import ScheduleCallList from "./models/ScheduleCallList";
import { globalStrings } from "./util/translation/languages";
import { joinScheduleCall, startScheduledCall } from "./Helpers/CallCommon";

const Sidebar = ({ isOpenSidebar }) => {
  const dispatch = useDispatch();
  const companyDetail = useSelector(
    (state) => state.authUserDetail.companyDetail,
  );
  const sessionThemeData = useSelector(
    (state) => state.authUserDetail.sessionThemeData,
  );

  const outBoundCallPopUpShow = useSelector(
    (state) => state.call.outBoundCallPopUpShow,
  );

  const inBoundCallPopUpShow = useSelector(
    (state) => state.call.inBoundCallPopUpShow,
  );

  const scheduledCallList = useSelector(
    (state) => state.call.scheduledCallList,
  );

  const scheduledCallListForHost = useSelector(
    (state) => state.call.scheduledCallListForHost,
  );

  const selectedLanguage = useSelector((state) => state.chat.selectedLanguage);
  const { user_unique_key } = getAuthSession();
  const [privateTypingData, setPrivateTypingDaa] = useState([]);
  const [isOpenScheduleCall, setIsOpenScheduleCall] = useState(false);
  const [groupTypingData, setGroupTypingDaa] = useState([]);
  const [notificationData, setNotificationData] = useState({});
  const [scheduleCallType, setScheduleCallType] = useState("");

  let SidebarGroupMessageProps = {
    groupTypingData,
    notificationData,
  };

  let sidebarDirectMessageProps = {
    privateTypingData,
  };

  useEffect(() => {
    _getEventList(
      user_unique_key,
      setPrivateTypingDaa,
      setGroupTypingDaa,
      setNotificationData,
    );
  }, []);

  useEffect(() => {
    if (!inBoundCallPopUpShow) {
      dispatch(setInBoundCallPopUpVisibility(false));
    }
  }, [inBoundCallPopUpShow]);
  useEffect(() => {
    if (!outBoundCallPopUpShow) {
      dispatch(setOutBoundCallPopUpVisibility(false));
    }
  }, [outBoundCallPopUpShow]);

  const WelcomePage = () => {
    dispatch(setWelcomeBoard(false));
  };

  const handleOpen = (type) => {
    setScheduleCallType(type);
    setIsOpenScheduleCall(true);
  };

  const closeModal = () => {
    setIsOpenScheduleCall(false);
    setScheduleCallType("");
  };
  return (
    <>
      <div
        className={`left_s ${isOpenSidebar ? "open" : ""}   ${
          scheduledCallList && scheduledCallList.length > 0
            ? "schedule-call"
            : ""
        }`}
      >
        <div className="p-2 h-100">
          <div
            className="company-logo-container cursor-pointer"
            onClick={WelcomePage}
            onKeyDown={WelcomePage}
            role="presentation"
          >
            <img
              src={`${companyDetail?.company_logo}`}
              alt="Logo"
              className="company-logo"
            />
            <UserSetting selectedLanguage={selectedLanguage} />
          </div>

          <SidebarSearch />
          {scheduledCallListForHost && scheduledCallListForHost.length > 0 && (
            <button
              className="btn btn-green w-100 mt-2"
              onClick={() =>
                scheduledCallListForHost.length > 1
                  ? handleOpen("admin")
                  : startScheduledCall(scheduledCallListForHost[0])
              }
            >
              {globalStrings[selectedLanguage].$startScheduleCall}
            </button>
          )}
          {scheduledCallList && scheduledCallList.length > 0 && (
            <button
              className="btn btn-green w-100 mt-2"
              onClick={() =>
                scheduledCallList.length > 1
                  ? handleOpen("user")
                  : joinScheduleCall(scheduledCallList[0])
              }
            >
              {globalStrings[selectedLanguage].$joinScheduleCall}
            </button>
          )}

          <div className="chat_list mt-2">
            <SidebarGroupMessage {...SidebarGroupMessageProps} />
            <SidebarDirectMessage {...sidebarDirectMessageProps} />
          </div>
          <div className="company-logo-container-btm bg-light border-radius mt-2 cursor-pointer d-flex">
            {sessionThemeData ? (
              <img
                src="../assets/images/c2connect-logo-theme-dark.svg"
                alt="Logo"
                className="company-logo max-w-130"
              />
            ) : (
              <img
                src="../assets/images/c2connect-logo-theme-light.svg"
                alt="Logo"
                className="company-logo max-w-130"
              />
            )}
          </div>
        </div>
      </div>
      {isOpenScheduleCall && (
        <ScheduleCallList
          closeModal={closeModal}
          scheduleCallType={scheduleCallType}
        />
      )}
    </>
  );
};
export default Sidebar;
