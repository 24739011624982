/* eslint-disable no-unused-vars */
import {
  _exists,
  _push,
  _readOnChange,
  _delete,
  _update,
} from "./firebase/database";

import { elementToSVG } from "dom-to-svg";
import { Buffer } from "buffer";
import { addWhiteBoardElemets } from "../../SocketIo";
import { getMyUserId } from "../Helpers/common";
import moment from "moment";
import { uuidv4 } from "./CanvasFunction";
var roomName = "c2-perform";

(function (scope) {
  var ALIAS_OFFSET = 0.5;
  var LAYOUT_OFFSET = 2;
  var ROTATION_OFFSET = 25;
  var ShapeType = {
    RECTANGLE: "RECTANGLE",
    ARROW: "ARROW",
    FREEHAND: "FREEHAND",
    ELLIPSE: "ELLIPSE",
    TRIANGLE: "TRIANGLE",
    POLYGON: "POLYGON",
    RA_TRIANGLE: "RA_TRIANGLE",
    SOLID_LINE: "SOLID_LINE",
    DOTTED_LINE: "DOTTED_LINE",
    ERASER: "ERASER",
    TEXT: "TEXT",
    IMAGE: "IMAGE",
    PROTRACTOR: "PROTRACTOR",
  };
  var CURSORS = [
    "default",
    "w-resize",
    "n-resize",
    "move",
    "col-resize",
    "row-resize",
    "nw-resize",
    "ne-resize",
  ];
  var TEXT_OFFSET = 5;
  var ERASER_WIDTH = 25;
  var ERASER_OFFSET = 12.5;
  var timeout = 0;
  var TAU = 2 * Math.PI;
  class Point {
    constructor(x, y) {
      this.x = x;
      this.y = y;
    }
    clone() {
      return new Point(this.x, this.y);
    }
  }

  class Rect {
    constructor(x, y, width, height) {
      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;
    }
  }

  class Shape {
    constructor(type, color, points, text) {
      this.type = type;
      this.color = color;
      this.points = points || [];
      this.text = text || "";
      this.textAddedFlag = false;
    }
  }

  function Transform() {
    this.reset();
  }

  Transform.prototype.reset = function () {
    this.m = [1, 0, 0, 1, 0, 0];
  };

  Transform.prototype.multiply = function (matrix) {
    var m11 = this.m[0] * matrix.m[0] + this.m[2] * matrix.m[1];
    var m12 = this.m[1] * matrix.m[0] + this.m[3] * matrix.m[1];

    var m21 = this.m[0] * matrix.m[2] + this.m[2] * matrix.m[3];
    var m22 = this.m[1] * matrix.m[2] + this.m[3] * matrix.m[3];

    var dx = this.m[0] * matrix.m[4] + this.m[2] * matrix.m[5] + this.m[4];
    var dy = this.m[1] * matrix.m[4] + this.m[3] * matrix.m[5] + this.m[5];

    this.m[0] = m11;
    this.m[1] = m12;
    this.m[2] = m21;
    this.m[3] = m22;
    this.m[4] = dx;
    this.m[5] = dy;
  };

  Transform.prototype.invert = function () {
    var d = 1 / (this.m[0] * this.m[3] - this.m[1] * this.m[2]);
    var m0 = this.m[3] * d;
    var m1 = -this.m[1] * d;
    var m2 = -this.m[2] * d;
    var m3 = this.m[0] * d;
    var m4 = d * (this.m[2] * this.m[5] - this.m[3] * this.m[4]);
    var m5 = d * (this.m[1] * this.m[4] - this.m[0] * this.m[5]);
    this.m[0] = m0;
    this.m[1] = m1;
    this.m[2] = m2;
    this.m[3] = m3;
    this.m[4] = m4;
    this.m[5] = m5;
  };

  Transform.prototype.rotate = function (rad) {
    var c = Math.cos(rad);
    var s = Math.sin(rad);
    var m11 = this.m[0] * c + this.m[2] * s;
    var m12 = this.m[1] * c + this.m[3] * s;
    var m21 = this.m[0] * -s + this.m[2] * c;
    var m22 = this.m[1] * -s + this.m[3] * c;
    this.m[0] = m11;
    this.m[1] = m12;
    this.m[2] = m21;
    this.m[3] = m22;
  };

  Transform.prototype.translate = function (x, y) {
    this.m[4] += this.m[0] * x + this.m[2] * y;
    this.m[5] += this.m[1] * x + this.m[3] * y;
  };

  Transform.prototype.scale = function (sx, sy) {
    this.m[0] *= sx;
    this.m[1] *= sx;
    this.m[2] *= sy;
    this.m[3] *= sy;
  };

  Transform.prototype.transformPoint = function (px, py) {
    var x = px;
    var y = py;
    px = x * this.m[0] + y * this.m[2] + this.m[4];
    py = x * this.m[1] + y * this.m[3] + this.m[5];
    return [px, py];
  };

  class WB {
    constructor(canvas, afterShapeAdd, constraints) {
      this.canvasID = canvas;
      if (typeof canvas === "string") {
        canvas = document.getElementById(canvas);
      }
      // this.textEnabled = constraints.textEnabled || false;
      this.canvas = canvas || document.createElement("canvas");
      this.ctx = this.canvas.getContext("2d");
      this.lineWidth = 1;
      this.shapes = [];
      this.undoShapes = [];
      this.activeShape = null;
      this.events = {};
      this.docEvents = {}; // to be continued
      this.clientRect = null;
      this.prevData = null;
      this.prevDim = null;
      this.currentShapeType = null;
      this.currentColor = "#000000";
      this.eraserColor = "rgba(255,255,255,100)";
      this.afterShapeAdd = afterShapeAdd;
      this.defaultDraw = constraints.defaultDraw && true;
      this.touchEnabled = constraints.touchEnabled || false;
      this.devicePixelRatio = 1;
      this.compensateForBorder = constraints.compensateForBorder || 0;
      this.uuid = "harshit";
      this.textarea = false;
      this.textStyle = {};
      // Image vars
      this.imageAvail = false;
      this.draggingResizer = { x: 0, y: 0 };
      this.draggingImage = false;
      this.locked = false;
      this.serverCred = {};
      this.init();
      this.protractorImg = new Image();
      this.protractorImg.src = "images/whiteboard/protractor.png";
      this.rotationImage = new Image();
      this.rotationImage.src = "../../assets/images/rotate.png";
      // FOR EDITING PART
      this.canvasEditMode = false;
      this.figures = {};
      this.activeFigure = {};
      this.editableFigure = {};
      this.editMode = false;
      this.prevImageData = null;
      this.resizeDirection = null;
      this.editInitPoints = null;
      this.rotationCred = null;
      this.imageDrawing = null;
      this.edRotationalCred = {};
      this.scale = 1;
      this.panning = false; // to move whole canvas
      this.panCred = {};
      this.panningOffset = { x: 0, y: 0 };
    }
  }

  var proto = WB.prototype;

  proto.changeMode = function (_mode) {
    this.canvasEditMode = _mode;
    this.prevImageData = null;
    this.AttachDetachElements();
  };

  proto.setScale = function (scale) {
    this.scale = scale;
    scope.scale = scale;
  };

  proto.setPanning = function (_response) {
    this.panning = _response ? true : false;
    if (_response) {
      this.canvas.style.cursor = "grab";
      this.unRegisterEvent(this.canvas, "mouseup");
      this.unRegisterEvent(document, "mouseup", "document");
      this.unRegisterEvent(this.canvas, "mousemove"); //MOBILE CONDITION REQUIRED
      this.unRegisterEvent(this.canvas, "mousedown"); //MOBILE CONDITION REQUIRED
      // FOR panning events
      this.registerEvent(this.canvas, "mousedown", this.panSelect);
      this.registerEvent(this.canvas, "mouseup", this.panUp);
      this.registerEvent(document, "mouseup", this.panUp, "document");
    } else {
      this.canvas.style.cursor = null;
      this.unRegisterEvent(document, "mouseup", "document");
      this.unRegisterEvent(this.canvas, "mouseup");
      this.unRegisterEvent(this.canvas, "mousedown");
      this.AttachDetachElements(false);
    }
  };

  proto.panSelect = function (e) {
    this.canvas.style.cursor = "grabbing";
    let x = e.offsetX;
    let y = e.offsetY;
    _exists(`${roomName}/${this.uuid}/panning`).then((_value) => {
      if (_value.x && _value.y) {
        let _xP = Math.ceil(_value.x * this.canvas.width);
        let _yP = Math.ceil(_value.y * this.canvas.height);
        this.panCred = { x, y, _xP, _yP };
      } else {
        this.panCred = { x, y, _xP: 0, _yP: 0 };
      }
      // this.registerEvent(this.canvas, "mousemove", this.panMove);
    });
  };

  proto.panMove = function (e) {
    let movedX = 0;
    let movedY = 0;
    let x = e.offsetX;
    let y = e.offsetY;
    if (!this.panCred?.x) {
      movedX = 0;
      movedY = 0;
      this.panCred = { x, y, _xP: 0, _yP: 0 };
    } else {
      movedX = x - this.panCred.x + (this.panCred._xP || 0);
      movedY = y - this.panCred.y + (this.panCred._yP || 0);
    }
    let _xP = movedX / this.canvas.width;
    let _yP = movedY / this.canvas.width;
    // _update(`${roomName}/${this.uuid}/panning`, {
    //   x: _xP,
    //   y: _yP,
    // });
  };

  proto.panUp = function (e) {
    this.canvas.style.cursor = "grab";
    this.panCred = {};
    this.unRegisterEvent(this.canvas, "mousemove");
  };

  proto.AttachDetachElements = function (rewind = true) {
    if (rewind) {
      this.prevImageData
        ? this.ctx.putImageData(this.prevImageData, 0, 0)
        : null;
    }
    this.prevImageData = null;
    this.activeFigure = {};
    this.editableFigure = {};
    scope._activeKey = null;
    this.editMode = false;
    this.resizeDirection = null;
    this.editInitPoints = null;
    if (this.canvasEditMode) {
      this.detachEvents();
      this.attachEditableElements();
    } else {
      this.detachEditableElements();
      this.attachEvents();
    }
  };

  proto.attachEditableElements = function () {
    // this.registerEvent(document, "keydown", this.deleteElement, "document");
    this.registerEvent(this.canvas, "mousemove", this.hitImage); //MOBILE CONDITION REQUIRED
    this.registerEvent(this.canvas, "mousedown", this.mouseClickEditor);
    this.registerEvent(document, "mouseup", this.mouseUpEditor, "document");
  };

  proto.detachEditableElements = function () {
    this.unRegisterEvent(document, "keydown", "document");
    this.unRegisterEvent(this.canvas, "mousemove"); //MOBILE CONDITION REQUIRED
    this.unRegisterEvent(this.canvas, "mousedown"); //MOBILE CONDITION REQUIRED
    this.unRegisterEvent(document, "mouseup", "document"); //MOBILE CONDITION REQUIRED
  };

  proto.deleteElement = function (e) {
    if (
      e.code === "Delete" &&
      this.editableFigure &&
      Object.keys(this.editableFigure).length
    ) {
      let key = Object.keys(this.editableFigure)[0];
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      _delete(`${roomName}/${this.uuid}/Drawing/${key}`);
      this.editableFigure = {};
      scope._activeKey = null;
      this.editMode = false;
      this.prevImageData = null;
      this.resizeDirection = null;
      this.editInitPoints = null;
      this.rotationCred = null;
    }
  };

  proto.mouseClickEditor = function (e) {
    let x = e.offsetX;
    let y = e.offsetY;
    if (typeof this.panningOffset?.x === "number") {
      x -= this.panningOffset?.x || 0;
      y -= this.panningOffset?.y || 0;
    }
    this.unRegisterEvent(this.canvas, "mousemove");
    if (this.textarea && this.canvasEditMode === true) {
      this.textarea = false;
      let elementData = Object.values(this.editableFigure)[0];
      let _panningWidth = this.panningOffset.x || 0;
      let _panningHeight = this.panningOffset.y || 0;
      this.textStyle = {
        x: elementData.points.x * this.canvas.width + _panningWidth,
        y: elementData.points.y * this.canvas.height + _panningHeight,
      };
      this.createTextArea(e);
      return;
    }
    if (Object.keys(this.activeFigure || {}).length === 1) {
      if (
        Object.keys(this.editableFigure || {})?.[0] !=
        Object.keys(this.activeFigure)[0]
      ) {
        this.prevImageData
          ? this.ctx.putImageData(this.prevImageData, 0, 0)
          : null;
        this.editMode = true;
        this.editableFigure = { ...this.activeFigure };
        this.canvas.style.cursor = "move";
        this.prevImageData = this.ctx.getImageData(
          0,
          0,
          this.canvas.width,
          this.canvas.height,
        );
        this.rotationCred = null;
        this.DrawEditableLayout(
          null,
          null,
          Object.values(this.editableFigure)[0].angle || null,
        );
        scope._activeKey = Object.keys(this.editableFigure)[0];
      } else if (
        Object.values(this.editableFigure)?.[0]?.type === "EDITABLE_DIV" &&
        this.resizeDirection === "text"
      ) {
        this.prevImageData
          ? this.ctx.putImageData(this.prevImageData, 0, 0)
          : null;
        this.prevImageData = null;
        let elementData = Object.values(this.editableFigure)[0];
        let key = Object.keys(this.editableFigure)[0];
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        // _delete(`${roomName}/${this.uuid}/Drawing/${key}`);
        let element = document.getElementById("canvas-editable-id");
        let _panningWidth = this.panningOffset.x || 0;
        let _panningHeight = this.panningOffset.y || 0;
        let style = `top:${
          elementData.points.y * this.canvas.height + _panningHeight
        };left:${
          elementData.points.x * this.canvas.width + _panningWidth
        };position:absolute;border:1.5px dotted #7e7e9d; white-space:pre; min-width:150px; min-height:50px`;
        element.style.cssText = style;
        this.textarea = true;
        if (element.classList.value.includes("hide")) {
          element.classList.remove("hide");
        }
        element.innerHTML = elementData.html;
      } else {
        let x = e.offsetX;
        let y = e.offsetY;
        this.editInitPoints = { x, y };
        this.registerEvent(this.canvas, "mousemove", this.figureMoveHandler);
        return;
      }
    } else if (
      this.rotationCred &&
      this.rotationCred.x &&
      this.rotationCred.x > x - 12 &&
      this.rotationCred.x < x + 12 &&
      this.rotationCred.y > y - 12 &&
      this.rotationCred.y < y + 12
    ) {
      // let x = e.offsetX;
      // let y = e.offsetY;
      this.editInitPoints = { x, y };
      return this.registerEvent(
        this.canvas,
        "mousemove",
        this.figureRotateHandler,
      );
    } else {
      this.prevImageData
        ? this.ctx.putImageData(this.prevImageData, 0, 0)
        : null;
      this.editMode = false;
      this.editableFigure = {};
      this.canvas.style.cursor = "default";
      this.prevImageData = null;
      this.rotationCred = null;
      scope._activeKey = null;
    }
    this.editInitPoints = null;
    this.registerEvent(this.canvas, "mousemove", this.hitImage);
  };

  proto.mouseUpEditor = function () {
    this.unRegisterEvent(this.canvas, "mousemove");

    // this.registerEvent(this.canvas, "mousemove", this.hitImage);
  };

  proto.setWidthHeight = function (_w, _h) {
    return { width: _w / this.canvas.width, height: _h / this.canvas.height };
  };

  proto.figureRotateHandler = function (e) {
    let x = e.offsetX;
    let y = e.offsetY;
    if (typeof this.panningOffset?.x === "number") {
      x -= this.panningOffset?.x || 0;
      y -= this.panningOffset?.y || 0;
    }
    const bounds = this.canvas.getBoundingClientRect();
    let mouseX = e.pageX - bounds.left - scope.scrollX;
    let mouseY = e.pageY - bounds.top - scope.scrollY;
    let angle;
    let lastArr = { ...Object.values(this.editableFigure)[0] };
    let Cred1 = this.getWidthHeight(lastArr.points[0].x, lastArr.points[0].y);
    let Cred2 = this.getWidthHeight(lastArr.points[1].x, lastArr.points[1].y);
    if (this.editInitPoints.x) {
      angle = Math.atan2(
        mouseY - this.editInitPoints.y,
        mouseX - this.editInitPoints.x,
      );
    } else {
      this.editInitPoints = { x, y };
    }
    if (angle) {
      // _update(
      //   `${roomName}/${this.uuid}/Drawing/${
      //     Object.keys(this.editableFigure)[0]
      //   }/angle`,
      //   angle,
      // );
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        this.editableFigure[Object.keys(this.editableFigure)[0]]["angle"] =
          angle;
        this.prevImageData = this.ctx.getImageData(
          0,
          0,
          this.canvas.width,
          this.canvas.height,
        );
        this.DrawEditableLayout(lastArr.type, [Cred1, Cred2], angle);
      }, 200);
    }
  };
  proto.figureMoveHandler = function (e) {
    let movedX = 0;
    let movedY = 0;
    let Cred1 = {};
    let Cred2 = {};
    let lastArr = { ...Object.values(this.editableFigure)[0] };
    let x = e.offsetX;
    let y = e.offsetY;
    if (!this.editInitPoints?.x) {
      movedX = 0;
      movedY = 0;
      this.editInitPoints = { x, y };
    } else {
      movedX = x - this.editInitPoints.x;
      movedY = y - this.editInitPoints.y;
    }
    if (lastArr.type !== "EDITABLE_DIV") {
      Cred1 = this.getWidthHeight(lastArr.points[0].x, lastArr.points[0].y);
      Cred2 = this.getWidthHeight(lastArr.points[1].x, lastArr.points[1].y);
    } else {
      Cred1 = this.getWidthHeight(lastArr.points.x, lastArr.points.y);
      Cred2 = this.getWidthHeight(
        lastArr.points.iw + lastArr.points.x,
        lastArr.points.ih + lastArr.points.y,
      );
    }

    let Cred01 = {};
    let Cred02 = {};
    let FinalCred01 = {};
    let FinalCred02 = {};
    if (this.resizeDirection === "text") return null;
    if (this.resizeDirection && this.resizeDirection !== "move") {
      // switch (this.resizeDirection) {
      //   case "upper-left":
      //     let upperL0 = this.setWidthHeight(
      //       Cred1.width + movedX,
      //       Cred1.height + movedY,
      //     );
      //     let upperL1 = this.setWidthHeight(Cred2.width, Cred2.height);
      //     Cred01 = { x: upperL0.width, y: upperL0.height };
      //     Cred02 = { x: upperL1.width, y: upperL1.height };
      //     break;
      //   case "upper-right":
      //     let upperR0 = this.setWidthHeight(Cred1.width, Cred1.height + movedY);
      //     let upperR1 = this.setWidthHeight(Cred2.width + movedX, Cred2.height);
      //     Cred01 = { x: upperR0.width, y: upperR0.height };
      //     Cred02 = { x: upperR1.width, y: upperR1.height };
      //     break;
      //   case "lower-left":
      //     let lowerL0 = this.setWidthHeight(Cred1.width + movedX, Cred1.height);
      //     let lowerL1 = this.setWidthHeight(Cred2.width, Cred2.height + movedY);
      //     Cred01 = { x: lowerL0.width, y: lowerL0.height };
      //     Cred02 = { x: lowerL1.width, y: lowerL1.height };
      //     break;
      //   default:
      //     let lowerR0 = this.setWidthHeight(Cred1.width, Cred1.height);
      //     let lowerR1 = this.setWidthHeight(
      //       Cred2.width + movedX,
      //       Cred2.height + movedY,
      //     );
      //     Cred01 = { x: lowerR0.width, y: lowerR0.height };
      //     Cred02 = { x: lowerR1.width, y: lowerR1.height };
      //     break;
      // }
    } else {
      // let org0 = this.setWidthHeight(
      //   Cred1.width + movedX,
      //   Cred1.height + movedY,
      // );
      // let org1 = this.setWidthHeight(
      //   Cred2.width + movedX,
      //   Cred2.height + movedY,
      // );
      // Cred01 = { x: org0.width, y: org0.height };
      // Cred02 = { x: org1.width, y: org1.height };
    }
    this.prevImageData = null;
    if (lastArr.type !== "EDITABLE_DIV") {
      FinalCred01 = {
        x: Cred01.x > Cred02.x ? Cred02.x : Cred01.x,
        y: Cred01.y > Cred02.y ? Cred02.y : Cred01.y,
      };
      FinalCred02 = {
        x: Cred01.x > Cred02.x ? Cred01.x : Cred02.x,
        y: Cred01.y > Cred02.y ? Cred01.y : Cred02.y,
      };
      // _update(
      //   `${roomName}/${this.uuid}/Drawing/${
      //     Object.keys(this.editableFigure)[0]
      //   }/points`,
      //   [FinalCred01, FinalCred02],
      // );
    } else {
      FinalCred01 = { x: Cred01.x, y: Cred01.y };
      FinalCred02 = {
        iw: Cred02.x - FinalCred01.x,
        ih: Cred02.y - FinalCred01.y,
      };
      // _update(
      //   `${roomName}/${this.uuid}/Drawing/${
      //     Object.keys(this.editableFigure)[0]
      //   }/points`,
      //   { ...FinalCred01, ...FinalCred02 },
      // );
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      this.editInitPoints = null;
      let tmpeditableFigure = {
        ...this.editableFigure[Object.keys(this.editableFigure)[0]],
      };
      tmpeditableFigure["points"] =
        lastArr.type !== "EDITABLE_DIV"
          ? [FinalCred01, FinalCred02]
          : { ...FinalCred01, ...FinalCred02 };
      this.editableFigure[Object.keys(this.editableFigure)[0]] =
        tmpeditableFigure;
      // this.editableFigure[Object.keys(this.editableFigure)[0]].points =
      //   lastArr.type !== "EDITABLE_DIV"
      //     ? [FinalCred01, FinalCred02]
      //     : { ...FinalCred01, ...FinalCred02 };

      this.prevImageData = this.ctx.getImageData(
        0,
        0,
        this.canvas.width,
        this.canvas.height,
      );
      this.DrawEditableLayout(
        lastArr.type,
        lastArr.type !== "EDITABLE_DIV"
          ? [FinalCred01, FinalCred02]
          : { ...FinalCred01, ...FinalCred02 },
        lastArr.angle || null,
      );
    }, 200);
  };

  proto.getElement = function () {
    return this.canvas;
  };
  proto.CreatePoints = function (obj) {
    return new Point(obj.x, obj.y);
  };
  proto.getContext = function () {
    return this.ctx;
  };

  proto.init = function (e) {
    this.initSize();
    this.attachEvents();
    // this.figureUpdate();
  };

  proto.updateCred = function (_cred) {
    this.serverCred = _cred;
  };

  proto.initSize = function () {
    if (devicePixelRatio !== 1) {
      this.devicePixelRatio = 1;
    }
  };

  proto.setShapeType = function (shapeType) {
    this.currentShapeType = shapeType;
    if (this.textarea) {
      this.textarea = false;
      this.createTextArea();
    }
  };

  proto.setUUID = function (id) {
    this.uuid = id;
  };

  proto.setColor = function (color) {
    this.currentColor = color;
  };

  proto.setEraserColor = function (color) {
    this.eraserColor = color;
  };

  proto.setImage = function (_data) {
    var $this = this;
    let img = new Image();
    img.onload = function () {
      let width =
        $this.canvas.width > this.width ? this.width : $this.canvas.width;
      let height =
        $this.canvas.height > this.height ? this.height : $this.canvas.height;
      $this.ctx.drawImage(img, 0, 0, width, height);
      let _ref = `${roomName}/${$this.uuid}/Drawing`;
      let shapes = {};
      shapes.points = [
        { x: 0, y: 0 },
        { x: width / $this.canvas.width, y: height / $this.canvas.height },
      ];
      shapes.source = getMyUserId();
      shapes.type = "IMAGE";
      shapes.img = _data;
      const utcTimestamp = moment().utc().valueOf();
      shapes.timestamp = utcTimestamp;
      shapes.uuid = uuidv4();
      shapes.scale = this.scale || 1;
      scope.lastFigure = shapes.uuid;
      //_push(_ref, shapes);
      addWhiteBoardElemets(shapes);
    };
    img.src = _data;
  };

  proto.lockScreen = function (action) {
    this.locked = action;
    if (action) {
      this.canvas.style.cursor = "no-drop";
    } else {
      this.canvas.style.cursor = "";
    }
  };

  proto.setTextStyle = function (style) {
    if (typeof style !== "object") return;
    this.textStyle =
      typeof this.textStyle === "object"
        ? { ...this.textStyle, ...style }
        : style;
    styleChanger(this.textStyle);
  };

  proto.figureUpdate = function () {
    let figureRef = `${roomName}/${this.canvasID}`;
    _exists(figureRef, false, (_val) => {
      this.updateFigures.bind(this);
      this.updateFigures(_val?.Drawing || {});
      if (typeof _val.panning?.x === "number") {
        let _xOff = _val.panning.x * this.canvas.width;
        let _yOff = _val.panning.y * this.canvas.height;
        this.panningOffset = { x: _xOff, y: _yOff };
      }
    });
    _readOnChange(figureRef, (_val) => {
      this.updateFigures.bind(this);
      this.updateFigures(_val?.Drawing || {});
      if (typeof _val.panning?.x === "number") {
        let _xOff = _val.panning.x * this.canvas.width;
        let _yOff = _val.panning.y * this.canvas.height;
        this.panningOffset = { x: _xOff, y: _yOff };
      }
    });
  };

  proto.updateFigures = function (_data) {
    this.figures = {};
    if (_data && Object.keys(_data).length) {
      for (let i = Object.keys(_data).length - 1; i >= 0; i--) {
        if (Object.values(_data)[i].type === "ERASER") break;
        this.figures = {
          ...this.figures,
          [Object.keys(_data)[i]]: Object.values(_data)[i],
        };
      }
    }
  };

  proto.getWidthHeight = function (_w, _h) {
    return {
      width: Math.ceil(_w * this.canvas.width),
      height: Math.ceil(_h * this.canvas.height),
    };
  };

  proto.attachEvents = function () {
    if (this.defaultDraw) {
      this.registerEvent(this.canvas, "mousedown", this.onMouseDown);
    } else {
      console.log("Drawing disabled on this device!");
    }
  };

  proto.detachEvents = function () {
    this.unRegisterEvent(this.canvas, "mousedown");
    if (this.touchEnabled) {
      this.unRegisterEvent(this.canvas, "touchstart");
    }
  };

  proto.clear = function () {
    this.shapes = this.undoShapes = [];
    this.drawShapes();
  };

  proto.registerEvent = function (
    element,
    eventType,
    eventHandler,
    type = "canvas",
  ) {
    if (type === "canvas") {
      (window.addEventListener || window.attachEvent).call(
        element,
        eventType,
        (this.events[eventType] = eventHandler.bind(this)),
      );
    } else {
      (window.addEventListener || window.attachEvent).call(
        element,
        eventType,
        (this.docEvents[eventType] = eventHandler.bind(this)),
      );
    }
  };

  proto.unRegisterEvent = function (element, eventType, type = "canvas") {
    if (type === "canvas") {
      (window.removeEventListener || window.detachEvent).call(
        element,
        eventType,
        this.events[eventType],
      );
      delete this.events[eventType];
    } else {
      (window.removeEventListener || window.detachEvent).call(
        element,
        eventType,
        this.docEvents[eventType],
      );
      delete this.docEvents[eventType];
    }
  };

  proto.hitImage = function (e) {
    if (this.panning) return false;
    let x = e.offsetX;
    let y = e.offsetY;
    let _found = false;
    if (typeof this.panningOffset?.x === "number") {
      x -= this.panningOffset.x;
      y -= this.panningOffset.y;
    }
    this.activeFigure = {};
    for (let i = 0; i < Object.keys(this.figures).length; i++) {
      if (Object.values(this.figures)[i]?.type !== "FREEHAND") {
        let startX, startY, widthX, widthY;
        let {
          points,
          type,
          angle,
          scale: org_scale,
        } = Object.values(this.figures)[i];
        if (org_scale != scope.scale) {
          _found = false;
        } else {
          if (type === "EDITABLE_DIV") {
            startX = Math.ceil(points.x * this.canvas.width);
            startY = Math.ceil(points.y * this.canvas.height);
            widthX = Math.ceil(points.iw * this.canvas.width);
            widthY = Math.ceil(points.ih * this.canvas.height);
            _found =
              x > startX &&
              x < startX + widthX &&
              y > startY &&
              y < startY + widthY;
          } else {
            startX = Math.ceil(points[0].x * this.canvas.width);
            startY = Math.ceil(points[0].y * this.canvas.height);
            widthX = Math.ceil(points[1].x * this.canvas.width) - startX;
            widthY = Math.ceil(points[1].y * this.canvas.height) - startY;
            if (angle) {
              let originX = startX + widthX / 2,
                originY = startY + widthY / 2;
              let dx = x - originX,
                dy = y - originY;
              // distance between the point and the center of the rectangle
              let h1 = Math.sqrt(dx * dx + dy * dy);
              let currA = Math.atan2(dy, dx);
              // Angle of point rotated around origin of rectangle in opposition
              let newA = currA - angle;
              // New position of mouse point when rotated
              let x2 = Math.cos(newA) * h1;
              let y2 = Math.sin(newA) * h1;
              if (
                x2 > -0.5 * widthX &&
                x2 < 0.5 * widthX &&
                y2 > -0.5 * widthY &&
                y2 < 0.5 * widthY
              ) {
                _found = true;
              }
            } else {
              _found =
                x > startX &&
                x < startX + widthX &&
                y > startY &&
                y < startY + widthY;
            }
          }
        }

        if (_found === true) {
          this.activeFigure = {
            [Object.keys(this.figures)[i]]: Object.values(this.figures)[i],
          };
          break;
        }
      }
    }
    if (_found) {
      if (
        Object.keys(this.activeFigure).length &&
        Object.keys(this.editableFigure).length &&
        Object.keys(this.activeFigure)[0] ===
          Object.keys(this.editableFigure)[0]
      ) {
        let { points, angle, type } = Object.values(this.editableFigure)[0];
        if (angle && Object.keys(this.edRotationalCred).length) {
          let { rtd1, rtd2, rtd3, rtd4 } = this.edRotationalCred;

          let cursor_type = "move";
          this.resizeDirection = "move";
          if (
            Math.abs(x - Math.ceil(rtd1[0])) <= 3.5 * LAYOUT_OFFSET &&
            Math.abs(y - Math.ceil(rtd1[1])) <= 3.5 * LAYOUT_OFFSET
          ) {
            this.resizeDirection = "upper-left";
            cursor_type = "nw-resize";
          } else if (
            Math.abs(x - Math.ceil(rtd2[0])) <= 3.5 * LAYOUT_OFFSET &&
            Math.abs(y - Math.ceil(rtd2[1])) <= 3.5 * LAYOUT_OFFSET
          ) {
            this.resizeDirection = "upper-right";
            cursor_type = "ne-resize";
          } else if (
            Math.abs(x - Math.ceil(rtd3[0])) <= 3.5 * LAYOUT_OFFSET &&
            Math.abs(y - Math.ceil(rtd3[1])) <= 3.5 * LAYOUT_OFFSET
          ) {
            this.resizeDirection = "lower-right";
            cursor_type = "nw-resize";
          } else if (
            Math.abs(x - Math.ceil(rtd4[0])) <= 3.5 * LAYOUT_OFFSET &&
            Math.abs(y - Math.ceil(rtd4[1])) <= 3.5 * LAYOUT_OFFSET
          ) {
            this.resizeDirection = "lower-left";
            cursor_type = "ne-resize";
          }
          this.canvas.style.cursor = cursor_type;
          return;
        }
        if (type !== "EDITABLE_DIV") {
          let x1 = Math.ceil(points[0].x * this.canvas.width);
          let x2 = Math.ceil(points[1].x * this.canvas.width);
          let y1 = Math.ceil(points[0].y * this.canvas.height);
          let y2 = Math.ceil(points[1].y * this.canvas.height);
          let cursor_type = "move";
          this.resizeDirection = "move";
          if (
            (x - x1 || x1 - x) <= 3.5 * LAYOUT_OFFSET &&
            (y - y1 || y1 - y) <= 3.5 * LAYOUT_OFFSET
          ) {
            this.resizeDirection = "upper-left";
            cursor_type = "nw-resize";
          } else if (
            (x2 - x || x - x2) <= 3.5 * LAYOUT_OFFSET &&
            (y - y1 || y1 - y) <= 3.5 * LAYOUT_OFFSET
          ) {
            this.resizeDirection = "upper-right";
            cursor_type = "ne-resize";
          } else if (
            (x2 - x || x - x2) <= 3.5 * LAYOUT_OFFSET &&
            (y2 - y || y - y2) <= 3.5 * LAYOUT_OFFSET
          ) {
            this.resizeDirection = "lower-right";
            cursor_type = "nw-resize";
          } else if (
            (x - x1 || x1 - x) <= 3.5 * LAYOUT_OFFSET &&
            (y2 - y || y - y2) <= 3.5 * LAYOUT_OFFSET
          ) {
            this.resizeDirection = "lower-left";
            cursor_type = "ne-resize";
          }
          this.canvas.style.cursor = cursor_type;
        } else {
          let x1 = Math.ceil(points.x * this.canvas.width);
          let x2 = Math.ceil(points.iw * this.canvas.width) + x1;
          let y1 = Math.ceil(points.y * this.canvas.height);
          let y2 = Math.ceil(points.ih * this.canvas.height) + y1;
          let cursor_type = "text";
          this.resizeDirection = "text";
          if (
            (y - y1 <= TEXT_OFFSET && x1 < x < x2) ||
            (y2 - y <= TEXT_OFFSET && x1 < x < x2)
          ) {
            cursor_type = "move";
            this.resizeDirection = "move";
          }
          this.canvas.style.cursor = cursor_type;
        }
      } else {
        this.resizeDirection = null;
        this.canvas.style.cursor = "pointer";
      }
    } else if (
      this.rotationCred &&
      this.rotationCred.x &&
      this.rotationCred.x > x - 12 &&
      this.rotationCred.x < x + 12 &&
      this.rotationCred.y > y - 12 &&
      this.rotationCred.y < y + 12
    ) {
      this.canvas.style.cursor = "alias";
      this.resizeDirection = "rotate";
    } else {
      this.resizeDirection = null;
      this.canvas.style.cursor = "default";
    }
  };

  proto.DrawEditableLayout = function (_ct = null, _cp = null, _an = null) {
    let type, points, translated, org_scale;
    if (!_ct) {
      let {
        type: _dtype,
        points: _dpoints,
        scale: org_scale_data,
      } = Object.values(this.activeFigure)[0];
      type = _dtype;
      points = _dpoints;
      org_scale = org_scale_data;
    } else {
      type = _ct;
      points = _cp;
      org_scale = scope.scale;
    }
    if (
      [
        "RECTANGLE",
        "ELLIPSE",
        "TRIANGLE",
        "POLYGON",
        "RA_TRIANGLE",
        "IMAGE",
        "PROTRACTOR",
      ].includes(type)
    ) {
      let x1 = Math.ceil(points[0].x * this.canvas.width);
      let x2 = Math.ceil(points[1].x * this.canvas.width);
      let y1 = Math.ceil(points[0].y * this.canvas.height);
      let y2 = Math.ceil(points[1].y * this.canvas.height);
      if (_an) {
        this.ctx.save();
        let _transX = (x2 - x1) / 2 + x1;
        let _transY = (y2 - y1) / 2 + y1;
        this.ctx.translate(_transX, _transY);
        this.ctx.rotate(_an);
        x1 -= _transX;
        x2 -= _transX;
        y1 -= _transY;
        y2 -= _transY;
        translated = new Transform();
        translated.translate(_transX, _transY);
        translated.rotate(_an);
      }
      this.ctx.beginPath();
      this.ctx.strokeStyle = "#007bff";
      this.ctx.lineWidth = this.lineWidth;
      this.ctx.setLineDash([5, 3]);
      this.ctx.moveTo(x1 - LAYOUT_OFFSET, y1 - LAYOUT_OFFSET);
      this.ctx.lineTo(x2 + LAYOUT_OFFSET, y1 - LAYOUT_OFFSET);
      this.ctx.lineTo(x2 + LAYOUT_OFFSET, y2 + LAYOUT_OFFSET);
      this.ctx.lineTo(x1 - LAYOUT_OFFSET, y2 + LAYOUT_OFFSET);
      this.ctx.closePath();
      this.ctx.stroke();
      this.ctx.setLineDash([]);
      let dt1 = { x: x1 - LAYOUT_OFFSET / 2, y: y1 - LAYOUT_OFFSET / 2 };
      let dt2 = { x: x2 + LAYOUT_OFFSET / 2, y: y1 - LAYOUT_OFFSET / 2 };
      let dt3 = { x: x2 + LAYOUT_OFFSET / 2, y: y2 + LAYOUT_OFFSET / 2 };
      let dt4 = { x: x1 - LAYOUT_OFFSET / 2, y: y2 + LAYOUT_OFFSET / 2 };
      let rt = { x: x1 - 30, y: y2 + 12 };
      this.AdjustingDot(this.ctx, dt1);
      this.AdjustingDot(this.ctx, dt2);
      this.AdjustingDot(this.ctx, dt3);
      this.AdjustingDot(this.ctx, dt4);
      this.RotateIcon(this.ctx, rt);
      if (_an) {
        if (translated) {
          let rtd1 = translated.transformPoint(dt1.x, dt1.y);
          let rtd2 = translated.transformPoint(dt2.x, dt2.y);
          let rtd3 = translated.transformPoint(dt3.x, dt3.y);
          let rtd4 = translated.transformPoint(dt4.x, dt4.y);
          let rtd5 = translated.transformPoint(rt.x, rt.y);
          this.rotationCred = {
            x: Math.round(rtd5[0]),
            y: Math.round(rtd5[1]),
          };
          this.edRotationalCred = { rtd1, rtd2, rtd3, rtd4 };
        }
        this.ctx.restore();
      } else {
        this.rotationCred = {
          x: x1 - ROTATION_OFFSET,
          y: y2 + ROTATION_OFFSET,
        };
      }
    } else if (type === "EDITABLE_DIV") {
      let x1 = Math.ceil(points.x * this.canvas.width);
      let x2 = Math.ceil(points.iw * this.canvas.width);
      let y1 = Math.ceil(points.y * this.canvas.height);
      let y2 = Math.ceil(points.ih * this.canvas.height);
      this.ctx.beginPath();
      this.ctx.strokeStyle = "#007bff";
      this.ctx.lineWidth = this.lineWidth;
      this.ctx.setLineDash([5, 3]);
      this.ctx.moveTo(x1 - LAYOUT_OFFSET, y1 - LAYOUT_OFFSET);
      this.ctx.lineTo(x1 + x2 + LAYOUT_OFFSET, y1 - LAYOUT_OFFSET);
      this.ctx.lineTo(x1 + x2 + LAYOUT_OFFSET, y1 + y2 + LAYOUT_OFFSET);
      this.ctx.lineTo(x1 - LAYOUT_OFFSET, y1 + y2 + LAYOUT_OFFSET);
      this.ctx.closePath();
      this.ctx.stroke();
      this.ctx.setLineDash([]);
    }
  };

  proto.AdjustingDot = function (ctx, { x, y }) {
    ctx.beginPath();
    ctx.fillStyle = "#007bff";
    ctx.fillRect(
      x - LAYOUT_OFFSET,
      y - LAYOUT_OFFSET,
      2 * LAYOUT_OFFSET,
      2 * LAYOUT_OFFSET,
    );
    ctx.fill();
    ctx.closePath();
  };

  proto.RotateIcon = function (ctx, { x, y }) {
    ctx.beginPath();
    // need to implement
    // ctx.drawImage(scope?.RotationImage(), x, y);
    ctx.closePath();
  };

  proto.onMouseDown = function (e) {
    if (this.locked) return false;

    if (this.currentShapeType && !["TEXT"].includes(this.currentShapeType)) {
      this.registerEvent(document, "mousemove", this.onMouseMove, "document");
      this.registerEvent(document, "mouseup", this.onMouseUp, "document");
      if (this.touchEnabled) {
        this.registerEvent(document, "touchmove", this.onMouseMove, "document");
        this.registerEvent(document, "touchend", this.onMouseUp, "document");
      }
      this.clientRect = this.canvas.getBoundingClientRect();
      this.shapes.push(
        (this.activeShape = new Shape(
          this.currentShapeType,
          this.currentColor,
          [this.getOffsetPoint(e), this.getOffsetPoint(e)],
        )),
      );
      this.textarea = false;
      this.imageAvail = false;
    } else if (this.currentShapeType) {
      switch (this.currentShapeType) {
        case "TEXT":
          this.textarea = !this.textarea;
          this.imageAvail = false;
          return this.createTextArea(e);
        case "PROTRACTOR":
          this.textarea = false;
          this.imageAvail = true;
          return this.drawProtractor(e);
        default:
          return null;
      }
    }
  };

  proto.onMouseMove = function (e) {
    this.processEvent(e, this.backupPixelRect.bind(this));
  };

  proto.onMouseUp = function (e) {
    var $this = this;
    $this.unRegisterEvent(document, "mousemove", "document");
    $this.unRegisterEvent(document, "mouseup", "document");

    if ($this.touchEnabled) {
      $this.unRegisterEvent(document, "touchmove", "document");
      $this.unRegisterEvent(document, "touchend", "document");
    }
    // if (roomName && getMyUserId?.()) {
    if (roomName) {
      let shapes = $this.activeShape;
      let _ref = `${roomName}/${this.uuid}/Drawing`;
      _exists(_ref).then((value) => {
        if (shapes?.points instanceof Array) {
          shapes.points.forEach((values, keys) => {
            shapes["points"][keys]["x"] = values.x / this.canvas.width;
            shapes["points"][keys]["y"] = values.y / this.canvas.height;
          });
        }
        if (shapes && shapes?.type === "ERASER") {
          shapes.eraserWidth = ERASER_WIDTH / this.canvas.width;
          shapes.offset = ERASER_OFFSET / this.canvas.width;
        }
        shapes.width = this.lineWidth;
        shapes.source = getMyUserId();
        shapes.uuid = uuidv4();
        const utcTimestamp = moment().utc().valueOf();
        shapes.timestamp = utcTimestamp;
        shapes.scale = this.scale || 1;
        scope.lastFigure = shapes.uuid;
        addWhiteBoardElemets(shapes);
        //_push(_ref, shapes);
      });
    }

    $this.processEvent(e);
    var shapeLength = $this.shapes.length;
    var lastShape = null;

    if (shapeLength > 0) {
      lastShape = $this.shapes[shapeLength - 1];
    }

    if ($this.afterShapeAdd) {
      $this.afterShapeAdd(lastShape);
    }

    $this.activeShape = null;
  };

  proto.processEvent = function (e, pixelBackupHandler) {
    if (this.activeShape != null) {
      switch (this.activeShape.type) {
        case ShapeType.RECTANGLE:
        case ShapeType.ELLIPSE:
        case ShapeType.TRIANGLE:
        case ShapeType.POLYGON:
        case ShapeType.RA_TRIANGLE:
        case ShapeType.PROTRACTOR:
        case ShapeType.IMAGE:
        case ShapeType.ARROW:
          this.activeShape.points[1] = this.getOffsetPoint(e);
          break;
        case ShapeType.SOLID_LINE:
          this.activeShape.points[1] = this.getOffsetPoint(e);
          break;
        case ShapeType.DOTTED_LINE:
          this.activeShape.points[1] = this.getOffsetPoint(e);
          break;
        case ShapeType.FREEHAND:
          this.activeShape.points.push(this.getOffsetPoint(e));
          break;
        case ShapeType.ERASER:
          this.activeShape.points.push(this.getOffsetPoint(e));
          break;
        default:
          break;
      }
      this.erasePrevShape();
      this.drawShape(this.activeShape, pixelBackupHandler);
    }
  };

  proto.drawShapes = function () {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    for (var i = 0; i < this.shapes.length; i++) {
      this.drawShape(this.shapes[i]);
    }
  };

  proto.drawShape = function (shape, pixelBackupHandler) {
    this.ctx.strokeStyle = this.ctx.fillStyle = shape.color;
    // this.lineWidth = 4;
    switch (shape.type) {
      case ShapeType.RECTANGLE:
        this.drawRectangle(shape, pixelBackupHandler);
        break;
      case ShapeType.ARROW:
        this.drawArrow(shape, pixelBackupHandler);
        break;
      case ShapeType.SOLID_LINE:
        this.drawArrow(shape, pixelBackupHandler, "solid");
        break;
      case ShapeType.DOTTED_LINE:
        this.drawArrow(shape, pixelBackupHandler, "dot");
        break;
      case ShapeType.FREEHAND:
        this.drawFreeHand(shape, pixelBackupHandler);
        break;
      case ShapeType.ELLIPSE:
        this.drawEllipse(shape, pixelBackupHandler);
        break;
      case ShapeType.TRIANGLE:
        this.drawTriangle(shape, pixelBackupHandler);
        break;
      case ShapeType.POLYGON:
        this.drawPolygon(shape, pixelBackupHandler);
        break;
      case ShapeType.RA_TRIANGLE:
        this.drawTriangle(shape, pixelBackupHandler, true);
        break;
      case ShapeType.ERASER:
        this.eraseCanvas(shape, pixelBackupHandler);
        break;
      case ShapeType.PROTRACTOR:
        this.drawProtractor(shape, pixelBackupHandler);
        break;
      case ShapeType.IMAGE:
        this.imageDraw(shape, pixelBackupHandler);
        break;
      default:
        break;
    }
  };

  proto.updateLineWidth = function (width) {
    this.lineWidth = width;
  };

  proto.drawArrow = function (shape, pixelBackupHandler, type = null) {
    this.ctx.shadowColor = "rgba(0,0,0,0)";
    // this.lineWidth = 5;
    this.ctx.font = "bold 15px Arial";
    var points = shape.points;
    if (pixelBackupHandler) {
      var drawingRect = getMaxBoudingRect(points);
      pixelBackupHandler(
        new Rect(
          drawingRect.x * this.devicePixelRatio - 50,
          drawingRect.y * this.devicePixelRatio - 50,
          drawingRect.width * this.devicePixelRatio + 100,
          drawingRect.height * this.devicePixelRatio + 100,
        ),
      );
    }

    // Draw line
    if (type === "dot") {
      this.ctx.setLineDash([5, 3]);
    } else {
      this.ctx.setLineDash([]);
    }
    this.ctx.beginPath();
    this.ctx.lineWidth = this.lineWidth;
    this.ctx.strokeStyle = this.color;
    this.ctx.moveTo(points[0].x + ALIAS_OFFSET, points[0].y + ALIAS_OFFSET);
    this.ctx.lineTo(points[1].x + ALIAS_OFFSET, points[1].y + ALIAS_OFFSET);
    this.ctx.stroke();
    this.ctx.closePath();
    let radians = 0;
    // this.lineWidth = 4;
    if (!type) {
      // Draw arrow head
      radians = Math.atan(
        (points[1].y - points[0].y) / (points[1].x - points[0].x),
      );
      radians += ((points[1].x > points[0].x ? 90 : -90) * Math.PI) / 180;
      drawArrowhead(this.ctx, points[1].x, points[1].y, radians, shape.color);
    }
  };

  proto.drawRectangle = function (shape, pixelBackupHandler) {
    this.ctx.shadowColor = "rgba(0,0,0,0)";
    var points = shape.points;
    var drawingRect = getMaxBoudingRect(points);
    if (pixelBackupHandler) {
      pixelBackupHandler(
        new Rect(
          drawingRect.x * this.devicePixelRatio - 2,
          drawingRect.y * this.devicePixelRatio - 2,
          drawingRect.width * this.devicePixelRatio + 10,
          drawingRect.height * this.devicePixelRatio + 10,
        ),
      );
    }
    this.ctx.beginPath();
    this.ctx.lineWidth = this.lineWidth;
    this.ctx.strokeStyle = this.color;
    this.ctx.strokeRect(
      drawingRect.x + ALIAS_OFFSET,
      drawingRect.y + ALIAS_OFFSET,
      drawingRect.width,
      drawingRect.height,
    );
    this.ctx.closePath();
  };

  proto.drawFreeHand = function (shape, pixelBackupHandler) {
    // this.ctx.shadowColor = shape.color;
    // this.ctx.shadowBlur = 1;
    var points = shape.points;
    if (pixelBackupHandler) {
      var drawingRect = getMaxBoudingRect(points);
      pixelBackupHandler(
        new Rect(
          drawingRect.x * this.devicePixelRatio - 50,
          drawingRect.y * this.devicePixelRatio - 50,
          drawingRect.width * this.devicePixelRatio + 100,
          drawingRect.height * this.devicePixelRatio + 100,
        ),
      );
    }
    this.ctx.beginPath();
    this.ctx.lineWidth = this.lineWidth;
    if (this.lineWidth > 5) {
      this.ctx.globalAlpha = 0.5;
    }
    this.ctx.strokeStyle = this.color;
    this.ctx.moveTo(points[0].x, points[0].y);
    for (var i = 1; i < points.length; i++) {
      this.ctx.lineTo(points[i].x, points[i].y);
    }
    this.ctx.stroke();
    this.ctx.globalAlpha = 1;
    this.ctx.closePath();
  };

  proto.drawEllipse = function (shape, pixelBackupHandler) {
    this.ctx.shadowColor = "rgba(0,0,0,0)";
    var points = shape.points;
    var drawingCircle = getMaxBoudingRect(points);
    if (pixelBackupHandler) {
      pixelBackupHandler(
        new Rect(
          drawingCircle.x * this.devicePixelRatio - 2,
          drawingCircle.y * this.devicePixelRatio - 2,
          drawingCircle.width * this.devicePixelRatio + 10,
          drawingCircle.height * this.devicePixelRatio + 10,
        ),
      );
    }
    let x = drawingCircle.x;
    let y = drawingCircle.y;
    let w = drawingCircle.width;
    let h = drawingCircle.height;
    var center_x = x + w / 2;
    var center_y = y + h / 2;
    if (w < 0) {
      x += w;
      w = -w;
    }
    if (h < 0) {
      y += h;
      h = -h;
    }
    this.ctx.beginPath();
    this.ctx.lineWidth = this.lineWidth;
    this.ctx.strokeStyle = this.color;
    this.ctx.ellipse(center_x, center_y, w / 2, h / 2, 0, TAU, false);
    this.ctx.stroke();
    // this.ctx.fill();
    this.ctx.closePath();
  };

  proto.drawCircle = function (shape, pixelBackupHandler) {
    this.ctx.shadowColor = "rgba(0,0,0,0)";
    var points = shape.points;
    var drawingCircle = getMaxBoudingRect(points);
    let startPointX = drawingCircle.x;
    let startPointY = drawingCircle.y;
    let width = drawingCircle.width;
    let height = drawingCircle.height;
    let radius = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
    if (pixelBackupHandler) {
      pixelBackupHandler(
        new Rect(
          drawingCircle.x * this.devicePixelRatio - 2,
          drawingCircle.y * this.devicePixelRatio - 2,
          drawingCircle.width * this.devicePixelRatio + 2,
          drawingCircle.height * this.devicePixelRatio + 2,
        ),
      );
    }
    this.ctx.beginPath();
    this.ctx.lineWidth = this.lineWidth;
    this.ctx.arc(startPointX, startPointY, radius, 0, 2 * Math.PI);
    this.ctx.stroke();
    this.ctx.closePath();
  };

  proto.drawTriangle = function (shape, pixelBackupHandler, rtAngle = false) {
    this.ctx.shadowColor = "rgba(0,0,0,0)";
    var points = shape.points;
    var drawingTriangle = getMaxBoudingRect(points);
    let startPointX = drawingTriangle.x;
    let startPointY = drawingTriangle.y;
    let width = drawingTriangle.width;
    let height = drawingTriangle.height;
    let endedAtX = startPointX + width;
    let endedAtY = startPointY + height;
    let quardA = { x: startPointX, y: endedAtY };
    let quardB = { x: endedAtX, y: endedAtY };
    let quardC = rtAngle
      ? { x: startPointX, y: startPointY }
      : { x: (endedAtX + startPointX) / 2, y: startPointY };
    if (pixelBackupHandler) {
      pixelBackupHandler(
        new Rect(
          drawingTriangle.x * this.devicePixelRatio - 2,
          drawingTriangle.y * this.devicePixelRatio - 2,
          drawingTriangle.width * this.devicePixelRatio + 10,
          drawingTriangle.height * this.devicePixelRatio + 10,
        ),
      );
    }
    this.ctx.beginPath();
    this.ctx.lineWidth = this.lineWidth;
    this.ctx.strokeStyle = this.color;
    this.ctx.moveTo(quardA.x, quardA.y);
    this.ctx.lineTo(quardB.x, quardB.y);
    this.ctx.lineTo(quardC.x, quardC.y);
    this.ctx.lineTo(quardA.x, quardA.y);
    this.ctx.stroke();
    this.ctx.closePath();
  };

  proto.drawPolygon = function (shape, pixelBackupHandler) {
    this.ctx.shadowColor = "rgba(0,0,0,0)";
    var points = shape.points;
    var drawingTriangle = getMaxBoudingRect(points);
    let startPointX = drawingTriangle.x;
    let startPointY = drawingTriangle.y;
    let width = drawingTriangle.width;
    let height = drawingTriangle.height;
    let endedAtX = startPointX + width;
    let endedAtY = startPointY + height;
    let quardA = { x: startPointX + width / 4, y: startPointY };
    let quardB = { x: startPointX + (width * 3) / 4, y: startPointY };
    let quardC = { x: endedAtX, y: startPointY + height / 2 };
    let quardD = { x: quardB.x, y: endedAtY };
    let quardE = { x: quardA.x, y: endedAtY };
    let quardF = { x: startPointX, y: quardC.y };

    if (pixelBackupHandler) {
      pixelBackupHandler(
        new Rect(
          drawingTriangle.x * this.devicePixelRatio - 2,
          drawingTriangle.y * this.devicePixelRatio - 2,
          drawingTriangle.width * this.devicePixelRatio + 10,
          drawingTriangle.height * this.devicePixelRatio + 10,
        ),
      );
    }

    this.ctx.beginPath();
    this.ctx.lineWidth = this.lineWidth;
    this.ctx.strokeStyle = this.color;
    this.ctx.moveTo(quardA.x, quardA.y);
    this.ctx.lineTo(quardB.x, quardB.y);
    this.ctx.lineTo(quardC.x, quardC.y);
    this.ctx.lineTo(quardD.x, quardD.y);
    this.ctx.lineTo(quardE.x, quardE.y);
    this.ctx.lineTo(quardF.x, quardF.y);
    this.ctx.lineTo(quardA.x, quardA.y);
    this.ctx.stroke();
    this.ctx.closePath();
  };

  proto.newEraser = function ({ x, y }) {
    this.ctx.clearRect(x - 10, y - 10, 20, 20);
  };

  proto.eraseCanvas = function (shape, pixelBackupHandler) {
    var points = shape.points;
    if (pixelBackupHandler) {
      var drawingRect = getMaxBoudingRect(points);
      pixelBackupHandler(
        new Rect(
          drawingRect.x * this.devicePixelRatio - 50,
          drawingRect.y * this.devicePixelRatio - 50,
          drawingRect.width * this.devicePixelRatio + 100,
          drawingRect.height * this.devicePixelRatio + 100,
        ),
      );
    }
    for (var i = 0; i < points.length; i++) {
      this.ctx.clearRect(
        points[i].x - ERASER_OFFSET,
        points[i].y - ERASER_OFFSET,
        2 * ERASER_OFFSET,
        2 * ERASER_OFFSET,
      );
      // this.ctx.lineTo(points[i].x, points[i].y);
    }
  };

  proto.backupPixelRect = function (rect) {
    this.prevDim = rect;
    this.prevData = this.ctx.getImageData(
      this.prevDim.x + (this.panningOffset?.x || 0),
      this.prevDim.y + (this.panningOffset?.y || 0),
      this.prevDim.width,
      this.prevDim.height,
    );
  };

  proto.erasePrevShape = function () {
    if (this.prevData && this.prevDim) {
      this.ctx.putImageData(
        this.prevData,
        this.prevDim.x + (this.panningOffset?.x || 0),
        this.prevDim.y + (this.panningOffset?.y || 0),
      );
      this.prevData = this.prevDim = null;
    }
  };

  proto.createTextArea = function (e) {
    if (this.textarea) {
      this.textStyle = {
        ...this.textStyle,
        x: e.offsetX + (this.panning?.x || 0),
        y: e.offsetY + (this.panning?.y || 0),
      };
      var style = `top:${this.textStyle.y}px;left:${this.textStyle.x}px;position:absolute;border:1.5px dotted #7e7e9d; white-space:pre; min-width:150px; min-height:50px`;
      var element = document.getElementById("canvas-editable-id");
      element.style.cssText = style;
      this.textarea = true;
      if (element.classList.value.includes("hide")) {
        element.classList.remove("hide");
      }
      setTimeout(() => {
        styleChanger(this.textStyle, this.currentColor);
      }, 500);
    } else {
      let $this = this;
      let element = document.getElementById("canvas-editable-id");
      element.style.border = "1.5px dotted rgb(255, 255, 255,0)";
      const svgDocument = elementToSVG(element);
      const svgString = new XMLSerializer().serializeToString(svgDocument);
      let svg64 = Buffer.from(svgString, "utf8").toString("base64");
      let b64Start = "data:image/svg+xml;base64,";
      let image64 = b64Start + svg64;
      let img = new Image();
      img.onload = function () {
        $this.ctx.drawImage(img, $this.textStyle.x, $this.textStyle.y);
        $this.DrawEditableDiv(image64, element.innerHTML, {
          x: $this.textStyle.x,
          y: $this.textStyle.y,
          imgWidth: img.width,
          imgHeight: img.height,
        });
        element.innerHTML = "";
        element.removeAttribute("style");
      };
      img.src = image64;
      this.textarea = false;
    }
  };

  proto.DrawEditableDiv = function (img, html, { x, y, imgWidth, imgHeight }) {
    if (!img || !html) return;
    let _ref = `${roomName}/${this.uuid}/Drawing`;
    let shape = {};
    shape.points = {
      x: (x - (this.panningOffset?.x || 0)) / this.canvas.width,
      y: (y - (this.panningOffset?.y || 0)) / this.canvas.height,
      iw: imgWidth / this.canvas.width,
      ih: imgHeight / this.canvas.height,
    };
    shape.type = "EDITABLE_DIV";
    shape.html = html;
    shape.img = img;
    shape.source = getMyUserId();
    shape.uuid = uuidv4();
    const utcTimestamp = moment().utc().valueOf();
    shape.timestamp = utcTimestamp;
    shape.scale = this.scale || 1;
    scope.lastFigure = shape.uuid;
    addWhiteBoardElemets(shape);
    // _push(_ref, shape);
  };

  proto.drawProtractor = function (shape, pixelBackupHandler) {
    var imageX, imageY, imageWidth, imageHeight;
    var points = shape.points;
    var drawingRect = getMaxBoudingRect(points);
    if (pixelBackupHandler) {
      pixelBackupHandler(
        new Rect(
          drawingRect.x * this.devicePixelRatio - 2,
          drawingRect.y * this.devicePixelRatio - 2,
          drawingRect.width * this.devicePixelRatio + 10,
          drawingRect.height * this.devicePixelRatio + 10,
        ),
      );
    }
    var $this = this;
    imageX = drawingRect.x + ALIAS_OFFSET;
    imageY = drawingRect.y + ALIAS_OFFSET;
    imageWidth = drawingRect.width;
    imageHeight = drawingRect.height;
    $this.ctx.beginPath();
    $this.ctx.drawImage(
      $this.protractorImg,
      imageX,
      imageY,
      imageWidth,
      imageHeight,
    );
    $this.ctx.closePath();
  };

  proto.imageDraw = function (shape, pixelBackupHandler) {
    var imageX, imageY, imageWidth, imageHeight;
    var points = shape.points;
    var drawingRect = getMaxBoudingRect(points);
    if (pixelBackupHandler) {
      pixelBackupHandler(
        new Rect(
          drawingRect.x * this.devicePixelRatio - 2,
          drawingRect.y * this.devicePixelRatio - 2,
          drawingRect.width * this.devicePixelRatio + 10,
          drawingRect.height * this.devicePixelRatio + 10,
        ),
      );
    }
    var $this = this;
    imageX = drawingRect.x + ALIAS_OFFSET;
    imageY = drawingRect.y + ALIAS_OFFSET;
    imageWidth = drawingRect.width;
    imageHeight = drawingRect.height;
    $this.ctx.beginPath();
    $this.ctx.drawImage(
      $this.protractorImg,
      imageX,
      imageY,
      imageWidth,
      imageHeight,
    );
    $this.ctx.closePath();
  };

  proto.getOffsetPoint = function (e) {
    var eventCords = {
      x:
        e.clientX ||
        (e.targetTouches?.length > 0
          ? e.targetTouches?.[0]?.pageX
          : e.changedTouches?.[0]?.pageX),
      y:
        e.clientY ||
        (e.targetTouches?.length > 0
          ? e.targetTouches?.[0]?.pageY
          : e.changedTouches?.[0]?.pageY),
    };
    if (typeof this.panningOffset?.x === "number") {
      eventCords.x -= this.panningOffset.x;
      eventCords.y -= this.panningOffset.y;
    }
    return new Point(
      parseInt(
        eventCords.x - (this.clientRect.left + this.compensateForBorder),
      ),
      parseInt(eventCords.y - (this.clientRect.top + this.compensateForBorder)),
    );
  };

  proto.undo = function () {
    if (this.shapes.length > 0) {
      this.undoShapes.push(this.shapes.pop());
      this.drawShapes();
    }
  };

  proto.redo = function () {
    if (this.undoShapes.length > 0) {
      this.shapes.push(this.undoShapes.pop());
      this.drawShapes();
    }
  };

  proto.getBBoxCords = (points) => getMaxBoudingRect(points);

  function getMaxBoudingRect(points) {
    var pointMin = points[0].clone();
    var pointMax = points[0].clone();
    for (var i = 0; i < points.length; i++) {
      var point = points[i];
      if (point.x < pointMin.x) {
        pointMin.x = point.x;
      }
      if (point.y < pointMin.y) {
        pointMin.y = point.y;
      }
      if (point.x > pointMax.x) {
        pointMax.x = point.x;
      }
      if (point.y > pointMax.y) {
        pointMax.y = point.y;
      }
    }
    return new Rect(
      pointMin.x,
      pointMin.y,
      pointMax.x - pointMin.x,
      pointMax.y - pointMin.y,
    );
  }

  function drawArrowhead(ctx, x, y, radians, color) {
    ctx.save();
    ctx.beginPath();
    ctx.translate(x, y);
    ctx.rotate(radians);
    ctx.moveTo(0, 0);
    ctx.lineTo(10, 30);
    ctx.lineTo(-10, 30);
    ctx.closePath();
    ctx.restore();
    ctx.fill();
  }
  //////////////////////////////////////////////////////////////////IMAGE DRAWING WITH DRAG/////////////////////////////////////////////////////////////////////////////////////

  proto.drawDragAnchor = function (x, y) {
    this.ctx.beginPath();
    this.ctx.arc(x, y, 10, 0, TAU, false);
    this.ctx.closePath();
    this.ctx.fill();
  };

  function styleChanger(
    { styleClass: _stateCheck, fontSize, fontFamily },
    color,
  ) {
    if (_stateCheck !== undefined && _stateCheck !== null) {
      // Active/Inactive style
      let isBold = document.queryCommandState("Bold");
      let isItalic = document.queryCommandState("Italic");
      let isUnderline = document.queryCommandState("Underline");
      if (_stateCheck.includes("bold") && !isBold) {
        document.execCommand("Bold", true, null);
      } else if (!_stateCheck.includes("bold") && isBold) {
        document.execCommand("Bold", true, null);
      }
      if (_stateCheck.includes("italic") && !isItalic) {
        document.execCommand("Italic", true, null);
      } else if (!_stateCheck.includes("italic") && isItalic) {
        document.execCommand("Italic", true, null);
      }
      if (_stateCheck.includes("underline") && !isUnderline) {
        document.execCommand("Underline", true, null);
      } else if (_stateCheck.includes("underline") && isUnderline) {
        document.execCommand("Underline", true, null);
      }
    }
    if (fontSize && fontSize > 0) {
      document.execCommand(
        "insertHTML",
        false,
        `<span style="font-size:${fontSize}px;">&nbsp</span>`,
      );
    }
    if (color && color.r && color.g) {
      document.execCommand(
        "foreColor",
        false,
        `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
      );
    }
    if (fontFamily) {
      document.execCommand(
        "fontName",
        false,
        fontFamily === "Helvetica" ? "Helvetica Neue" : fontFamily,
      );
    }
  }

  WB.ShapeType = ShapeType;
  scope.WhiteBoard = WB;
  scope.getMaxBoudingRect = getMaxBoudingRect;
  scope.Point = Point;
  scope.Shape = Shape;
})(window);
