/* eslint-disable no-case-declarations */
export const _eventListener = () => {};

export const _eventGenerator = () => {
  RegisterEvent(document, "keypress", KeypadHandler);
};

export const _eventRemover = () => {
  UnRegisterEvent(document, "keypress", KeypadHandler);
};

export const RegisterEvent = (element, eventType, eventHandler) => {
  (window.addEventListener || window.attachEvent).call(
    element,
    eventType,
    eventHandler,
  );
};

export const UnRegisterEvent = (element, eventType, eventHandler) => {
  (window.removeEventListener || window.detachEvent).call(
    element,
    eventType,
    eventHandler,
  );
};

const KeypadHandler = (e) => {
  let fetchTextElement = document.getElementById("canvas-text-element");
  if (fetchTextElement && document.activeElement === fetchTextElement) {
    return null;
  }
  let isShift = e.shiftKey;
  let key = e.key;
  if (key && isShift) {
    switch (key) {
      case "Z":
      case "z": {
        let undo = document.getElementById("undo");
        if (undo) {
          undo.click();
        }
        break;
      }
      case "Y":
      case "y":
        let redo = document.getElementById("redo");
        if (redo) {
          redo.click();
        }
        break;
      case "S":
      case "s":
        setTimeout(() => {
          let download = document.getElementById("download");
          if (download) {
            download.click();
          }
        }, 1000);
        break;
      case "R":
      case "r":
        let sReactangle = document.getElementById("sReactangle");
        if (sReactangle) {
          sReactangle.click();
        }
        break;
      case "C":
      case "c":
        let sElipse = document.getElementById("sElipse");
        if (sElipse) {
          sElipse.click();
        }
        break;
      case "H":
      case "h":
        let sPolygon = document.getElementById("sPolygon");
        if (sPolygon) {
          sPolygon.click();
        }
        break;
      case "L":
      case "l":
        let sSolidLine = document.getElementById("sSolidLine");
        if (sSolidLine) {
          sSolidLine.click();
        }
        break;
      case "A":
      case "a":
        let sArrow = document.getElementById("sArrow");
        if (sArrow) {
          sArrow.click();
        }
        break;
      case "P":
      case "p":
        let sPen = document.getElementById("sPen");
        if (sPen) {
          sPen.click();
        }
        break;
      default:
        break;
    }
  }
};
