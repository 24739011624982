/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from "react";
import "font-awesome/css/font-awesome.min.css";
import reactCSS from "reactcss";
import { SketchPicker } from "react-color";
import drawTools from "./index";
// import { useIsInViewport } from "./CanvasFunction";
// import { etherpad } from "./etherpad";
// import { _userDrawData } from "./firebase/database";
import { useSelector } from "react-redux";
import { _eventGenerator, _eventRemover } from "./Events";
import { Shapes } from "./Constant";
import { jsPDF } from "jspdf";
import { etherpad } from "./etherpad";
import { _canvas_credentials } from "../Helpers/CallCommon";

// import { _canvas_credentials } from "../Helpers/CallCommon";
import { clearWhiteBoardData, updateWhiteBoardElemets } from "../../SocketIo";
import { getBase64, uuidv4 } from "./CanvasFunction";

import ReactTooltip from "react-tooltip";
import { globalStrings } from "../util/translation/languages";
// import { clearWhiteBoardData } from "../../SocketIo";

const CanvasUI = () => {
  // const whiteBoardRef = useRef(null);
  // const isCanvasLoaded = useIsInViewport(whiteBoardRef);
  const { selectedLanguage } = useSelector((state) => state.chat);
  const { callDetail, whiteBoardData, canvasSize } = useSelector(
    (state) => state.call,
  );
  let divElement = document.getElementById("canvas-editable-id");
  const [colorOverLay, setColorOverLay] = useState({
    r: "0",
    g: "0",
    b: "0",
    a: "100",
  });
  const [showPickerOverLay, setShowPickerOverlay] = useState(false);
  const _fSize = 16;
  const styles = reactCSS({
    default: {
      colorOverLay: {
        background: `rgba(${colorOverLay.r}, ${colorOverLay.g}, ${colorOverLay.b}, ${colorOverLay.a})`,
      },
      popover: { position: "absolute", zIndex: "3", top: "150%", right: 0 },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });
  const fileRef = useRef();
  // const [undoStack, setUndoStack] = useState([]);

  useMemo(() => {
    if (divElement && _fSize) {
      let selText = document.getSelection().toString();
      if (selText) {
        let addedSpanToTxt = selText.replace(
          selText,
          `<span style="font-size: ${_fSize}px;">${selText}</span>`,
        );
        document.execCommand("insertHTML", false, addedSpanToTxt);
        return;
      }
      document.execCommand(
        "insertHTML",
        false,
        `<span style="font-size:${_fSize}px;">&nbsp</span>`,
      );
    }
    if (divElement) {
      document.execCommand(
        "foreColor",
        false,
        colorOverLay.r
          ? `rgba(${colorOverLay.r}, ${colorOverLay.g}, ${colorOverLay.b}, ${colorOverLay.a})`
          : "#000",
      );
    }
  }, [divElement, _fSize]);

  useEffect(() => {
    document.execCommand(
      "foreColor",
      false,
      colorOverLay.r
        ? `rgba(${colorOverLay.r}, ${colorOverLay.g}, ${colorOverLay.b}, ${colorOverLay.a})`
        : "#000",
    );
  }, [colorOverLay]);

  useEffect(() => {
    if (colorOverLay) {
      if (!drawTools) return;
      drawTools.changeActiveColor(
        `rgba(${colorOverLay.r},${colorOverLay.g},${colorOverLay.b},${colorOverLay.a})`,
      );
    }
  }, [colorOverLay]);

  useEffect(() => {
    if (whiteBoardData) {
      drawTools.updateFiguresData(whiteBoardData);
      etherpad(whiteBoardData, "whiteboard", true);
    }
  }, [whiteBoardData, canvasSize]);

  const sortedWhiteboardData = useMemo(() => {
    if (whiteBoardData && Object.keys(whiteBoardData).length > 0) {
      const sortedData = Object.values(whiteBoardData).sort(function (x, y) {
        return x.timestamp - y.timestamp;
      });
      return sortedData;
    } else {
      return [];
    }
  }, [whiteBoardData]);

  const undoStack = useMemo(() => {
    if (whiteBoardData && Object.keys(whiteBoardData).length > 0) {
      const sortedData = Object.values(whiteBoardData).sort(function (x, y) {
        return y.timestamp - x.timestamp;
      });
      const UndoData = sortedData.filter((u) => u.undo === true);
      return UndoData;
    } else {
      return [];
    }
  }, [whiteBoardData]);

  // const undoStack = useMemo(() => {
  //   if (whiteBoardData && sortedWhiteboardData.length > 0) {
  //     const UndoData = sortedWhiteboardData.filter((u) => u.undo === true);
  //     return UndoData;
  //   } else {
  //     return [];
  //   }
  // }, [sortedWhiteboardData]);

  useEffect(() => {
    setTimeout(() => {
      _canvas_credentials();
    }, 200);

    drawTools.initialize(
      "whiteboard",
      (shape) => console.log({ shape: shape?.type, color: shape.color }),
      { compensateForBorder: 0 },
    );
    drawTools.changeLineWidth(1);
    drawTools.changeActiveColor("black");
    drawTools.changeUUID(callDetail?.documentID ?? "harshit");
    _eventGenerator();
    window.addEventListener("resize", _canvas_credentials);
    return function cleanup() {
      _eventRemover();
      window.removeEventListener("resize", _canvas_credentials);
    };
  }, []);

  // const _canvas_credentials = () => {
  //   const _element = document.getElementById("outerStyleId");
  //   const height = _element.clientHeight;
  //   const width = _element.clientWidth;
  //   console.log("_canvas_credentials _element", height, width);
  //   setCred({ height, width });
  // };

  // const _wtb = useMemo(() => {
  //   if (isCanvasLoaded) {
  //     let config = newCanvasTools();
  //     config.initialize(
  //       "whiteboard",
  //       (e, v) => {
  //         console.log({ e, v });
  //       },
  //       { defaultDraw: true },
  //     );
  //     return config;
  //   } else {
  //     return {};
  //   }
  // }, [isCanvasLoaded]);

  const undoHandler = () => {
    if (undoStack.length > 0) {
      const lastElementKey = undoStack[undoStack.length - 1]?.uuid;
      const lastElementKeyIndex = sortedWhiteboardData.findIndex(
        (item) => item.uuid === lastElementKey,
      );
      const undoElemet =
        sortedWhiteboardData[
          lastElementKeyIndex > 0 ? lastElementKeyIndex - 1 : undoStack.length
        ];
      if (undoElemet) {
        const payload = {
          ...undoElemet,
          undo: true,
        };
        updateWhiteBoardElemets(payload);
      }
    } else {
      const lastElement = sortedWhiteboardData[sortedWhiteboardData.length - 1];
      const payload = {
        ...lastElement,
        undo: true,
      };
      updateWhiteBoardElemets(payload);
    }
  };

  const redoHandler = () => {
    if (undoStack.length > 0) {
      const lastElement = undoStack[undoStack.length - 1];
      const payload = {
        ...lastElement,
        undo: false,
      };
      updateWhiteBoardElemets(payload);
    }
  };
  const handleSave = () => {
    let canvas = document.getElementById("whiteboard");
    let url = canvas.toDataURL("image/png");
    let docx = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: [canvas.width, canvas.height],
    });
    docx.addImage(url, "JPEG", 0, 0);
    docx.save(`whiteboard${uuidv4()}.pdf`);
  };

  return (
    <>
      <div className="outerStyle">
        <div className="d-flex align-items-center justify-content-center outerStyle-canvas-container">
          <div
            id="canvas-container"
            className="outerStyle-canvas h-100 d-flex align-items-center justify-content-center"
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                drawTools.changeActiveShape(Shapes.RECTANGLE);
              }}
              data-tip
              data-for="rectangle"
            >
              <svg fill="currentColor" viewBox="0 0 16 16">
                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path>
              </svg>
              <ReactTooltip id={"rectangle"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$box}
              </ReactTooltip>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                drawTools.changeActiveShape(Shapes.POLYGON);
              }}
              data-tip
              data-for="polygon"
            >
              <svg fill="currentColor" viewBox="0 0 16 16">
                <path d="M4.54.146A.5.5 0 0 1 4.893 0h6.214a.5.5 0 0 1 .353.146l4.394 4.394a.5.5 0 0 1 .146.353v6.214a.5.5 0 0 1-.146.353l-4.394 4.394a.5.5 0 0 1-.353.146H4.893a.5.5 0 0 1-.353-.146L.146 11.46A.5.5 0 0 1 0 11.107V4.893a.5.5 0 0 1 .146-.353L4.54.146zM5.1 1 1 5.1v5.8L5.1 15h5.8l4.1-4.1V5.1L10.9 1H5.1z"></path>
              </svg>
              <ReactTooltip id={"polygon"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$polygon}
              </ReactTooltip>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                drawTools.changeActiveShape(Shapes.ELLIPSE);
              }}
              data-tip
              data-for="ellipse"
            >
              <svg fill="currentColor" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              </svg>
              <ReactTooltip id={"ellipse"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$ellipse}
              </ReactTooltip>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                drawTools.changeActiveShape(Shapes.ARROW);
              }}
              data-tip
              data-for="arrow"
            >
              <svg fill="currentColor" viewBox="1 3.5 14 9" className="arrow">
                <path d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"></path>
              </svg>
              <ReactTooltip id={"arrow"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$arrow}
              </ReactTooltip>
            </button>
            <button
              className="px-3"
              onClick={(e) => {
                e.preventDefault();
                drawTools.changeActiveShape(Shapes.SOLID_LINE);
              }}
              data-tip
              data-for="solidLine"
            >
              <svg fill="currentColor" viewBox="2 7.5 12 1" className="line">
                <path d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"></path>
              </svg>
              <ReactTooltip id={"solidLine"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$solidLine}
              </ReactTooltip>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                drawTools.changeActiveShape(Shapes.TRIANGLE);
              }}
              data-tip
              data-for="triangle"
            >
              <svg fill="currentColor" viewBox="0 1 16 14">
                <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.146.146 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.163.163 0 0 1-.054.06.116.116 0 0 1-.066.017H1.146a.115.115 0 0 1-.066-.017.163.163 0 0 1-.054-.06.176.176 0 0 1 .002-.183L7.884 2.073a.147.147 0 0 1 .054-.057zm1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566z"></path>
              </svg>
              <ReactTooltip id={"triangle"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$triangle}
              </ReactTooltip>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                drawTools.changeActiveShape(Shapes.RA_TRIANGLE);
              }}
              data-tip
              data-for="ra_triangle"
              className="mx-1"
            >
              <svg viewBox="0 0 207 208" fill="none">
                <line
                  x1="1"
                  y1="1"
                  x2="1.00001"
                  y2="206"
                  stroke="black"
                  strokeWidth="20"
                />
                <line
                  x1="1.01127"
                  y1="204"
                  x2="205.998"
                  y2="206.316"
                  stroke="black"
                  strokeWidth="20"
                />
                <line
                  x1="0.707107"
                  y1="1.29289"
                  x2="205.707"
                  y2="206.293"
                  stroke="black"
                  strokeWidth="15"
                />
              </svg>
              <ReactTooltip id={"ra_triangle"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$ra_triangle}
              </ReactTooltip>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                drawTools.changeActiveShape(Shapes.FREEHAND);
              }}
              data-tip
              data-for="freeHand"
            >
              <svg fill="currentColor" viewBox="0 0 16 16">
                <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"></path>
              </svg>
              <ReactTooltip id={"freeHand"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$freeHand}
              </ReactTooltip>
            </button>
            <button
              onClick={() => {
                drawTools.changeActiveShape(Shapes.TEXT);
              }}
              data-tip
              data-for="text"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                viewBox="0 0 24 24"
                fill="none"
                strokeWidth="2"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <g strokeWidth="1.5">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <line x1="4" y1="20" x2="7" y2="20"></line>
                  <line x1="14" y1="20" x2="21" y2="20"></line>
                  <line x1="6.9" y1="15" x2="13.8" y2="15"></line>
                  <line x1="10.2" y1="6.3" x2="16" y2="20"></line>
                  <polyline points="5 20 11 4 13 4 20 20"></polyline>
                </g>
              </svg>
              <ReactTooltip id={"text"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$text}
              </ReactTooltip>
            </button>

            <button
              onClick={() => fileRef.current.click()}
              data-tip
              data-for="image"
            >
              <svg fill="currentColor" viewBox="0 1 16 14">
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>{" "}
                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"></path>
              </svg>
              <input
                type="file"
                hidden
                ref={fileRef}
                onChange={(e) =>
                  e.target.files[0]
                    ? getBase64(e.target.files[0]).then((data) =>
                        drawTools.PasteImage(data),
                      )
                    : null
                }
                accept="image/*"
              />
              <ReactTooltip id={"image"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$image}
              </ReactTooltip>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                drawTools.changeActiveShape(Shapes.ERASER);
              }}
              data-tip
              data-for="eraser"
            >
              <svg fill="currentColor" viewBox="0.62 1.62 14.76 13.38">
                <path d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"></path>
              </svg>
              <ReactTooltip id={"eraser"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$eraser}
              </ReactTooltip>
            </button>
            <button
              data-tip
              data-for="undo"
              onClick={(e) => {
                e.preventDefault();
                undoHandler();
              }}
            >
              <svg fill="currentColor" viewBox="2 0.28 12 13.72">
                {" "}
                <path
                  fillRule="evenodd"
                  d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
                ></path>{" "}
                <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"></path>{" "}
              </svg>
              <ReactTooltip id={"undo"} place="top" effect="solid">
                Undo
              </ReactTooltip>
            </button>
            <button
              data-tip
              data-for="redo"
              onClick={(e) => {
                e.preventDefault();
                redoHandler();
              }}
            >
              <svg fill="currentColor" viewBox="2 0.28 12 13.72">
                {" "}
                <path
                  fillRule="evenodd"
                  d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                ></path>{" "}
                <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"></path>{" "}
              </svg>
              <ReactTooltip id={"redo"} place="top" effect="solid">
                Redo
              </ReactTooltip>
            </button>

            <button
              onClick={(e) => {
                e.preventDefault();
                clearWhiteBoardData({
                  companyId: callDetail?.company_id,
                  documentId: callDetail?.documentID,
                });
              }}
              data-tip
              data-for="clear"
            >
              <svg fill="currentColor" viewBox="1 0 14 16">
                <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"></path>
              </svg>
              <ReactTooltip id={"clear"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$clear}
              </ReactTooltip>
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleSave();
              }}
              data-tip
              data-for="save"
            >
              <svg fill="currentColor" viewBox="0 1 16 13.9">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"></path>
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"></path>
              </svg>
              <ReactTooltip id={"save"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$save}
              </ReactTooltip>
            </button>
            <button
              data-tip
              data-for="colorSelect"
              onClick={() => setShowPickerOverlay(!showPickerOverLay)}
            >
              <svg fill="currentColor" viewBox="0 0.29 15.71 15.71">
                <path d="M13.354.646a1.207 1.207 0 0 0-1.708 0L8.5 3.793l-.646-.647a.5.5 0 1 0-.708.708L8.293 5l-7.147 7.146A.5.5 0 0 0 1 12.5v1.793l-.854.853a.5.5 0 1 0 .708.707L1.707 15H3.5a.5.5 0 0 0 .354-.146L11 7.707l1.146 1.147a.5.5 0 0 0 .708-.708l-.647-.646 3.147-3.146a1.207 1.207 0 0 0 0-1.708l-2-2zM2 12.707l7-7L10.293 7l-7 7H2v-1.293z"></path>
              </svg>
              {showPickerOverLay ? (
                <div style={styles?.popover}>
                  <div
                    style={styles?.cover}
                    onClick={() => setShowPickerOverlay(false)}
                  />
                  <SketchPicker
                    color={colorOverLay}
                    onChange={(colorOverLay) => {
                      setColorOverLay(colorOverLay.rgb);
                    }}
                  />
                </div>
              ) : null}
              <ReactTooltip id={"colorSelect"} place="top" effect="solid">
                {globalStrings[selectedLanguage].$colorPicker}
              </ReactTooltip>
            </button>
          </div>
        </div>
        <div id="outerStyleId">
          <canvas
            //  ref={whiteBoardRef}
            id="whiteboard"
            //  height={1080}
            // width={1920}
            height={canvasSize?.height}
            width={canvasSize?.width}
          />
          <div contentEditable={true} id="canvas-editable-id" />
        </div>
      </div>
    </>
  );
};

export default CanvasUI;
