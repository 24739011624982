import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { alertMsg } from "../util/alertMsg";

export const useIsInViewport = (ref) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
      ),
    [],
  );

  useEffect(() => {
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const imageSize = file.size / Math.pow(1024, 2);
    if (imageSize < 1) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    } else {
      toast.error(alertMsg.whiteBoardFileSizeMsg, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  });
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16),
  );
};
