export const PEN_WIDTH = 2;
export const HIGHLIGHTER_WIDTH = 4;
export const DEFAULT_TEXT_COLOR = "#fff";
export const DEFAULT_ZOOM = "100%";

export const _fontSizeList = [
  { size: 16 },
  { size: 18 },
  { size: 20 },
  { size: 22 },
  { size: 26 },
  { size: 36 },
  { size: 48 },
  { size: 72 },
];

export const _docFontSizeList = [
  { size: 12 },
  { size: 14 },
  { size: 16 },
  { size: 18 },
  { size: 20 },
  { size: 22 },
  { size: 26 },
  { size: 36 },
  { size: 48 },
  { size: 72 },
];

export const _docFontStyle = [
  { title: "Lato", _val: "lato" },
  { title: "Arial", _val: "arial" },
  { title: "Times New Roman", _val: "times new roman" },
  { title: "Calibri", _val: "calibri" },
  { title: "Helvetica", _val: "helvetica" },
  { title: "Garamond", _val: "garamond" },
];

export const _curserPenDropDown = {
  _curserPen: "inactive",
  _curserHighliter: "inactive",
  _highlight: "inactive",
};

export const _shapeDropDown = {
  _rectangle: "inactive",
  _elipse: "inactive",
  _triangle: "inactive",
  _polygon: "inactive",
  _rightAngleTriangle: "inactive",
  _solidLine: "inactive",
  _dottedLine: "inactive",
  _arrow: "inactive",
  _highlight: "inactive",
};

export const _languageList = [
  { title: "ADA", _lngVal: "ada" },
  { title: "AsciiDoc", _lngVal: "ascii" },
  { title: "C/C++", _lngVal: "c" },
  { title: "C#", _lngVal: "c#" },
  { title: "CoffeeScript", _lngVal: "coffeescript" },
  { title: "Cold fusion", _lngVal: "coldfusion" },
  { title: "CSS", _lngVal: "css" },
  { title: "Dart", _lngVal: "dart" },
  { title: "Django", _lngVal: "django" },
  { title: "Eiffel", _lngVal: "eiffel" },
  { title: "Elixir", _lngVal: "elixir" },
  { title: "Elm", _lngVal: "elm" },
  { title: "Forth", _lngVal: "forth" },
  { title: "Fortran", _lngVal: "fortran" },
  { title: "GO", _lngVal: "go" },
  { title: "Typescript", _lngVal: "ts" },
  { title: "Javascript", _lngVal: "js" },
  { title: "Matlab", _lngVal: "matlab" },
  { title: "Python", _lngVal: "python" },
];

export const _wtbToolsDefault = {
  _arrowUp: "inactive",
  _arrowDn: "inactive",
  _back: "inactive",
  _fwd: "inactive",
  _copyCut: "inactive",
  _eraser: "inactive",
  _panning: "inactive",
  _curserPenDropDown,
  _text: "inactive",
  _styleDropDown: {
    _bold: "inactive",
    _italic: "inactive",
    _underline: "inactive",
    _highlight: "inactive",
  },
  _customFontSize: 23,
  _deaultFSize: 16,
  _shapeDropDown,
  _selectCursor: "inactive",
  _colorText: { _color: "inactive" },
  _colorBackground: { _bgColor: "inactive" },
  _handTouch: "inactive",
  _proTool: "inactive",
  _video: "inactive",
  _reactangleArrowDD: {
    _zIn: "inactive",
    _zOut: "inactive",
    _zToFit: "inactive",
    _zTo50: "inactive",
    _zTo100: "inactive",
    _zTo200: "inactive",
  },
  _lock: "inactive",
  _zoomPercent: "32%",
  _drag: "inactive",
  _shareScreen: "inactive",
  _kbShCut: {
    _undo: "inactive",
    _copy: "inactive",
    _cut: "inactive",
    _redo: "inactive",
    _paste: "inactive",
    _save: "inactive",
    _selectAll: "inactive",
    _wtbTools: {
      _reactangle: "inactive",
      _circles: "inactive",
      _lines: "inactive",
      _arrow: "inactive",
      _hexagon: "inactive",
      _pen: "inactive",
    },
  },
};

export const _docxToolsDefault = {
  _bold: "inactive",
  _textStrick: "inactive",
  _italic: "inactive",
  _underline: "inactive",
  _documentPin: "inactive",
  _xIcon: "inactive",
  _xIcon2: "inactive",
  _fx: "inactive",
  _txtSpace: "inactive",
  _squareLine: "inactive",
  _customFontSize: 16,
  _customFontStyle: "Lato",
  _numberLine: "inactive",
  _lato: "inactive",
  _fontSize: "16",
  _doubleText: "inactive",
  _bullets: "inactive",
  _numberBullets: "inactive",
  _layout: "inactive",
  _docPin: "inactive",
  _smile: "inactive",
  _layer: "inactive",
  _clipboard: "inactive",
};

export const Shapes = {
  RECTANGLE: "RECTANGLE",
  ARROW: "ARROW",
  FREEHAND: "FREEHAND",
  ELLIPSE: "ELLIPSE",
  TRIANGLE: "TRIANGLE",
  POLYGON: "POLYGON",
  RA_TRIANGLE: "RA_TRIANGLE",
  SOLID_LINE: "SOLID_LINE",
  DOTTED_LINE: "DOTTED_LINE",
  TEXT: "TEXT",
  ERASER: "ERASER",
};
