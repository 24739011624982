/* eslint-disable no-unused-vars */

var ALIAS_OFFSET = 0.5;
var TEXT_OFFSET = 5;
var TAU = 2 * Math.PI;
var scale = 1;
var CanvasImages = [];

export const etherpad = (_object, id, remove = true) => {
  // let _panning = _object.panning;
  // let _data = _object.Drawing;
  let _data = _object;
  window.allCtx = {};
  if (!window?.scale) {
    window.scale = 1;
  }
  let canvas = document.getElementById(id);
  if (!canvas) return;
  let ctx = canvas.getContext("2d");
  const { height, width } = canvas;
  if (remove) {
    // if (_panning && _panning.x) {
    //   ctx.save();
    //   ctx.setTransform(1, 0, 0, 1, 0, 0);
    //   ctx.clearRect(0, 0, 10000, 10000);
    //   ctx.restore();
    // } else {
    ctx.clearRect(0, 0, width, height);
    // }
  }
  if (
    !canvas ||
    !_data ||
    typeof _data !== "object" ||
    (_data && !Object.keys(_data)?.length)
  )
    return;
  // if (_panning && _panning.x) {
  //   ctx.setTransform(
  //     1,
  //     0,
  //     0,
  //     1,
  //     _panning.x * canvas.width,
  //     _panning.y * canvas.height,
  //   );
  // }
  window.lastFigure = null;
  for (let j = 0; j < Object.keys(_data).length; j++) {
    let i = Object.keys(_data)[j];
    let _sendData = { ..._data[i], uuid: i };
    SelectHandler({ height, width, ctx, data: _sendData });
  }
};

export const SelectHandler = async ({ height, width, ctx, data }) => {
  if (height <= 0 || width <= 0 || !data.type) return;
  let { type, uuid } = data;
  if (uuid && uuid === window.lastFigure) return false;
  if (data?.undo === true) return false;
  if (type === "FREEHAND") {
    ctx.globalCompositeOperation = "source-over";
    FreeHand({ height, width, ctx, data });
  } else if (["ARROW", "SOLID_LINE", "DOTTED_LINE"].includes(type)) {
    ctx.globalCompositeOperation = "source-over";
    DrawArrow({ height, width, ctx, data });
  } else if (type === "RECTANGLE") {
    ctx.globalCompositeOperation = "source-over";
    Rectangle({ height, width, ctx, data });
  } else if (type === "ELLIPSE") {
    ctx.globalCompositeOperation = "source-over";
    Ellipse({ height, width, ctx, data });
  } else if (["TRIANGLE", "RA_TRIANGLE"].includes(type)) {
    ctx.globalCompositeOperation = "source-over";
    Triangle({ height, width, ctx, data });
  } else if (type === "POLYGON") {
    ctx.globalCompositeOperation = "source-over";
    Polygon({ height, width, ctx, data });
  } else if (type === "ERASER") {
    ctx.globalCompositeOperation = "source-over";
    Eraser({ height, width, ctx, data });
  } else if (type === "EDITABLE_DIV") {
    ctx.globalCompositeOperation = "source-over";
    await EditableDiv({ height, width, ctx, data });
  } else if (type === "PROTRACTOR") {
    ctx.globalCompositeOperation = "source-over";
    await ProtDraw({ height, width, ctx, data });
  } else if (type === "IMAGE") {
    ctx.globalCompositeOperation = "source-over";
    await ImageDraw({ height, width, ctx, data });
  }
};

const FreeHand = ({ height, width, ctx, data }) => {
  const { points, width: lineWidth, color, uuid, scale: org_scale } = data;
  let translatePos = { x: width / 2, y: height / 2 };
  let calculated_scale = (scale / org_scale).toFixed(2);
  ctx.setLineDash([]);
  ctx.save();
  ctx.strokeStyle = color;
  ctx.beginPath();
  ctx.lineWidth = lineWidth;
  if (lineWidth > 5) {
    ctx.globalAlpha = 0.5;
  }
  ctx.translate(translatePos.x, translatePos.y);
  ctx.scale(calculated_scale, calculated_scale);
  let _initX = points[0].x * width;
  let _initY = points[0].y * height;
  _initX -= translatePos.x;
  _initY -= translatePos.y;
  ctx.moveTo(_initX, _initY);
  for (var i = 0; i < points.length; i++) {
    let _nextX = points[i].x * width - translatePos.x;
    let _nextY = points[i].y * height - translatePos.y;
    ctx.lineTo(_nextX, _nextY);
  }
  ctx.stroke();
  ctx.globalAlpha = 1;
  ctx.closePath();
  ctx.restore();
  window.allCtx = { ...window.allCtx, [uuid]: ctx };
};

const DrawArrow = ({ height, width, ctx, data }) => {
  const {
    points,
    width: lineWidth,
    color,
    type,
    uuid,
    scale: org_scale,
  } = data;
  let translatePos = { x: width / 2, y: height / 2 };
  let calculated_scale = (scale / org_scale).toFixed(2);
  ctx.save();
  ctx.strokeStyle = color;
  ctx.lineWidth = 1;
  if (type === "DOTTED_LINE") {
    ctx.setLineDash([5, 3]);
  } else {
    ctx.setLineDash([]);
  }
  ctx.beginPath();
  let _initX = points[0].x * width;
  let _initY = points[0].y * height;
  let _finalX = points[1].x * width;
  let _finalY = points[1].y * height;
  _initX -= translatePos.x;
  _initY -= translatePos.y;
  _finalX -= translatePos.x;
  _finalY -= translatePos.y;
  ctx.translate(translatePos.x, translatePos.y);
  ctx.scale(calculated_scale, calculated_scale);
  ctx.moveTo(_initX + ALIAS_OFFSET, _initY + ALIAS_OFFSET);
  ctx.lineTo(_finalX + ALIAS_OFFSET, _finalY + ALIAS_OFFSET);
  ctx.stroke();
  ctx.closePath();
  let radians = 0;
  if (type === "ARROW") {
    // Draw arrow head
    radians = Math.atan((_finalY - _initY) / (_finalX - _initX));
    radians += ((_finalX > _initX ? 90 : -90) * Math.PI) / 180;
    drawArrowhead(ctx, _finalX, _finalY, radians, color);
  }
  ctx.restore();
  window.allCtx = { ...window.allCtx, [uuid]: ctx };
};

const Rectangle = ({ height, width, ctx, data }) => {
  let translatePos = { x: width / 2, y: height / 2 };
  ctx.setLineDash([]);
  ctx.shadowColor = "rgba(0,0,0,0)";
  const { points, lineWidth, color, angle, uuid, scale: org_scale } = data;
  let calculated_scale = (scale / org_scale).toFixed(2);
  ctx.beginPath();
  ctx.lineWidth = 1;
  let _initX = points[0].x * width;
  let _initY = points[0].y * height;
  let _finalX = points[1].x * width;
  let _finalY = points[1].y * height;
  let _figWidth = Math.abs(_finalX - _initX);
  let _figHeight = Math.abs(_finalY - _initY);
  ctx.save();
  if (angle) {
    let _transX = _figWidth / 2 + points[0].x * width; // centerX
    let _transY = _figHeight / 2 + points[0].y * height; // centerY
    let _rotateX = points[0].x * width - _transX;
    let _rotateY = points[0].y * height - _transY;
    ctx.translate(_transX, _transY);
    ctx.rotate(angle);
    ctx.scale(calculated_scale, calculated_scale);
    ctx.rect(_rotateX, _rotateY, _figWidth, _figHeight);
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
  } else {
    _initX -= translatePos.x;
    _initY -= translatePos.y;
    _finalX -= translatePos.x;
    _finalY -= translatePos.y;
    ctx.translate(translatePos.x, translatePos.y);
    ctx.scale(calculated_scale, calculated_scale);
    ctx.rect(_initX, _initY, _figWidth, _figHeight);
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
  }
  ctx.restore();
  window.allCtx = { ...window.allCtx, [uuid]: ctx };
};

const Ellipse = ({ height, width, ctx, data }) => {
  let translatePos = { x: width / 2, y: height / 2 };
  ctx.setLineDash([]);
  const { points, lineWidth, color, angle, scale: org_scale } = data;
  let calculated_scale = (scale / org_scale).toFixed(2);
  let _initX = points[0].x * width;
  let _initY = points[0].y * height;
  let _finalX = points[1].x * width;
  let _finalY = points[1].y * height;
  _initX -= translatePos.x;
  _initY -= translatePos.y;
  _finalX -= translatePos.x;
  _finalY -= translatePos.y;
  let _figWidth = Math.abs(_finalX - _initX);
  let _figHeight = Math.abs(_finalY - _initY);
  var center_x = _initX + _figWidth / 2;
  var center_y = _initY + _figHeight / 2;
  if (_figWidth < 0) {
    _initX += _figWidth;
    _figWidth = -_figWidth;
  }
  if (_figHeight < 0) {
    _initY += _figHeight;
    _figHeight = -_figHeight;
  }
  ctx.beginPath();
  ctx.lineWidth = 1;
  ctx.save();
  ctx.translate(translatePos.x, translatePos.y);
  ctx.scale(calculated_scale, calculated_scale);
  if (angle) {
    ctx.ellipse(
      center_x,
      center_y,
      _figWidth / 2,
      _figHeight / 2,
      angle,
      TAU,
      false,
    );
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
  } else {
    ctx.ellipse(
      center_x,
      center_y,
      _figWidth / 2,
      _figHeight / 2,
      0,
      TAU,
      false,
    );
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
  }
  ctx.restore();
};

const Triangle = ({ height: c_height, width: c_width, ctx, data }) => {
  let translatePos = { x: c_width / 2, y: c_height / 2 };
  ctx.setLineDash([]);
  const { points, lineWidth, color, type, angle, scale: org_scale } = data;
  let calculated_scale = (scale / org_scale).toFixed(2);
  let _initX = points[0].x * c_width;
  let _initY = points[0].y * c_height;
  let _finalX = points[1].x * c_width;
  let _finalY = points[1].y * c_height;

  ctx.beginPath();
  ctx.lineWidth = 1;
  ctx.save();
  if (angle) {
    let quardA =
      type === "RA_TRIANGLE"
        ? { x: _initX, y: _initY }
        : { x: (_finalX + _initX) / 2, y: _initY };
    let quardB = { x: _finalX, y: _finalY };
    let quardC = { x: _initX, y: _finalY };
    let middleX = (_finalX - _initX) / 2 + _initX;
    let middleY = (_finalY - _initY) / 2 + _initY;
    _initX -= translatePos.x;
    _initY -= translatePos.y;
    _finalX -= translatePos.x;
    _finalY -= translatePos.y;
    ctx.save();
    ctx.translate(middleX, middleY);
    ctx.rotate(angle);
    ctx.scale(calculated_scale, calculated_scale);
    ctx.moveTo(middleX - quardA.x, middleY - quardA.y);
    ctx.lineTo(middleX - quardB.x, middleY - quardB.y);
    ctx.lineTo(middleX - quardC.x, middleY - quardC.y);
    ctx.closePath();
    ctx.strokeStyle = color;
    ctx.stroke();
  } else {
    _initX -= translatePos.x;
    _initY -= translatePos.y;
    _finalX -= translatePos.x;
    _finalY -= translatePos.y;
    let quardA =
      type === "RA_TRIANGLE"
        ? { x: _initX, y: _initY }
        : { x: (_finalX + _initX) / 2, y: _initY };
    let quardB = { x: _finalX, y: _finalY };
    let quardC = { x: _initX, y: _finalY };
    ctx.translate(translatePos.x, translatePos.y);
    ctx.scale(calculated_scale, calculated_scale);
    ctx.moveTo(quardA.x, quardA.y);
    ctx.lineTo(quardB.x, quardB.y);
    ctx.lineTo(quardC.x, quardC.y);
    ctx.closePath();
    ctx.strokeStyle = color;
    ctx.stroke();
  }
  ctx.restore();
};

const Polygon = ({ height, width, ctx, data }) => {
  let translatePos = { x: width / 2, y: height / 2 };
  ctx.setLineDash([]);
  // ctx.shadowColor = 'rgba(0,0,0,0)';
  const { points, lineWidth, color, angle, scale: org_scale } = data;
  let calculated_scale = (scale / org_scale).toFixed(2);
  let _initX = points[0].x * width;
  let _initY = points[0].y * height;
  let _finalX = points[1].x * width;
  let _finalY = points[1].y * height;
  let _figWidth = Math.abs(_finalX - _initX);
  let _figHeight = Math.abs(_finalY - _initY);
  ctx.beginPath();
  ctx.lineWidth = 1;
  ctx.save();
  if (angle) {
    let quardA = { x: _initX + _figWidth / 4, y: _initY };
    let quardB = { x: _initX + (_figWidth * 3) / 4, y: _initY };
    let quardC = { x: _finalX, y: _initY + _figHeight / 2 };
    let quardD = { x: quardB.x, y: _finalY };
    let quardE = { x: quardA.x, y: _finalY };
    let quardF = { x: _initX, y: quardC.y };
    let middleX = _figWidth / 2 + _initX;
    let middleY = _figHeight / 2 + _initY;
    ctx.translate(middleX, middleY);
    ctx.scale(calculated_scale, calculated_scale);
    ctx.rotate(angle);
    ctx.moveTo(middleX - quardA.x, middleY - quardA.y);
    ctx.lineTo(middleX - quardB.x, middleY - quardB.y);
    ctx.lineTo(middleX - quardC.x, middleY - quardC.y);
    ctx.lineTo(middleX - quardD.x, middleY - quardD.y);
    ctx.lineTo(middleX - quardE.x, middleY - quardE.y);
    ctx.lineTo(middleX - quardF.x, middleY - quardF.y);
    ctx.closePath();
    ctx.strokeStyle = color;
    ctx.stroke();
  } else {
    _initX -= translatePos.x;
    _initY -= translatePos.y;
    _finalX -= translatePos.x;
    _finalY -= translatePos.y;
    let quardA = { x: _initX + _figWidth / 4, y: _initY };
    let quardB = { x: _initX + (_figWidth * 3) / 4, y: _initY };
    let quardC = { x: _finalX, y: _initY + _figHeight / 2 };
    let quardD = { x: quardB.x, y: _finalY };
    let quardE = { x: quardA.x, y: _finalY };
    let quardF = { x: _initX, y: quardC.y };
    ctx.translate(translatePos.x, translatePos.y);
    ctx.scale(calculated_scale, calculated_scale);
    ctx.moveTo(quardA.x, quardA.y);
    ctx.lineTo(quardB.x, quardB.y);
    ctx.lineTo(quardC.x, quardC.y);
    ctx.lineTo(quardD.x, quardD.y);
    ctx.lineTo(quardE.x, quardE.y);
    ctx.lineTo(quardF.x, quardF.y);
    ctx.closePath();
    ctx.strokeStyle = color;
    ctx.stroke();
  }
  ctx.restore();
};

const Eraser = ({ height, width, ctx, data }) => {
  let translatePos = { x: width / 2, y: height / 2 };
  const { points, eraserWidth, offset, scale: org_scale } = data;
  let calculated_scale = (scale / org_scale).toFixed(2);
  for (var i = 0; i < points.length; i++) {
    let _initX = points[i].x * width;
    let _initY = points[i].y * height;
    _initX -= translatePos.x;
    _initY -= translatePos.y;
    ctx.save();
    ctx.translate(translatePos.x, translatePos.y);
    ctx.scale(calculated_scale, calculated_scale);
    ctx.clearRect(
      _initX - offset * width,
      _initY - offset * width,
      eraserWidth * width,
      eraserWidth * width,
    );
    ctx.restore();
  }
};

const EditableDiv = ({ height, width, ctx, data }) => {
  return new Promise((resolve) => {
    let translatePos = { x: width / 2, y: height / 2 };
    const {
      img,
      points: { x, y, iw, ih },
      scale: org_scale,
    } = data;
    let calculated_scale = (scale / org_scale).toFixed(2);
    let _initX = x * width;
    let _initY = y * height;
    let _figWidth = iw * width;
    let _figHeight = ih * height;
    if (CanvasImages && CanvasImages[data.uuid]) {
      _initX -= translatePos.x;
      _initY -= translatePos.y;
      ctx.save();
      ctx.translate(translatePos.x, translatePos.y);
      ctx.scale(calculated_scale, calculated_scale);
      ctx.drawImage(
        CanvasImages[data.uuid],
        Math.ceil(_initX),
        Math.ceil(_initY),
        Math.ceil(_figWidth),
        Math.ceil(_figHeight),
      );
      ctx.restore();
    } else {
      let image = new Image();
      image.onload = function () {
        ctx.drawImage(
          image,
          Math.ceil(_initX),
          Math.ceil(_initY),
          Math.ceil(_figWidth),
          Math.ceil(_figHeight),
        );
        if (CanvasImages && !CanvasImages[data.uuid]) {
          CanvasImages[data.uuid] = image;
        }
        resolve(true);
      };
      image.src = img;
    }
  });
};

const ProtDraw = ({ height, width, ctx, data }) => {
  return new Promise((resolve) => {
    let translatePos = { x: width / 2, y: height / 2 };
    const { points, angle, scale: org_scale } = data;
    let calculated_scale = (scale / org_scale).toFixed(2);
    let _initX = points[0].x * width;
    let _initY = points[0].y * height;
    let _finalX = points[1].x * width;
    let _finalY = points[1].y * height;
    let _figWidth = Math.abs(_finalX - _initX);
    let _figHeight = Math.abs(_finalY - _initY);
    ctx.setLineDash([]);
    ctx.save();
    ctx.beginPath();
    if (angle) {
      let middleX = _figWidth / 2 + _initX;
      let middleY = _figHeight / 2 + _initY;
      ctx.translate(middleX, middleY);
      ctx.scale(calculated_scale, calculated_scale);
      ctx.rotate(angle);
      ctx.drawImage(
        Math.ceil(_initX) - middleX,
        Math.ceil(_initY) - middleY,
        _figWidth,
        _figHeight,
      );
    } else {
      _initX -= translatePos.x;
      _initY -= translatePos.y;
      ctx.translate(translatePos.x, translatePos.y);
      ctx.scale(calculated_scale, calculated_scale);
      ctx.drawImage(
        Math.ceil(_initX),
        Math.ceil(_initY),
        _figWidth,
        _figHeight,
      );
    }
    ctx.restore();
    resolve(true);
  });
};

const ImageDraw = ({ height, width, ctx, data }) => {
  return new Promise((resolve) => {
    let translatePos = { x: width / 2, y: height / 2 };
    const { img, points, angle, scale: org_scale } = data;
    let calculated_scale = (scale / org_scale).toFixed(2);
    let _initX = Math.ceil(points[0].x * width);
    let _initY = Math.ceil(points[0].y * height);
    let _finalX = Math.ceil(points[1].x * width);
    let _finalY = Math.ceil(points[1].y * height);
    let _figWidth = Math.abs(_finalX - _initX);
    let _figHeight = Math.abs(_finalY - _initY);
    if (CanvasImages && CanvasImages[data.uuid]) {
      ctx.save();
      if (angle) {
        let middleX = _figWidth / 2 + _initX;
        let middleY = _figHeight / 2 + _initY;
        _initX -= middleX;
        _initY -= middleY;
        ctx.translate(middleX, middleY);
        ctx.scale(calculated_scale, calculated_scale);
        ctx.rotate(angle);
      } else {
        _initX -= translatePos.x;
        _initY -= translatePos.y;
        ctx.translate(translatePos.x, translatePos.y);
        ctx.scale(calculated_scale, calculated_scale);
      }
      ctx.drawImage(
        CanvasImages[data.uuid],
        _initX,
        _initY,
        _figWidth,
        _figHeight,
      );
      return ctx.restore();
    } else {
      let image = new Image();
      image.onload = function () {
        ctx.save();
        if (angle) {
          let middleX = _figWidth / 2 + _initX;
          let middleY = _figHeight / 2 + _initY;
          _initX -= middleX;
          _initY -= middleY;
          ctx.translate(middleX, middleY);
          ctx.scale(calculated_scale, calculated_scale);
          ctx.rotate(angle);
        } else {
          _initX -= translatePos.x;
          _initY -= translatePos.y;
          ctx.translate(translatePos.x, translatePos.y);
          ctx.scale(calculated_scale, calculated_scale);
        }
        ctx.drawImage(image, _initX, _initY, _figWidth, _figHeight);
        ctx.restore();
        if (CanvasImages && !CanvasImages[data.uuid]) {
          CanvasImages[data.uuid] = image;
        }
        resolve(true);
      };
      image.src = img;
      return;
    }
  });
};

function drawArrowhead(ctx, x, y, radians, color) {
  ctx.strokeStyle = color;
  ctx.fillStyle = color;
  ctx.save();
  ctx.beginPath();
  ctx.translate(x, y);
  ctx.rotate(radians);
  ctx.moveTo(0, 0);
  ctx.lineTo(10, 30);
  ctx.lineTo(-10, 30);
  ctx.stroke();
  ctx.closePath();
  ctx.restore();
  ctx.fill();
}
