/* eslint-disable no-unused-vars */
import { database as db } from "./index";
import {
  ref,
  set,
  get,
  onValue,
  child,
  push,
  update,
  onDisconnect,
  getDatabase,
  remove,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
} from "firebase/database";

export const _set = (refs, data) => {
  return set(ref(db, refs), data);
};

export const _push = (refs, data) => {
  const db = getDatabase();
  const postListRef = ref(db, refs);
  const newPostRef = push(postListRef);
  set(newPostRef, data);
};

export const _readOnChange = (refs, cb, id = null) => {
  const starCountRef = ref(db, refs);
  return onValue(starCountRef, (snapshot) => {
    const data = snapshot.val() || {};
    cb(data, id);
  });
};

const _limit = (snapshot) => {
  if (typeof snapshot === "object" && Object.keys(snapshot).length) {
    let keyArr = Object.keys(snapshot);
    let length = keyArr.length;
    let lastKey = keyArr[keyArr.length - 1];
    let lastValue = Object.values(snapshot)[keyArr.length - 1];
    return { key: lastKey, value: lastValue, length };
  }
  return {};
};

export const _exists = (
  refs,
  bool = false,
  cb = null,
  id = null,
  limit = null,
) => {
  const dbRef = ref(getDatabase());
  return get(child(dbRef, refs))
    .then((snapshot) => {
      if (snapshot.exists()) {
        if (bool) {
          if (typeof cb === "function") {
            return cb(true, id);
          }
          return true;
        } else {
          if (typeof cb === "function") {
            return cb(limit ? _limit(snapshot.val()) : snapshot.val(), id);
          }
          return snapshot.val();
        }
      } else {
        if (bool) {
          if (typeof cb === "function") {
            return cb(false, id);
          }
          return false;
        } else {
          if (typeof cb === "function") {
            return cb({}, id);
          }
          return {};
        }
      }
    })
    .catch((error) => {
      console.error("111", error);
    });
};

export const _update = (refs, data, key = false) => {
  const db = getDatabase();
  // Get a key for a new Post.
  const updates = {};
  if (key) {
    const newPostKey = push(child(ref(db), refs)).key;
    updates[refs + "/" + newPostKey] = data;
  } else {
    updates[refs] = data;
  }

  // Write the new post's data simultaneously in the posts list and the user's post list.

  return update(ref(db), updates)
    .then(() => {
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const _global_child_added = (
  _ref,
  _cb,
  id = null,
  removePrev = true,
) => {
  const db = getDatabase();
  const commentsRef = ref(db, _ref);
  onChildAdded(commentsRef, (data) => {
    let value = data.val() ? { ...data.val(), uuid: data.key } : {};
    if (removePrev === false) {
      _cb({ [data.key]: value }, id, removePrev);
    } else {
      _cb(value, id, removePrev);
    }
  });
};

export const _global_child_changed = (
  _ref,
  _cb,
  id = null,
  removePrev = true,
) => {
  const db = getDatabase();
  const commentsRef = ref(db, _ref);
  onChildChanged(commentsRef, (data) => {
    let value = data.val() ? { ...data.val(), uuid: data.key } : {};
    if (removePrev === false) {
      _cb({ [data.key]: value }, id, removePrev);
    } else {
      _cb(value, id, removePrev);
    }
  });
};

export const _child_added = (_ref, _cb, id = null) => {
  const db = getDatabase();
  const commentsRef = ref(db, _ref);
  onChildAdded(commentsRef, (data) => {
    let value = data.val() ? { ...data.val(), uuid: data.key } : {};
    if (value.Drawing) {
      delete value.Drawing;
    } else if (value.text) {
      delete value.text;
    } else if (value.code) {
      delete value.code;
    }
    _cb({ ...value, action: "_ad" });
  });
};

export const _child_removed = (_ref, _cb) => {
  const db = getDatabase();
  const commentsRef = ref(db, _ref);
  onChildRemoved(commentsRef, (data) => {
    let value = data.val() ? { ...data.val(), uuid: data.key } : {};
    _cb({ ...value, action: "_del" });
  });
};

export const _child_changed = (_ref, _cb) => {
  const db = getDatabase();
  const commentsRef = ref(db, _ref);
  onChildChanged(commentsRef, (data) => {});
};

export const _setOnDisconnect = (refs, data, key = false) => {
  const presenceRef = ref(db, refs);
  // Write a string when this client loses connection
  if (key) {
    const newPostKey = push(child(ref(db), refs)).key;
    return onDisconnect(presenceRef).set({ [newPostKey]: data });
  }
  onDisconnect(presenceRef).set(data);
};

export const _removeOnDisconnect = (refs) => {
  const presenceRef = ref(db, refs);
  onDisconnect(presenceRef)
    .remove()
    .catch((err) => {
      if (err) {
        console.error("could not establish onDisconnect event", err);
      }
    });
};

export const _off = (refs) => {
  ref(db, refs).off();
};
export const _delete = (refs) => {
  return remove(ref(db, refs))
    .then(() => {
      return true;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const _userDrawData = (refs) => {
  const dbRef = ref(getDatabase());
  return get(child(dbRef, refs))
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log("No data available");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
