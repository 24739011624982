import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { _sendEvent } from "../../../SocketIo";
import pencil from "../../../assets/images/pencil.png";
// import ResizeObserver from "resize-observer-polyfill";
const Konva = window.Konva;
// var width = window.innerWidth;
// var height = window.innerHeight;
let stage;
var layer;
var isPaint = false;
var lastLine;
var groupId;
var timeout;
var boardStats = [];
var userLines = [];
// var sceneWidth = 1000;
// var sceneHeight = 1000;
const SketchBoard = (props) => {
  const [position, setposition] = useState([]);
  const [endLine, setEndLine] = useState(false);
  const sketchBoardPosition = useSelector(
    (state) => state.call.sketchBoardPosition,
  );
  const { callDetail } = useSelector((state) => state.call);
  const { authSessionUser } = useSelector((state) => state.authUserDetail);
  const [, forceupdate] = useState("");
  const companyid = authSessionUser.company_id;
  const userID = authSessionUser?.user_id ?? "";
  const id = props.id;
  var ro = new ResizeObserver((entries) => {
    console.log("ResizeObserver", entries);
    fitStageIntoParentContainer();
  });
  useEffect(() => {
    // let sketchContainer = document.getElementById("localvideo");
    const videoElement = document.getElementById(props.videoID);
    const clientHeight = videoElement?.clientHeight ?? 0;
    const clientWidth = videoElement?.clientWidth ?? 0;
    if (Konva) {
      stage = new Konva.Stage({
        container: `container-${props.userId}`,
        width: clientWidth,
        height: clientHeight,
      });

      layer = new Konva.Layer();
      stage.add(layer);
      ro.observe(videoElement);
    }
    window.addEventListener("resize", fitStageIntoParentContainer);
    return () => {
      window.removeEventListener("resize", fitStageIntoParentContainer);
    };
  }, []);

  useEffect(() => {
    if (props?.closeStage && stage) {
      stage?.destroy();
    }
  }, [props?.closeStage]);

  function fitStageIntoParentContainer() {
    if (stage) {
      const videoElement = document.getElementById(props.videoID);
      const clientheight = videoElement?.clientHeight ?? 0;
      const clientwidth = videoElement?.clientWidth ?? 0;
      stage.width(clientwidth);
      stage.height(clientheight);
      forceupdate(0);
    }
  }

  useEffect(() => {
    fitStageIntoParentContainer();
  }, [props.videoID, props.pinUserId, props?.fullScreen]);

  useEffect(() => {
    if (stage) {
      if (props.startEdit) {
        stage.container().style.cursor = `url(${pencil}) 0 25, auto`;
        stage.on("mousedown touchstart", function () {
          if (timeout) {
            clearTimeout(timeout);
          }
          isPaint = true;
          var pos = stage.getPointerPosition();
          groupId = crypto.randomUUID();
          setposition((current) => {
            let tmpCurrentState = { ...current };
            tmpCurrentState[groupId] = [];
            tmpCurrentState[groupId].push({
              x: pos.x,
              y: pos.y,
              groupId: groupId,
              start: true,
            });
            return tmpCurrentState;
          });
          setEndLine(false);
          lastLine = new Konva.Line({
            stroke: props.color,
            strokeWidth: 5,
            globalCompositeOperation: "source-over",
            // round cap for smoother lines
            lineCap: "round",
            lineJoin: "round",
            // add point twice, so we have some drawings even on a simple click
            points: [pos.x, pos.y, pos.x, pos.y],
          });
          layer.add(lastLine);
          userLines.push(lastLine);
        });

        stage.on("mouseup touchend", function () {
          isPaint = false;
          setposition((current) => {
            let tmpCurrentState = { ...current };
            if (tmpCurrentState[groupId]) {
              tmpCurrentState[groupId].push({ groupId: groupId, end: true });
            }
            return tmpCurrentState;
          });
          setEndLine(true);
          timeout = setTimeout(() => {
            clearBoard("timeout");
          }, 5000);
        });

        stage.on("mousemove touchmove", function (e) {
          if (!isPaint) {
            return;
          }
          // prevent scrolling on touch devices
          e.evt.preventDefault();

          const pos = stage.getPointerPosition();
          setposition((current) => {
            let tmpCurrentState = { ...current };
            if (tmpCurrentState[groupId]) {
              tmpCurrentState[groupId].push({
                x: pos.x,
                y: pos.y,
                groupId: groupId,
              });
            }
            return tmpCurrentState;
          });
          var newPoints = lastLine.points().concat([pos.x, pos.y]);
          lastLine.points(newPoints);
        });
      } else {
        stage.container().style.cursor = "default";
        if (stage) {
          stage.off("mousedown touchstart");
          stage.off("mouseup touchend");
          stage.off("mousemove touchmove");
        }
        clearBoard("mousemove else");
      }
    }
  }, [props.startEdit]);

  useEffect(() => {
    console.log("sketchBoardPosition[id]", sketchBoardPosition, id, stage);
    if (stage) {
      if (
        sketchBoardPosition[id] &&
        sketchBoardPosition[id]?.user_id !== userID
      ) {
        const videoElement = document.getElementById(props.videoID);
        const clientheight = videoElement?.clientHeight ?? 0;
        const clientwidth = videoElement?.clientWidth ?? 0;
        const positions = sketchBoardPosition[id]?.position;
        const color = sketchBoardPosition[id]?.color;
        const userID = sketchBoardPosition[id]?.user_id ?? "";
        const clearBoard = sketchBoardPosition[id]?.clearBoard;

        const positionsKey =
          Object.keys(positions).length > 0 ? Object.keys(positions) : [];
        const newPostions = {};
        for (const keyItem of positionsKey) {
          const valuesArray = positions[keyItem];
          const newValues = [];
          for (const valueItem of valuesArray) {
            const item = { ...valueItem };
            item.x = Math.floor((valueItem.x / 100) * clientwidth);
            item.y = Math.floor((valueItem.y / 100) * clientheight);
            newValues.push(item);
          }
          newPostions[keyItem] = newValues;
        }
        if (clearBoard) {
          if (boardStats[userID]) {
            const childrens = [...boardStats[userID]];
            boardStats[userID] = [];
            childrens.map((child) => {
              //   console.log(child[0]);
              child.remove();
            });
          }
        } else {
          Object.keys(newPostions).map((id) => {
            const position = newPostions[id];
            let userLastline;
            position.map((i) => {
              if (i.x && i.y) {
                if (i?.start) {
                  userLastline = startdraw(i.x, i.y, color, userID);
                } else {
                  updatedraw(i.x, i.y, userLastline);
                }
              }
            });
          });
        }
      }
    }
  }, [sketchBoardPosition]);
  const startdraw = (x, y, color, userID) => {
    // isPaint = true;
    let line = new Konva.Line({
      stroke: color,
      strokeWidth: 5,
      globalCompositeOperation: "source-over",
      // round cap for smoother lines
      lineCap: "round",
      lineJoin: "round",
      // add point twice, so we have some drawings even on a simple click
      points: [x, y, x, y],
    });
    layer.add(line);
    if (!boardStats[userID]) {
      boardStats[userID] = [];
    }

    // console.log("layer children", line);
    boardStats[userID].push(line);

    return line;
  };
  const updatedraw = (x, y, userLastline) => {
    // var newPoints = userLastline?.points()?.concat([x, y]);
    // if (newPoints) userLastline?.points([newPoints]);
    userLastline?.points([...userLastline.points(), x, y]);
  };

  const clearBoard = (type) => {
    const tmpPosition = { ...userLines };
    userLines = [];
    console.log("userLines", tmpPosition, type);
    Object.keys(tmpPosition).map((child) => {
      //   console.log(child[0]);
      tmpPosition[child].remove();
    });
    setposition([]);
    const data = {
      id: id,
      color: props.color,
      company_id: companyid,
      user_id: userID,
      position: [],
      clearBoard: true,
    };
    console.log("clearBoard saini==>", data);
    const ObjPayload = {
      senderID: authSessionUser?.user_id,
      event_type: "sketchBoard-Pos", // typing-start / typing-end / call-notification / msg-notification
      receiverID: callDetail?.receiverID, // receiverID or groupID
      type: callDetail?.type, // private or group
      company_id: callDetail?.company_id,
      documentID: callDetail?.documentID,
      data: data,
    };
    _sendEvent(ObjPayload);
    // _updateSketchBoardPosition(data);
  };
  useEffect(() => {
    if (endLine) {
      const videoElement = document.getElementById(props.videoID);
      const clientheight = videoElement?.clientHeight ?? 0;
      const clientwidth = videoElement?.clientWidth ?? 0;
      const positionsKey =
        Object.keys(position).length > 0 ? Object.keys(position) : [];
      const newPostion = {};
      for (const keyItem of positionsKey) {
        const valuesArray = position[keyItem];
        const newValues = [];
        for (const valueItem of valuesArray) {
          const item = { ...valueItem };
          item.x = ((valueItem.x / clientwidth) * 100).toFixed(2);
          item.y = ((valueItem.y / clientheight) * 100).toFixed(2);
          newValues.push(item);
        }
        newPostion[keyItem] = newValues;
      }
      const data = {
        id: id,
        color: props.color,
        company_id: companyid,
        user_id: userID,
        position: newPostion,
      };
      console.log("endLine saini==>", data);
      const ObjPayload = {
        senderID: authSessionUser?.user_id,
        event_type: "sketchBoard-Pos", // typing-start / typing-end / call-notification / msg-notification
        receiverID: callDetail?.receiverID, // receiverID or groupID
        type: callDetail?.type, // private or group
        company_id: callDetail?.company_id,
        documentID: callDetail?.documentID,
        data: data,
      };
      _sendEvent(ObjPayload);
      // _updateSketchBoardPosition(data);
    }
  }, [endLine]);

  return (
    <div
      id={`container-${props?.userId}`}
      className="overlay draw-layout"
    ></div>
  );
};
export default SketchBoard;
