// @flow

import { Modal, ModalFooter, Tabs, Tab, Button } from "react-bootstrap";

import React, { PureComponent } from "react";
import { isPlatform } from "../Helpers/common";
import { getTransport } from "../transport";
import store from "../../store";
import { setSharedScreenType } from "../Helpers/Lib/libSlice";
import { updateScreenshareTypeGlobally } from "../Helpers/CallCommon";
const transport = getTransport();

const THUMBNAIL_SIZE = {
  height: 300,
  width: 300,
};

const UPDATE_INTERVAL = 2000;

const DEFAULT_TAB_TYPE = "screen";

const TAB_LABELS = {
  screen: "Your Entire Screen",
  window: "Application Window",
};

const VALID_TYPES = Object.keys(TAB_LABELS);

type Props = {
  desktopSharingSources: Array<string>,

  onSourceChoose: Function,

  show: Boolean,

  handleClose: Function,
};

type State = {
  screenShareAudio: boolean,

  selectedSource: Object,

  sources: Object,

  types: Array<string>,
};

class DesktopScreenShareModal extends PureComponent<Props, State> {
  static getDerivedStateFromProps(props) {
    return {
      types: DesktopScreenShareModal._getValidTypes(
        props.desktopSharingSources,
      ),
    };
  }

  static _getValidTypes(types = []) {
    return types.filter((type) => VALID_TYPES.includes(type));
  }

  _poller = null;

  state = {
    screenShareAudio: false,
    selectedSource: {},
    sources: {},
    types: [],
  };

  _selectedTabType = DEFAULT_TAB_TYPE;

  constructor(props: Props) {
    super(props);

    this._onCloseModal = this._onCloseModal.bind(this);
    this._onPreviewClick = this._onPreviewClick.bind(this);
    this._onShareAudioChecked = this._onShareAudioChecked.bind(this);
    this._onselectedTabType = this._onselectedTabType.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._updateSources = this._updateSources.bind(this);
    this.state.types = DesktopScreenShareModal._getValidTypes(
      this.props.desktopSharingSources,
    );
  }

  componentDidMount() {
    this._startPolling();
  }

  componentWillUnmount() {
    this._stopPolling();
  }

  render() {
    return (
      <>
        <Modal show={this.props.show} onHide={this._onCloseModal} size="lg">
          <Modal.Header className="flex-column align-items-start">
            <Modal.Title>Share your screen</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this._renderTabs()}</Modal.Body>
          <ModalFooter>
            <div id="shareaudio">
              {isPlatform() === "windows" && (
                <label className="checkwrap mb-0">
                  <input
                    spellCheck="false"
                    type="checkbox"
                    onChange={this._onShareAudioChecked}
                  />
                  <span className="label-text">Share system audio</span>
                  <span className="checkmark"></span>
                </label>
              )}
            </div>

            <div className="d-flex">
              <Button
                className="mr-2"
                variant="light"
                onClick={this._onCloseModal}
              >
                Cancel
              </Button>
              <Button variant="orange" onClick={this._onSubmit}>
                Share
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }

  _getSelectedSource(sources = {}) {
    const { selectedSource } = this.state;
    if (
      !Array.isArray(sources[this._selectedTabType]) ||
      sources[this._selectedTabType].length <= 0
    ) {
      return {};
    }

    if (
      !selectedSource || // scenario 1)
      selectedSource.type !== this._selectedTabType || // scenario 2)
      !sources[this._selectedTabType].some(
        // scenario 3)
        (source) => source.id === selectedSource.id,
      )
    ) {
      return {
        id: sources[this._selectedTabType][0].id,
        type: this._selectedTabType,
      };
    }
    return selectedSource;
  }

  _onCloseModal(id = "", type, screenShareAudio = false) {
    this.props.onSourceChoose(id, type, screenShareAudio);

    this.props.handleClose(false);
  }

  _onPreviewClick(id, type, name) {
    this.setState({
      selectedSource: {
        id,
        type,
        name,
      },
    });
    this._selectedTabType = type;
  }

  _onSubmit() {
    const {
      selectedSource: { id, type, name },
      screenShareAudio,
    } = this.state;
    let screenShareAudiofinal = screenShareAudio;
    if (type !== DEFAULT_TAB_TYPE) screenShareAudiofinal = false;

    store.dispatch(setSharedScreenType(type));
    updateScreenshareTypeGlobally(type, "start");
    // if (type === "window") {
    transport.sendEvent({
      name: "shared-window-info",
      sharedWindowName: name,
      type: type || "",
    });
    // }
    this._onCloseModal(id, type, screenShareAudiofinal);
  }

  _onShareAudioChecked({ target: { checked } }) {
    this.setState({ screenShareAudio: checked });
  }

  _onselectedTabType = (e) => {
    let currentSelectedTab = e.target.dataset.rrUiEventKey;
    let element = document.getElementById("shareaudio");
    if (element !== null) {
      if (currentSelectedTab === DEFAULT_TAB_TYPE)
        element.style.visibility = "visible";
      else element.style.visibility = "hidden";
    }
  };

  _renderTabs() {
    const { selectedSource, sources, types } = this.state;
    return (
      <Tabs defaultActiveKey="screen" fill onClick={this._onselectedTabType}>
        {types.map((type) => {
          return this._renderTab(sources[type], type, selectedSource);
        })}
      </Tabs>
    );
  }

  _renderTab(sources, type, selectedSource) {
    const previews = sources ? (
      sources.map((source) => this._renderSource(source, type, selectedSource))
    ) : (
      <div className="spinner">
        <div className="spin"></div>
      </div>
    );

    return (
      <Tab
        eventKey={type}
        title={TAB_LABELS[type]}
        className="tab-content-min tab-content-max"
      >
        <div className="d-flex flex-wrap share__container">{previews}</div>
      </Tab>
    );
  }

  _renderSource(source, type, selectedSource) {
    const key = source.id;
    const selectedClass = key === selectedSource.id ? "selected" : "";
    const displayClasses = `share__box ${selectedClass}`;
    return (
      <div
        className={displayClasses}
        onClick={() => {
          this._onPreviewClick(source.id, type, source.name);
        }}
        onDoubleClick={() => {
          this._onSubmit(source.id, type);
        }}
        key={key}
        aria-hidden="true"
      >
        <img
          src={source.thumbnail.toDataURL()}
          alt="Share"
          width="100%"
          height="100%"
        />
        {/* <div className="desktop-source-preview-label">{source.name}</div> */}
      </div>
    );
  }
  /**
   * Create an interval to update known available DesktopCapturerSources.
   *
   * @private
   * @returns {void}
   */
  _startPolling() {
    this._stopPolling();
    this._updateSources();
    this._poller = window.setInterval(this._updateSources, UPDATE_INTERVAL);
  }

  /**
   * Cancels the interval to update DesktopCapturerSources.
   *
   * @private
   * @returns {void}
   */
  _stopPolling() {
    window.clearInterval(this._poller);
    this._poller = null;
  }

  /**
   * Obtains the desktop sources and updates state with them.
   *
   * @private
   * @returns {void}
   */
  _updateSources() {
    const { types } = this.state;

    if (types.length > 0) {
      obtainDesktopSources(this.state.types, { thumbnailSize: THUMBNAIL_SIZE })
        .then((sources) => {
          const selectedSource = this._getSelectedSource(sources);
          // TODO: Maybe check if we have stopped the timer and unmounted
          // the component.
          this.setState({
            sources,
            selectedSource,
          });
        })
        .catch(() => {
          /* ignore */
        });
    }
  }
}

export default DesktopScreenShareModal;

export function obtainDesktopSources(types, options = {}) {
  const capturerOptions = {
    types,
  };

  if (options.thumbnailSize) {
    capturerOptions.thumbnailSize = options.thumbnailSize;
  }

  return new Promise((resolve, reject) => {
    const { JitsiMeetElectron } = window;

    if (JitsiMeetElectron && JitsiMeetElectron.obtainDesktopStreams) {
      JitsiMeetElectron.obtainDesktopStreams(
        (sources) => resolve(_seperateSourcesByType(sources)),
        (error) => {
          console.error(`Error while obtaining desktop sources: ${error}`);
          reject(error);
        },
        capturerOptions,
      );
    } else {
      const reason =
        "Called JitsiMeetElectron.obtainDesktopStreams" +
        " but it is not defined";

      console.error(reason);

      return Promise.reject(new Error(reason));
    }
  });
}

function _seperateSourcesByType(sources = []) {
  const sourcesByType = {
    screen: [],
    window: [],
  };

  sources.forEach((source) => {
    const idParts = source?.id?.split(":");
    const type = idParts[0];

    if (sourcesByType[type]) {
      sourcesByType[type].push(source);
    }
  });

  return sourcesByType;
}
