import React, { useState, useEffect } from "react";
import { JitsiTrackEvents } from "../Helpers/Lib";

const AudioInputPreview = ({ audioTrack }) => {
  const [audioLevel, setAudioLevel] = useState(0);

  useEffect(() => {
    if (audioTrack !== null) listenForAudioUpdates(audioTrack);
  }, [audioTrack]);

  const updateAudioLevel = (level) => {
    setAudioLevel(level);
  };

  const listenForAudioUpdates = (track) => {
    stopListeningForAudioUpdates();
    track &&
      track.on(JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED, updateAudioLevel);
  };

  const stopListeningForAudioUpdates = () => {
    audioTrack &&
      audioTrack.off(
        JitsiTrackEvents.TRACK_AUDIO_LEVEL_CHANGED,
        updateAudioLevel,
      );
  };

  const sty = { width: "4%", marginRight: ".2em", overflow: "hidden" };
  let arr = Math.floor(Math.floor(audioLevel * 150) / 5);
  arr = arr > 20 ? 19 : arr;
  return (
    <>
      {[...Array(arr > 0 ? arr : 0).keys()].map((v, i) => {
        return (
          <div
            className={`${
              i <= 6
                ? "bg-green-bar"
                : i > 6 && i <= 10
                ? "bg-orange-bar"
                : "bg-red-bar"
            } `}
            role="progressbar"
            style={sty}
            aria-valuenow="15"
            aria-valuemin="0"
            aria-valuemax="100"
            key={"preview" + i}
          />
        );
      })}
    </>
  );
};

export default AudioInputPreview;
