/* eslint-disable quotes */
/* eslint-disable react/display-name */
/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef, Fragment, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { getAuthSession, hrefPattern, hrefValue } from "../Helpers/common";
import ChatBoardIncoming from "./chatboardIncoming";
import ChatBoardOutgoing from "./chatboardOutgoing";
import {
  _jumpToSpecificChatData,
  _sendLoadMoreChatData,
  _unreadMessageCountUpdate,
} from "../../SocketIo";
import {
  setChatMessage,
  setImagePreviewOpen,
  setIsChatHistoryEnableOnce,
  setIsFetchingChatHistory,
  setIsScrollingUp,
  setLoaderActiveData,
  setReplyMessage,
  setUnreadChatMessage,
} from "../redux/chatSlice";
import ImagePreview from "../models/ImagePreview";
import {
  OnClickOutside,
  checkIsAttachmentsUploaded,
  getChatMessageByGroup,
  getMsgListCorrespondenceToRoom,
  updateChatRoom,
} from "../Helpers/chatCommon";
import CustomDropdown from "../customComponent/customDropdown";
import { alertMsg } from "../util/alertMsg";
import { clearFileData } from "../redux/fileSlice";

const ChatBoardBody = () => {
  const dispatch = useDispatch();

  const authSessionUser = useSelector(
    (state) => state.authUserDetail.authSessionUser,
  );

  const chatMessage = useSelector((state) => state.chat.chatMessage);
  const progress = useSelector((state) => state.chat.progress);

  const imagePreviewOpen = useSelector((state) => state.chat.imagePreviewOpen);
  const loaderActiveData = useSelector((state) => state.chat.loaderActiveData);

  const attendeeData = useSelector((state) => state.chat.attendeeData);
  const searchChatData = useSelector((state) => state.chat.searchChatData);

  const isFetchingChatHistory = useSelector(
    (state) => state.chat.isFetchingChatHistory,
  );

  const selectedFileName = useSelector((state) => state.chat.selectedFileName);
  const selectedFileType = useSelector((state) => state.chat.selectedFileType);
  const chatRoom = useSelector((state) => state.chat.chatRoom);
  const isChatHistoryEnableOnce = useSelector(
    (state) => state.chat.isChatHistoryEnableOnce,
  );

  const isScrollingUp = useSelector((state) => state.chat.isScrollingUp);

  const unreadChatMessage = useSelector(
    (state) => state.chat.unreadChatMessage,
  );

  const authSession = getAuthSession();
  const emojiRef = useRef(null);
  const chatFilterRef = useRef([]);
  const emojiMsgRef = useRef(null);
  const lastElement = useRef(null);
  const chatFeed = useRef(null);
  const [newChatMessage, setNewChatMessage] = useState([]);
  const [selectedJumpId, setSelectedJumpId] = useState(null);
  const [chatMessageByGroup, setChatMessageByGroup] = useState([]);
  const [beginningMessage, setBeginningMessage] = useState(true);
  const [stopScrollBottom, setStopScrollBottom] = useState(false);
  const [lastScrollHeight, SetLastScrollHeight] = useState("");
  const [messageEditId, setMessageEditId] = useState("");
  const [disableScroll, setDisableScroll] = useState(false);
  const [moreActionEnableId, setMoreActionEnableId] = useState("");
  const [showPicker, setShowPicker] = useState("");
  const [isMessageUpdateFromJump, setIsMessageFromJump] = useState(false);
  const [showMessagePicker, setShowMessagePicker] = useState("");
  const [isOpenDropdown, setIsOpenDropdown] = useState("");

  // ---------------------Date-----------------
  const todayDate = moment(Date.now()).format("MMM DD YYYY");
  let username = attendeeData.name;
  let type = "private";
  let receiverID = attendeeData?.user_id;
  let companyID = attendeeData.company_id;
  let senderID = authSession.user_unique_key;

  if (attendeeData.type == "group") {
    username = attendeeData.username;
    type = "group";
    receiverID = attendeeData.groupId;
    companyID = authSession.company_unique_key;
  }

  useEffect(() => {
    if (Object.keys(attendeeData).length > 0) {
      updateChatRoom(type, senderID, receiverID);
    }
  }, [attendeeData]);

  let incomingUserObj = attendeeData;
  let outgoingUserObj = authSessionUser;

  // socket code start//
  useEffect(() => {
    if (chatRoom) {
      dispatch(clearFileData());
      getMsgListCorrespondenceToRoom();
    }
  }, [chatRoom]);

  useEffect(() => {
    setNewChatMessage(chatMessage);
  }, [chatMessage]);

  useEffect(async () => {
    if (searchChatData) {
      setBeginningMessage(false);
    } else {
      setBeginningMessage(true);
    }
  }, [searchChatData]);

  useEffect(() => {
    dispatch(setLoaderActiveData(false));
  }, [newChatMessage]);

  useEffect(() => {
    if (newChatMessage) {
      getChatMessageByGroup(newChatMessage).then((data) => {
        setChatMessageByGroup(data);
      });
    }
  }, [newChatMessage, attendeeData]);

  useEffect(() => {
    let lastScrollTop = 0;
    const el = document.getElementById("chat-feed");
    if (el) {
      el.onscroll = () => {
        if (el.scrollTop < lastScrollTop) {
          dispatch(setIsScrollingUp(true));
        }
        lastScrollTop = el.scrollTop <= 0 ? 0 : el.scrollTop;
        if (el.scrollTop + el.offsetHeight >= el.scrollHeight - 3) {
          dispatch(setIsScrollingUp(false));
        }
      };
    }
    if (stopScrollBottom === false) {
      if (!isChatHistoryEnableOnce && !isScrollingUp) {
        scrollToBottom();
      }
    } else {
      if (el && stopScrollBottom === false) {
        const NewScrollHeight = el.scrollHeight;
        const difference = NewScrollHeight - lastScrollHeight;
        el.scrollTop = difference;
      }
      setTimeout(() => {
        setStopScrollBottom(false);
      }, 100);
    }
  }, [chatMessageByGroup]);

  useEffect(() => {
    checkIsAttachmentsUploaded();
  }, [newChatMessage]);

  useEffect(() => {
    if (!isFetchingChatHistory) return;
    fetchMoreListItems();
  }, [isFetchingChatHistory]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      chatFilterRef?.current?.forEach((divRef) => {
        if (divRef && !divRef?.current?.contains(event?.target)) {
          setSelectedJumpId("");
          setDisableScroll(false);
          setIsOpenDropdown("");
        }
      });
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (isMessageUpdateFromJump) {
      setTimeout(() => {
        const elementId = chatMessage[chatMessage.length - 1];
        let element = document.getElementById(elementId?.id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          setIsMessageFromJump(false);
        }
      }, 200);
    }
  }, [chatMessage]);

  const scrollToBottom = () => {
    lastElement.current?.scrollIntoView({ behavior: "smooth" });
    const el = document.getElementById("chat-feed");
    if (el) {
      el.scrollTop = el?.scrollHeight;
    }
  };

  const fetchMoreListItems = () => {
    setTimeout(() => {
      const payloadData = {
        company_id: companyID,
        documentID: chatMessage[chatMessage.length - 1].documentID,
        timestamp: chatMessage[chatMessage.length - 1].timestamp,
        user_id: senderID,
      };
      _sendLoadMoreChatData(payloadData);
    }, 2000);
  };

  const handleScroll = (e) => {
    let element = e.target;
    if (
      element.scrollTop === 0 &&
      chatMessageByGroup.length > 0 &&
      searchChatData === ""
    ) {
      dispatch(setIsFetchingChatHistory(true));
      dispatch(setIsChatHistoryEnableOnce(true));
      setStopScrollBottom(true);
      SetLastScrollHeight(element?.scrollHeight ?? 0);
    }
    const el = document.getElementById("chat-feed");
    if (el) {
      const { scrollTop, scrollHeight, clientHeight } = el;
      if (scrollTop + clientHeight === scrollHeight) {
        dispatch(setIsChatHistoryEnableOnce(false));

        const prevChatMessage = [...unreadChatMessage];
        const msgIndex = prevChatMessage.findIndex(
          (obj) =>
            obj.user_id ===
            (type === "private"
              ? attendeeData?.user_id
              : attendeeData?.groupId),
        );
        if (msgIndex !== -1) {
          const msgElement = prevChatMessage[msgIndex];
          if (msgElement.count !== 0) {
            prevChatMessage[msgIndex] = {
              company_id: companyID,
              logged_user_id: senderID,
              user_id: msgElement?.user_id,
              count: 0,
              timestamp: msgElement.timestamp,
              notification_sound: msgElement?.notification_sound ?? true,
              group_mute: msgElement?.group_mute ?? false,
            };
            const newObj = {
              company_id: companyID,
              logged_user_id: senderID,
              user_id: msgElement?.user_id,
              count: 0,
              timestamp: msgElement.timestamp,
              notification_sound: msgElement?.notification_sound ?? true,
              group_mute: msgElement?.group_mute ?? false,
            };
            _unreadMessageCountUpdate(newObj);
            dispatch(setUnreadChatMessage(prevChatMessage));
          }
        }
      }
    }
  };

  const editMessageHandler = (id) => {
    const element1 = document.getElementById(id)?.getBoundingClientRect()?.top;
    const element2 = document
      .getElementById("text")
      ?.getBoundingClientRect()?.top;
    let totalCal = element2 - element1;
    totalCal < 96 ? console.log("Yes") : console.log("No");
    setMessageEditId(id);
    setMoreActionEnableId("");
  };

  const moreActionHandler = (id) => {
    setMoreActionEnableId(moreActionEnableId === id ? "" : id);
  };

  const showHideEmojiPickerHandler = (id) => {
    if (showPicker === id) {
      setShowPicker("");
    } else {
      setShowPicker(id);
    }
    moreActionHandler("");
  };

  const showHideEmojiMsgPickerHandler = (id) => {
    if (showPicker === id) {
      setShowMessagePicker("");
    } else {
      setShowMessagePicker(id);
    }
  };

  const handleDisableScroll = (e, rowId) => {
    window.onload = handleMouseMove(e);
    let element = document.getElementById(rowId);
    element?.addEventListener("click", handleMouseMove);

    function handleMouseMove(event) {
      let textArea = document
        .getElementById("text")
        ?.getBoundingClientRect()?.top;
      let mouseY = event.pageY;
      let totalCal = Math.floor(textArea) - Number(mouseY);
      totalCal < 210
        ? setIsOpenDropdown("position-bottom")
        : setIsOpenDropdown("");
    }
    if (selectedJumpId === rowId) {
      setSelectedJumpId("");
      setDisableScroll(false);
      element?.removeEventListener("click", handleMouseMove);
    } else {
      setSelectedJumpId(rowId);
      setDisableScroll(true);
    }
  };

  OnClickOutside(emojiRef, () => setShowPicker(""));
  OnClickOutside(emojiMsgRef, () => setShowMessagePicker(""));

  const replyHandler = (replyData) => {
    dispatch(setReplyMessage(replyData));
  };

  const handleImagePreviewClose = () => {
    dispatch(setImagePreviewOpen(false));
  };

  const handleChatFilterByDate = async (day) => {
    const payloadData = {
      company_id: companyID,
      documentID: chatMessage[chatMessage.length - 1].documentID,
      days: day,
    };

    const result = await _jumpToSpecificChatData(payloadData);
    if (result.length > 0) {
      setSelectedJumpId("");
      setDisableScroll(false);
      setIsOpenDropdown("");
      setIsMessageFromJump(true);
      dispatch(setChatMessage(result));
    }
  };

  const customChatLoader = () => {
    return (
      <div className="timeline-loader-background">
        <div className="timeline-loader-background-masker header-top"></div>
        <div className="timeline-loader-background-masker header-left"></div>
        <div className="timeline-loader-background-masker header-right"></div>
        <div className="timeline-loader-background-masker header-bottom"></div>
        <div className="timeline-loader-background-masker subheader-left"></div>
        <div className="timeline-loader-background-masker subheader-right"></div>
        <div className="timeline-loader-background-masker subheader-bottom"></div>
        <div className="timeline-loader-background-masker content-top"></div>
        <div className="timeline-loader-background-masker content-first-end"></div>
        <div className="timeline-loader-background-masker content-second-line"></div>
        <div className="timeline-loader-background-masker content-second-end"></div>
        <div className="timeline-loader-background-masker content-third-line"></div>
        <div className="timeline-loader-background-masker content-third-end"></div>
      </div>
    );
  };

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {progress !== "" && (
        <div className="progress-container">
          <div className="progress-inner">
            <div
              className="progress-bar progress-bar-striped"
              role="progressbar"
              aria-valuenow="50"
              aria-valuemin="0"
              aria-valuemax="100"
              style={{ width: "20%" }}
            ></div>

            <div className="progress-name">
              <span className="progress-name-inner">
                {selectedFileType[0] === "jpeg" ||
                selectedFileType[0] === "jpg" ||
                selectedFileType[0] === "png"
                  ? "Processing Uploaded File..."
                  : progress === 100
                  ? "Processing Uploaded File..."
                  : selectedFileName[0]
                  ? selectedFileName[0]
                  : "Processing Uploaded File..."}
              </span>
            </div>
            <div className="progress-count">{progress}%</div>
          </div>
        </div>
      )}

      {loaderActiveData ? (
        <>{customChatLoader()}</>
      ) : (
        <>
          <div
            ref={chatFeed}
            id="chat-feed"
            className={`chat-feed ${
              chatMessageByGroup.length >= 3 && "overflow-auto"
            }  ${disableScroll ? "overflow-hidden" : ""}`}
            onScroll={(e) => !stopScrollBottom && handleScroll(e)}
          >
            {beginningMessage ? (
              <BeginningOfChatMessage
                username={
                  type === "private" ? username : attendeeData.groupName
                }
                type={type}
              />
            ) : searchChatData != "" && newChatMessage.length === 0 ? (
              <ChatMessageNotFound />
            ) : null}
            <div>{isFetchingChatHistory && customChatLoader()}</div>
            <div>
              {chatMessageByGroup.map((chatItem, index) => {
                const value = { ...chatItem };
                let message = value.message;
                if (message.includes("href=")) {
                  const newMessage = message.replace(
                    hrefPattern,
                    '<a href="' + hrefValue + '"',
                  );
                  value["message"] = newMessage;
                }

                return (
                  <Fragment key={index}>
                    {value.checkdate && (
                      <div className="chat_row chat_row_hr">
                        <div className="top-border text-right mt-3 mb-1">
                          <div className="current-date-div">
                            <div
                              className="current-date-inner"
                              onClick={(e) => handlePropagation(e)}
                            >
                              <a
                                className="justify-content-center position-relative"
                                type="button"
                                onClick={(e) => {
                                  handleDisableScroll(e, value?.id);
                                }}
                                ref={(ref) =>
                                  (chatFilterRef.current[index] = ref)
                                }
                                id={value.id}
                              >
                                <small className="current-date">
                                  {value.checkdate == todayDate
                                    ? "Today"
                                    : value.checkdate}
                                  <svg
                                    width={8}
                                    className="ml-2"
                                    viewBox="6.29 9.29 11.41 7.12"
                                  >
                                    <path d="M16.293 9.293 12 13.586 7.707 9.293l-1.414 1.414L12 16.414l5.707-5.707z"></path>
                                  </svg>
                                </small>
                                {value.id === selectedJumpId && (
                                  <CustomDropdown
                                    {...{
                                      handleChatFilterByDate,
                                      isOpenDropdown,
                                    }}
                                  />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {value.senderID === senderID ? (
                      <ChatBoardOutgoing
                        value={value}
                        userInfo={outgoingUserObj}
                        lastMessage={
                          chatMessageByGroup.length - 1 === index ? true : false
                        }
                        editMessageHandler={editMessageHandler}
                        messageEditId={messageEditId}
                        moreActionHandler={moreActionHandler}
                        moreActionEnableId={moreActionEnableId}
                        showPicker={showPicker}
                        setShowPicker={setShowPicker}
                        attendeeData
                        emojiRef={emojiRef}
                        showHideEmojiPickerHandler={showHideEmojiPickerHandler}
                        showHideEmojiMsgPickerHandler={
                          showHideEmojiMsgPickerHandler
                        }
                        showMessagePicker={showMessagePicker}
                        emojiMsgRef={emojiMsgRef}
                        senderID={senderID}
                        replyHandler={replyHandler}
                        setShowMessagePicker={setShowMessagePicker}
                        setMoreActionEnableId={setMoreActionEnableId}
                        setStopScrollBottom={setStopScrollBottom}
                      />
                    ) : (
                      <ChatBoardIncoming
                        value={value}
                        userInfo={incomingUserObj}
                        lastMessage={
                          chatMessageByGroup.length - 1 === index ? true : false
                        }
                        editMessageHandler={editMessageHandler}
                        messageEditId={messageEditId}
                        moreActionHandler={moreActionHandler}
                        moreActionEnableId={moreActionEnableId}
                        attendeeData
                        emojiRef={emojiRef}
                        showHideEmojiPickerHandler={showHideEmojiPickerHandler}
                        setMoreActionEnableId={setMoreActionEnableId}
                        showPicker={showPicker}
                        setShowPicker={setShowPicker}
                        showHideEmojiMsgPickerHandler={
                          showHideEmojiMsgPickerHandler
                        }
                        showMessagePicker={showMessagePicker}
                        emojiMsgRef={emojiMsgRef}
                        senderID={senderID}
                        replyHandler={replyHandler}
                        setShowMessagePicker={setShowMessagePicker}
                      />
                    )}
                  </Fragment>
                );
              })}
            </div>
            <div ref={lastElement}></div>
          </div>
        </>
      )}
      {imagePreviewOpen && (
        <ImagePreview handleImagePreviewClose={handleImagePreviewClose} />
      )}
    </>
  );
};

export default memo(ChatBoardBody);

const BeginningOfChatMessage = memo(({ username, type }) => {
  return (
    <p className="text-center">
      {`This is the very beginning of ${
        type === "private" ? "your direct" : ""
      } messages ${type === "private" ? "with" : "in the group"}`}
      <span className="text-highlight">&nbsp;{username}&nbsp;</span>
    </p>
  );
});

const ChatMessageNotFound = () => {
  return (
    <p className="text-center">
      <b>{alertMsg.nothingSearch}</b>
      <br />
      {alertMsg.msgNotFound}
      <br />
    </p>
  );
};
