import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  chatMessage: [],
  isFetchingChatHistory: false,
  replyMessage: {},
  progress: "",
  selectedFileName: [],
  selectedFileType: [],
  imagePreviewOpen: false,
  imagePreviewData: {},
  userProfileId: "",
  sidebarActiveData: {},
  loaderActiveData: false,
  searchChatData: "",
  attendeeData: {},
  deleteChatData: {},
  badWordList: [],
  loderAttendeeDirect: true,
  loaderAttendeeGroup: true,
  unreadChatMessage: [],
  socketData: null,
  isSocketConnected: false,
  groupList: [],
  attendeeList: [],
  chatRoom: "",
  searchAttendeeData: "",
  isUploadingData: [],
  attachmentData: [],
  deletedMessageIds: [],
  isCursorOnUserProfile: false,
  userProfilePopPosition: { x: 0, y: 0 },
  msgMentionUser: "",
  msgMentionId: "",
  isChatHistoryEnableOnce: false,
  selectedLanguage: "en",
  chatFilterLoader: false,
  popupClass: "",
  isScrollingUp: false,
  selectedReactionUser: {},
  recentlyUsedEmoticon: [],
  desktopSystemStats: {},
  userStaticInfo: {},
  // chatMessagesImagesIdsData: [],
};
const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChatMessage: (state, action) => {
      state.chatMessage = action.payload;
    },
    setIsFetchingChatHistory: (state, action) => {
      state.isFetchingChatHistory = action.payload;
    },
    setReplyMessage: (state, action) => {
      state.replyMessage = action.payload;
    },
    setProgress: (state, action) => {
      state.progress = action.payload;
    },
    setSelectedFileName: (state, action) => {
      state.selectedFileName = action.payload;
    },
    setSelectedFileType: (state, action) => {
      state.selectedFileType = action.payload;
    },
    setImagePreviewOpen: (state, action) => {
      state.imagePreviewOpen = action.payload;
    },
    setImagePreviewData: (state, action) => {
      state.imagePreviewData = action.payload;
    },
    setUserProfileId: (state, action) => {
      state.userProfileId = action.payload;
    },
    setSidebarActiveData: (state, action) => {
      state.sidebarActiveData = action.payload;
    },
    setLoaderActiveData: (state, action) => {
      state.loaderActiveData = action.payload;
    },
    setSearchChatData: (state, action) => {
      state.searchChatData = action.payload;
    },
    setAttendeeData: (state, action) => {
      state.attendeeData = action.payload;
    },
    setMsgMentionUser: (state, action) => {
      state.msgMentionUser = action.payload;
    },
    setDeleteChatData: (state, action) => {
      state.deleteChatData = action.payload;
    },
    setBadWordList: (state, action) => {
      state.badWordList = action.payload;
    },
    setLoderAttendeeDirect: (state, action) => {
      state.loderAttendeeDirect = action.payload;
    },
    setLoderAttendeeGroup: (state, action) => {
      state.loaderAttendeeGroup = action.payload;
    },
    setUnreadChatMessage: (state, action) => {
      state.unreadChatMessage = action.payload;
    },
    setSocketData: (state, action) => {
      state.socketData = action.payload;
    },
    setIsSocketConnected: (state, action) => {
      state.isSocketConnected = action.payload;
    },
    setGroupList: (state, action) => {
      state.groupList = action.payload;
    },
    setAttendeeList: (state, action) => {
      state.attendeeList = action.payload;
    },
    setChatRoom: (state, action) => {
      state.chatRoom = action.payload;
    },
    setSearchAttendeeData: (state, action) => {
      state.searchAttendeeData = action.payload;
    },
    setIsUploadingData: (state, action) => {
      state.isUploadingData = action.payload;
    },
    setAttachmentData: (state, action) => {
      state.attachmentData = action.payload;
    },
    setDeleteMessage: (state, action) => {
      state.deletedMessageIds = action.payload;
    },
    setIsCursorOnUserProfile: (state, action) => {
      state.isCursorOnUserProfile = action.payload;
    },
    setUserProfilePopPosition: (state, action) => {
      state.userProfilePopPosition = action.payload;
    },
    setMsgMentionId: (state, action) => {
      state.msgMentionId = action.payload;
    },
    setIsChatHistoryEnableOnce: (state, action) => {
      state.isChatHistoryEnableOnce = action.payload;
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    },
    setChatFilterLoader: (state, action) => {
      state.chatFilterLoader = action.payload;
    },
    setPopupClass: (state, action) => {
      state.popupClass = action.payload;
    },
    setIsScrollingUp: (state, action) => {
      state.isScrollingUp = action.payload;
    },
    setSelectedReactionUser: (state, action) => {
      state.selectedReactionUser = action.payload;
    },
    setRecentlyUsedEmoticons: (state, action) => {
      state.recentlyUsedEmoticon = action.payload;
    },
    setDesktopSystemStats: (state, action) => {
      state.desktopSystemStats = action.payload;
    },
    setUserStaticInfo: (state, action) => {
      state.userStaticInfo = action.payload;
    },
    // setChatMessagesImagesIdsData: (state, action) => {
    //   state.chatMessagesImagesIdsData = action.payload;
    // },
    // addChatMessagesImagesIdsData: (state, action) => {
    //   const val = action.payload;
    //   let tmpChatMessagesImagesIdsData = [
    //     ...state.chatMessagesImagesIdsData,
    //     val,
    //   ];
    //   state.chatMessagesImagesIdsData = tmpChatMessagesImagesIdsData;
    // },
  },
});

export default chatSlice.reducer;
export const {
  setChatMessage,
  setIsFetchingChatHistory,
  setReplyMessage,
  setProgress,
  setSelectedFileName,
  setSelectedFileType,
  setImagePreviewOpen,
  setImagePreviewData,
  setUserProfileId,
  setSidebarActiveData,
  setLoaderActiveData,
  setSearchChatData,
  setAttendeeData,
  setMsgMentionUser,
  setDeleteChatData,
  setBadWordList,
  setLoderAttendeeDirect,
  setLoderAttendeeGroup,
  setUnreadChatMessage,
  setSocketData,
  setIsSocketConnected,
  setGroupList,
  setAttendeeList,
  setChatRoom,
  setSearchAttendeeData,
  setIsUploadingData,
  setAttachmentData,
  setDeleteMessage,
  setIsCursorOnUserProfile,
  setUserProfilePopPosition,
  setMsgMentionId,
  setIsChatHistoryEnableOnce,
  setSelectedLanguage,
  setChatFilterLoader,
  setPopupClass,
  setIsScrollingUp,
  setSelectedReactionUser,
  setChatMessagesImagesIdsData,
  setRecentlyUsedEmoticons,
  setDesktopSystemStats,
  setUserStaticInfo,
} = chatSlice.actions;
